import date from "date-and-time";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import TablePaginationControls from "../shared/TablePaginationControls";

import LoadingWheel from "../shared/LoadingWheel";

import { useKpax } from "../../data/useKpax";
import { useState } from "react";
import AddAlert from "./AddAlert";
import useNotification from "../../hooks/useNotifications";

function AlertsTable({ data, onRemoveClick }) {
  const cols = [
    {
      accessorKey: "name",
      header: "Alert Type",
    },
    {
      accessorKey: "createdOn",
      header: "Date Created",
      cell: (info) => date.format(new Date(info.getValue()), "DD/MM/YYYY"),
    },
    {
      accessorKey: "email",
      header: "Send to",
    },
    {
      header: "Action",
      cell: (info) => (
        <button
          onClick={() => onRemoveClick(info.row.original.notificationId)}
          className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
        >
          Remove
        </button>
      ),
    },
  ];

  const table = useReactTable({
    data: data,
    columns: cols,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="block overflow-y-hidden overflow-x-auto">
        <table className="text-black min-w-full divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, ri) => {
              let rowCells = row.getVisibleCells();
              return (
                <tr
                  key={row.id}
                  className={`${ri % 2 == 0 ? "bg-gray-100" : "bg-white"}`}
                >
                  {rowCells.map((cell) => (
                    <td
                      key={cell.id}
                      className="px-3 py-4 text-sm font-medium"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePaginationControls table={table} />
    </>
  );
}

export default function Alerts({ customer }) {
  const { addNotification } = useNotification();
  const { alerts, removeAlert } = useKpax(customer.id);
  const [addAlert, setAddAlert] = useState(false);

  const handleRemoveAlert = (alertId) => {
    removeAlert.mutate(alertId, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Alert Removed",
          secondaryText: "Alert successfully removed",
        });
      },
    });
  };

  return (
    <div className="min-h-[55vh]">
      {alerts.isLoading ? (
        <LoadingWheel />
      ) : (
        <>
          {!addAlert && (
            <div className="flex justify-end">
              <button
                onClick={() => setAddAlert(true)}
                className="mb-4 p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
              >
                Add an Alert
              </button>
            </div>
          )}
          {addAlert ? (
            <AddAlert onCancel={() => setAddAlert(false)} customer={customer} />
          ) : (
            <AlertsTable
              data={alerts.data.alerts}
              onRemoveClick={handleRemoveAlert}
            />
          )}
        </>
      )}
    </div>
  );
}
