import { useState, useMemo } from "react";
import date from "date-and-time";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import LoadingWheel from "../shared/LoadingWheel";
import TablePaginationControls from "../shared/TablePaginationControls";
import SiteDropdown from "../shared/SiteDropdown";

import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import { useKpax } from "../../data/useKpax";
import { useEquipment } from "../../data/useEquipment";
import { isNotToday } from "../../utils/isNotToday";

function DeviceTable({ data, equipment, count }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSite, setFilterSite] = useState("*");

  const filteredData = useMemo(() => {
    if (filterSite === "*") {
      return data;
    } else {
      return data.filter(row => row.customerId.toString() === filterSite);
    }
  }, [data, filterSite]);

  const DetailCell = (info) => {
    const row = info.row.original;
    let matchedDevice = equipment.find(
      (eq) => eq.serialNumber === row.serialNumber
    );

    return (
      <div className="w-96 md:w-full">
        <div className="grid grid-cols-3">
          <div className="grid col-span-1 text-center">
            <span className="text-gray-500 text-sm">Serial</span>
            <span>{row.serialNumber}</span>
          </div>
          <div className="grid col-span-1 text-center">
            <span className="text-gray-500 text-sm">Device</span>
            <span>{row.model}</span>
          </div>
          <div className="grid col-span-1 text-center">
            <span className="text-gray-500 text-sm">Site</span>
            <span>{matchedDevice ? matchedDevice.address1 : ''}</span>
          </div>
          <div className="grid col-span-1 text-center">
            <span className="text-gray-500 text-sm">Location</span>
            <span>{matchedDevice ? matchedDevice.location : ''}</span>
          </div>
          <div className="grid col-span-1 text-center">
            <span className="text-gray-500 text-sm">Last Checked</span>
            <span className={isNotToday(row.lastCheck) ? "text-red-600" : ""}>{date.format(new Date(row.lastCheck), "DD/MM/YYYY")}</span>
          </div>
          <div className="grid col-span-1 text-center">
            <span className="text-gray-500 text-sm">IP Address</span>
            <span>{row.ip}</span>
          </div>
        </div>
      </div>
    );
  };

  const LevelsCell = (info) => {
    const row = info.row.original;

    if (row.supplies) {
      return (
        <div className="w-96 md:w-full">
          <div className="grid grid-cols-4 gap-3">
            <div className="relative h-10 border border-gray-300 bg-gray-200 p-0.5">
              <div
                className="bg-black h-full flex items-center justify-center"
                style={{ width: `${row.supplies.black}%` }}
              />
              <div className="absolute top-0 left-0 w-full h-full">
                <div
                  className={`w-full h-full flex items-center justify-center ${
                    row.supplies.black < 50 ? "text-black" : "text-white"
                  }`}
                >
                  {row.supplies.black}%
                </div>
              </div>
            </div>
            {row.supplies.cyan !== 0 && (
              <div className="relative h-10 border border-gray-300 bg-gray-200 p-0.5">
                <div
                  className="bg-ag-cyan h-full flex items-center justify-center"
                  style={{ width: `${row.supplies.cyan}%` }}
                />
                <div className="absolute top-0 left-0 w-full h-full">
                  <div
                    className={`w-full h-full flex text-black items-center justify-center`}
                  >
                    {row.supplies.cyan}%
                  </div>
                </div>
              </div>
            )}
            {row.supplies.magenta !== 0 && (
              <div className="relative h-10 border border-gray-300 bg-gray-200 p-0.5">
                <div
                  className="bg-ag-magenta h-full flex items-center justify-center"
                  style={{ width: `${row.supplies.magenta}%` }}
                />
                <div className="absolute top-0 left-0 w-full h-full">
                  <div
                    className={`w-full h-full flex items-center justify-center ${
                      row.supplies.magenta < 50 ? "text-black" : "text-white"
                    }`}
                  >
                    {row.supplies.magenta}%
                  </div>
                </div>
              </div>
            )}
            {row.supplies.yellow !== 0 && (
              <div className="relative h-10 border border-gray-300 bg-gray-200 p-0.5">
                <div
                  className="bg-ag-yellow h-full flex items-center justify-center"
                  style={{ width: `${row.supplies.yellow}%` }}
                />
                <div className="absolute top-0 left-0 w-full h-full">
                  <div className="w-full h-full flex text-black items-center justify-center">
                    {row.supplies.yellow}%
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-96 md:w-full text-center text-gray-500">No Data</div>
      );
    }
  };

  const VolumesCell = (info) => {
    const row = info.row.original;
    if (row.meters) {
      return (
        <div className="w-64 md:w-full">
          <div className="grid grid-cols-2">
            <span className="text-gray-500 text-sm">Total</span>
            <span>{parseInt(row.meters.engineCount).toLocaleString()}</span>
            <span className="text-gray-500 text-sm">Mono</span>
            <span>{parseInt(row.meters.totalMono).toLocaleString()}</span>
            <span className="text-gray-500 text-sm">Colour</span>
            <span>
              {row.isColor &&
              row.supplies.cyan !== 0 &&
              row.supplies.magenta !== 0 &&
              row.supplies.yellow !== 0
                ? parseInt(row.meters.totalColor).toLocaleString()
                : "N/A"}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-64 md:w-full text-center text-gray-500">No Data</div>
      );
    }
  };

  const cols = [
    {
      accessorKey: "serialNumber",
      header: "Details",
      cell: (info) => DetailCell(info),
    },
    {
      header: "Levels",
      cell: (info) => LevelsCell(info),
    },
    {
      header: "Volumes",
      cell: (info) => VolumesCell(info),
    },
  ];

  const table = useReactTable({
    data: filteredData,
    columns: cols,
    filterFns: {
      fuzzy: basicIncludesFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: basicIncludesFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="text-ag-default text-right font-medium">Total Devices: {count}</div>
      <div className="relative my-4">
        <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
          <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
            <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              placeholder="Search"
            />
          </div>
          <SiteDropdown
            onChange={(e) => setFilterSite(e.target.value)}
            className="w-full md:w-1/4"
          />
        </div>
      </div>
      <div className="block overflow-y-hidden overflow-x-auto">
        <table className="text-black min-w-full divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, ri) => {
              let rowCells = row.getVisibleCells();
              return (
                <tr
                  key={row.id}
                  className={`${ri % 2 == 0 ? "bg-gray-100" : "bg-white"}`}
                >
                  {rowCells.map((cell, ci) => (
                    <td
                      key={cell.id}
                      className="px-3 py-4 text-sm font-medium"
                      width={ci === 1 ? "40%" : undefined}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePaginationControls table={table} />
    </>
  );
}

export default function Overview({ customer }) {
  const { devices } = useKpax(customer.id);
  const { equipment } = useEquipment(customer.id);

  return (
    <div className="min-h-[55vh]">
      {devices.isLoading || equipment.isLoading ? (
        <LoadingWheel />
      ) : (
        <DeviceTable
          data={devices.data.devices}
          equipment={equipment.data.equipment}
          count={devices.data.count}
        />
      )}
    </div>
  );
}
