import { useState } from "react";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";

import TablePaginationControls from "../../components/shared/TablePaginationControls";

import useCurrentUserContext from "../../contexts/UserContext";

import { buildAddressString } from "../../utils/buildAddressString";

const AddressCell = (row) => {
    const { currentUser } = useCurrentUserContext();
    let site = currentUser.user.sites.find((s) => s.id === row.customerId);
    if (site) {
      return buildAddressString(
        site.address1,
        site.address2,
        site.town
      );
    } else {
      return buildAddressString(row.address1, row.address2, row.town);
    }
};

export default function EquipmentTable({
    data,
    selectedDevices,
    setSelectedDevices
  }) {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSelectAllToggle = () => {
        if (selectedDevices.length === data.length) {
          setSelectedDevices([]);
        } else {
          const allSerialNumbers = data.map(row => row.serialNumber);
          setSelectedDevices(allSerialNumbers);
        }
      };

    const handleToggleSelection = (serialNumber) => {
        setSelectedDevices(prevSelectedDevices => {
          if (prevSelectedDevices && prevSelectedDevices.includes(serialNumber)) {
            return prevSelectedDevices.filter(item => item !== serialNumber);
          } else {
            return [...prevSelectedDevices, serialNumber];
          }
        });
    };

  
    const cols = [
      {
        header: () => (
            <button
              type="button"
              onClick={() => handleSelectAllToggle()}
              className={`p-2 px-4 rounded-full text-white hover:bg-ag-lightDefault bg-ag-default`}
            >
              Select All
            </button>
        ),
        id: "select",
        cell: (info) => (
          <div className="flex items-center justify-center">
            {selectedDevices && selectedDevices.includes(info.row.original.serialNumber) ? (
            <button type="button" onClick={() => handleToggleSelection(info.row.original.serialNumber)}>
              <CheckIcon className="w-8 h-8" />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => handleToggleSelection(info.row.original.serialNumber)}
              className={`p-2 px-4 rounded-full text-white hover:bg-ag-lightDefault bg-ag-default`}
            >
              Select
            </button>
          )}
          </div>
        ),
      },
      {
        accessorKey: "serialNumber",
        header: "Serial",
      },
      {
        accessorKey: "description",
        header: "Device Name",
        cell: (info) => (
          <div className="space-y-2">
            <p>{info.getValue()}</p>
          </div>
        ),
      },
      {
        accessorKey: "name",
        header: "Company Name",
      },
      {
        id: "Address",
        accessorFn: (row) => AddressCell(row),
        cell: (info) => <span>{info.getValue()}</span>,
      },
      {
        accessorKey: "location",
        header: "Onsite Location",
      },
      {
        accessorKey: "assetNumber",
        header: "Asset Number",
      },
    ];
  
    const table = useReactTable({
      data: data,
      columns: cols,
      filterFns: {
        fuzzy: basicIncludesFilter,
      },
      state: {
        globalFilter: searchTerm,
      },
      onGlobalFilterChange: setSearchTerm,
      globalFilterFn: basicIncludesFilter,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    });
  
    return (
      <>
        <div className="relative mb-4 md:my-4">
          <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
            <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
              <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                id="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <div className="block overflow-y-hidden overflow-x-auto">
          <table className="text-black min-w-full divide-y border divide-gray-300">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                  {headerGroup.headers.map((header, hi) => (
                    <th
                      key={header.id}
                      scope="col"
                      width={hi === 4 ? "20%" : "auto"}
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold lg:table-cell"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, ri) => {
                let rowCells = row.getVisibleCells();
                return (
                  <tr
                    key={row.id}
                    className={`${ri % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                  >
                    {rowCells.map((cell, ci) => (
                      <td
                        key={cell.id}
                        className={`px-3 py-4 text-sm ${
                          ci === 1 ? "pl-5 font-bold" : "font-medium"
                        } ${
                          ci !== 0 && ci !== 1 ? "align-text-top" : ""
                        }`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <TablePaginationControls table={table} />
      </>
    );
  }