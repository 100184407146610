import { useState, useEffect } from "react";

import {
  MagnifyingGlassIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";

import Modal from "../shared/Modal";
import LoadingWheel from "../shared/LoadingWheel";
import ViewSelect from "../shared/ViewSelect";
import SiteDropdown from "../shared/SiteDropdown";

import CancelSupportRequest from "./CancelSupportRequest";
import SupportTicketCard from "./SupportTicketCard";
import TicketTable from "./TicketTable";

import date from "date-and-time";

import useNotification from "../../hooks/useNotifications";

import { useSupport } from "../../data/useSupport";
import { useEquipment } from "../../data/useEquipment";
import { useSearchParams } from "react-router-dom";
import { buildAddressString } from "../../utils/buildAddressString";

export default function OpenTickets({ customer }) {
  const { addNotification } = useNotification();
  const [searchParams] = useSearchParams();
  const [supportTickets, setSupportTickets] = useState([]);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [cancelTicket, setCancelTicket] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [cardView, setCardView] = useState(false);
  const { equipment } = useEquipment(customer.id);
  const [filterSite, setFilterSite] = useState("*");
  const { supportTickets: allSupportTickets, cancelRequest } = useSupport(
    customer.id
  );

  const handleCancelClick = (ticket) => {
    setCancelTicket(ticket);
    setCancelOpen(true);
  };

  const handleCancelConfirm = (data) => {
    cancelRequest.mutate(
      {
        ...data,
        assetNo: cancelTicket.assetNumber,
        referenceNo: cancelTicket.reference,
        deviceSerial: cancelTicket.serialNumber,
        category: cancelTicket.category,
        address: buildAddressString(cancelTicket.address1, cancelTicket.address2, cancelTicket.town, cancelTicket.postCode),
        location: cancelTicket.location,
        engineerStatusText: cancelTicket.engineerStatusText,
        loggedDate: date.format(new Date(cancelTicket.loggedDate), "DD/MM/YY HH:mm").replace(" ", "\xA0\xA0\xA0"),
      },
      {
        onSuccess: () => {
          addNotification({
            variant: "success",
            primaryText: "Support Request Cancelled",
            secondaryText: "Your support request has been cancelled",
          });
          setCancelOpen(false);
        },
      }
    );
  };

  useEffect(() => {
    if (!allSupportTickets.isLoading) {
      let filteredTickets = allSupportTickets.data.workUnits
        .filter((wu) => wu.completedDate === null)
        .sort((a, b) => {
          const dateComparison = new Date(a.completedDate) - new Date(b.completedDate);
          if (dateComparison !== 0) {
            return dateComparison;
          }
          
          if (a.reference !== b.reference) {
            return a.reference.localeCompare(b.reference);
          }
          return b.sequence - a.sequence;
        });
  
      const term = searchTerm.toLowerCase();
  
      const filtered = filteredTickets.filter(ticket => {
        const matchTerm =
          ticket.reference.toLowerCase().includes(term) ||
          ticket.location.toLowerCase().includes(term) ||
          ticket.serialNumber.toLowerCase().includes(term);
  
        const matchSite = filterSite === "*" || ticket.customerId === parseInt(filterSite);
  
        return matchTerm && matchSite;
      });
  
      setSupportTickets(searchTerm || filterSite !== "*" ? filtered : filteredTickets);
    }
  
    if (searchParams.has("view")) {
      if (searchParams.get("view") === "card") {
        setCardView(true);
      }
    }
  }, [
    searchTerm,
    allSupportTickets.data,
    allSupportTickets.isLoading,
    searchParams,
    filterSite
  ]);

  return (
    <>
      <Modal
        title="Cancel Support Request"
        open={cancelOpen}
        setOpen={setCancelOpen}
      >
        <CancelSupportRequest
          onConfirm={handleCancelConfirm}
          onClose={() => setCancelOpen(false)}
        />
      </Modal>
      {allSupportTickets.isLoading || equipment.isLoading ? (
        <div className="min-h-[50vh]">
          <LoadingWheel />
        </div>
      ) : cardView ? (
        <>
          <ul className="grid grid-cols-1 gap-6 md:grid-cols-3">
            <li className="md:col-span-3 py-4 px-6 rounded-lg bg-white shadow grid grid-cols-1 md:flex md:justify-between">
              <div className="relative">
                <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
                  <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
                    <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="text"
                      id="search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="block w-full text-black bg-gray-50 pl-10 border border-gray-400 p-2 focus:border-ag-darkBlue"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
              <div className="md:space-x-2 md:flex md:items-center w-7/8 justify-end">
                <SiteDropdown onChange={(e) => setFilterSite(e.target.value)} className="w-full md:w-1/2 mb-4 md:mb-0" />
                <ViewSelect value={cardView} onChange={setCardView} className="w-full md:w-1/2 mb-4 md:mb-0" />
              </div>
            </li>
            <li className="md:col-span-3 py-4 px-6 rounded-lg bg-white shadow grid grid-cols-1 md:flex md:justify-between">
              <div className="rounded-md bg-red-50 p-4 w-full">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-red-700">
                      Support tickets will show here once they have been
                      processed by our Customer Service Team.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            {supportTickets
              .sort((a, b) => new Date(b.loggedDate) - new Date(a.loggedDate))
              .map((ticket, i) => (
                <SupportTicketCard
                  key={`ticket-${i}`}
                  ticket={ticket}
                  onCancelClick={(ref) => handleCancelClick(ref)}
                />
              ))}
          </ul>
        </>
      ) : (
        <TicketTable
          cardView={cardView}
          setCardView={setCardView}
          data={supportTickets.sort(
            (a, b) => new Date(b.loggedDate) - new Date(a.loggedDate)
          )}
          onCancelClick={handleCancelClick}
          equipment={equipment.data?.equipment}
          open
        />
      )}
    </>
  );
}
