import { buildAddressString } from "../../utils/buildAddressString";

export default function SiteListRow({ site, selectedSites, setSelectedSites, index }) {
    let checked = selectedSites.find((siteId) => siteId === site.id);
  
    return (
      <div>
        <div
          className={`py-4 px-6 grid grid-cols-10 border-b ${
            index % 2 === 0 && "bg-gray-100"
          }`}
        >
          <div>
            <input
              name={`${site.id}`}
              checked={checked ? checked : false}
              onChange={() => {
                checked
                  ? setSelectedSites((oldSelected) => [
                      ...oldSelected.filter((s) => s !== site.id),
                    ])
                  : setSelectedSites((oldSelected) => [...oldSelected, site.id]);
              }}
              type="checkbox"
              className="rounded h-6 w-6 border text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
            />
          </div>
          <div className="col-span-9">
            <span className="text-md text-ag-default font-medium">
              {site.name} - {buildAddressString(site.address1, site.address2, site.town)}
            </span>
          </div>
        </div>
      </div>
    );
  }