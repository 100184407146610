import { useEffect } from "react";
import useCurrentUserContext from "../../contexts/UserContext";

export default function SiteDropdown({ className, onChange, selectProps }) {
  const { currentUser } = useCurrentUserContext();

  

  useEffect(() => {}, [currentUser.loading]);

  if (!currentUser.loading) {
    return (
      <select
        onChange={onChange}
        className={`border text-black bg-gray-50 border-gray-400 p-2 ${className}`}
        {...selectProps}
      >
        <option value="*">All Sites</option>
        {currentUser.user.sites
          .sort((a, b) => {
            const nameA = a.name.trim().toUpperCase().replace(/\s+/g, ' ');
            const nameB = b.name.trim().toUpperCase().replace(/\s+/g, ' ');
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        })
        .map((site) => (
          <option key={site.id} value={site.id}>
            {site.name}
          </option>
        ))}
      </select>
    );
  } else {
    return "";
  }
}
