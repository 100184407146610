import { useQuery } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useCurrentUserContext from "../contexts/UserContext";

export function useInvoices(customerId) {
  const { currentUser: { actAs } } = useCurrentUserContext();
  const { get } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
  });

  const invoices = useQuery({
    queryKey: ["invoices", customerId],
    queryFn: () => get("/invoice"),
  });

  return {
    invoices,
  };
}
