import FleetManagement from "../../assets/FleetManagement.jpg";

export default function FleetManSignup() {
  return (
    <div className="p-6 bg-white rounded-xl grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="grid grid-cols-1 gap-6">
        <div>
          <h1 className="mb-2 text-ag-default font-bold text-xl">
            Benefit From Proactive Fleet Management With KPAX
          </h1>
          <p className="text-gray-500">
            To reduce repair times and ensure you continue to receive
            outstanding service, we will remotely install KPAX, an innovative
            fleet management solution, as a free upgrade to all of our
            customers.
            <br />
            <br />
            'KPAX Manage' automates many of the tasks needed to effectively
            administer multifunctional devices (MFDs) and maximise uptime with
            the ability to proactively manage your supplies and servicing.
            <br />
            <br />
            <span className="font-semibold">
              Maximise your uptime with a free 10-minute KPAX installation.
            </span>
            <br />
            <br />
            <a
              className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
              href="https://outlook.office365.com/owa/calendar/DMCPrintAuditManagement@dmcplc.co.uk/bookings"
            >
              Book Your Installation Today
            </a>
          </p>
        </div>
        <div>
          <h1 className="mb-2 text-ag-default font-bold text-xl">
            How KPAX Works
          </h1>
          <p className="text-gray-500">
            The 'KPAX Discovery Agent' (KDA) must be remotely installed, ideally
            on a server or in some circumstances a workstation, which captures
            metrics from the MFD(s). This program gathers and securely sends
            this data to a central KM SaaS server.
            <br />
            <br />
            The 'KPAX Manage' (KM) system securely collects these device metrics
            from KDA. This product only gathers data that is critical to
            assisting Agilico in providing customers with first-rate managed
            document services.
            <br />
            <br />
            <a
              className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
              href="https://www.agilico.co.uk/kpax/"
            >
              Discover More
            </a>
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6">
        <div>
          <img
            src={FleetManagement}
            alt="KPAX Fleet Management"
            className="w-full"
          />
        </div>
        <div>
          <h1 className="mb-2 text-ag-default font-bold text-xl">
            The Benefits of KPAX
          </h1>
          <ol className="font-medium list-disc ml-4 space-y-0.5 text-gray-500">
            <li>
              <span className="font-bold">Enhanced Stability & Security</span> -
              Adhering to the industry's highest security standards, KPAX
              encrypts all communication. KPAX never collects users' personal
              information.
            </li>
            <li>
              <span className="font-bold">
                Proactive Monitoring & Maintenance
              </span>{" "}
              - Remote monitoring and automated fault alerts ensure that our
              support team can quickly determine the most effective
              intervention.
            </li>
            <li>
              <span className="font-bold">Proactive Toner Ordering</span> -
              Supply replenishment is predictive; assessing toner levels, KPAX
              will automatically place re-order requests so that toner can be
              delivered to you.
            </li>
            <li>
              <span className="font-bold">Eliminate Estimated Billing</span> -
              Automated meter readings ensure that your invoices are accurate
              and seamlessly transmitted to our billing team on time.
            </li>
            <li>
              <span className="font-bold">Access Usage Profiles</span> - KPAX
              also monitors the volume and mono/colour output patterns of each
              networked MFD - a useful feature when deciding where and how best
              to deploy your devices.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
