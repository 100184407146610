import { useEffect, useState } from "react";
import date from "date-and-time";

import { withCustomer } from "../../data/withCustomer";
import FullscreenLoading from "../../components/shared/FullscreenLoading";
import { useParams } from "react-router";

import { useSupport } from "../../data/useSupport";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import usePageTitleContext from "../../contexts/PageTitleContext";
import LoadingWheel from "../../components/shared/LoadingWheel";

function _SupportTicket({ customerLoading, customer }) {
  const { ticketId } = useParams();
  const { setTitle } = usePageTitleContext();
  const { supportTickets } = useSupport(customer.id, {
    daysSinceToday: 365,
  });
  const [supportTicket, setSupportTicket] = useState(undefined);

  const pages = [
    { to: "../..", label: "Support", current: false},
    { to: "..", label: "Support Tickets", current: false },
    {
      to: "",
      label: ticketId ? `Ticket: ${ticketId}` : "Ticket",
      current: true,
    },
  ];

  useEffect(() => {
    setTitle("Support Ticket");
  }, [setTitle]);

  useEffect(() => {
    if (!supportTickets.isLoading) {
      let ticket = supportTickets.data.workUnits.find(
        (wu) => wu.reference === ticketId
      );
      if (!ticket) {
        //TODO: Handle 404 here
      } else {
        setSupportTicket(ticket);
      }
    }
  }, [ticketId, supportTickets.isLoading, supportTickets.data]);

  if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <Breadcrumbs pages={pages} />
        <div className="overflow-hidden mt-4 bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Ticket {ticketId}
            </h3>
          </div>
          {!supportTicket ? (
            <div className="py-4">
              <LoadingWheel />
            </div>
          ) : (
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Location
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {supportTicket.location}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Asset Number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {supportTicket.assetNumber}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Serial Number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {supportTicket.serialNumber}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Opened On
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {date.format(
                      new Date(supportTicket.loggedDate),
                      "DD/MM/YY HH:mm"
                    )}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Ticket Status
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {supportTicket.engineerStatusText}
                  </dd>
                </div>
              </dl>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const SupportTicket = withCustomer(_SupportTicket);

export default SupportTicket;
