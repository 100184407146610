import DashboardLayout from "./layouts/DashboardLayout";
import UauthenticatedLayout from "./layouts/UnauthenticatedLayout";

import Home from "./pages/Home/Home";
import Invoices from "./pages/Invoices/Invoices";
import LiveChat from "./pages/Support/LiveChat";
import Settings from "./pages/Settings/Settings";
import Statistics from "./pages/Statistics/Statistics";
import SupportTickets from "./pages/Support/SupportTickets";
import SupportTicket from "./pages/Support/SupportTicket";
import SupportHome from "./pages/Support/SupportHome";
import FleetManagement from "./pages/FleetManagement/FleetManagement";
import Verify from "./pages/Verify/Verify";
import Consumables from "./pages/Consumables/Consumables";
import TonerRecycling from "./pages/Support/TonerRecycling";
import AskSomethingElse from "./pages/Support/AskSomethingElse";
import MovePrinter from "./pages/Support/MovePrinter";
import DownloadPrinterDrivers from "./pages/Support/DownloadPrinterDrivers";
import DownloadPrinterManuals from "./pages/Support/DownloadPrinterManuals";
import FullscreenLoading from "./components/shared/FullscreenLoading";
import ViewManual from "./pages/Support/ViewManual";
import ManagedIT from "./pages/ManagedIT/ManagedIT";
import Telco from "./pages/Telco/Telco";
import NotFound from "./pages/404/404";

import { loginRequest } from "./authConfig";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { AcceptInvite } from "./pages/Invite/AcceptInvite";

const authRequest = {
  ...loginRequest,
};

export const AuthenticatedRoutes = [
  {
    path: "invite",
    element: <UauthenticatedLayout />,
    children: [
      {
        path: "accept",
        element: <AcceptInvite />,
      },
    ],
  },
  {
    path: "",
    element: (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <DashboardLayout />
      </MsalAuthenticationTemplate>
    ),
    children: [
      {
        index: true,
        element: (
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
          >
            <FullscreenLoading />
          </MsalAuthenticationTemplate>
        ),
      },
      {
        path: ":companyName",
        children: [
          {
            index: true,
            element: (
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
              >
                <Home />
              </MsalAuthenticationTemplate>
            ),
          },
          {
            path: "support",
            children: [
              {
                index: true,
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <SupportHome />
                  </MsalAuthenticationTemplate>
                ),
              },
              {
                path: "tickets",
                children: [
                  {
                    index: true,
                    element: (
                      <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                      >
                        <SupportTickets />
                      </MsalAuthenticationTemplate>
                    ),
                  },
                  {
                    path: ":section",
                    element: (
                      <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                      >
                        <SupportTickets />
                      </MsalAuthenticationTemplate>
                    ),
                  },
                  {
                    path: "ticket/:ticketId",
                    element: (
                      <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                      >
                        <SupportTicket />
                      </MsalAuthenticationTemplate>
                    ),
                  },
                ],
              },
              {
                path: "invoices",
                children: [
                  {
                    index: true,
                    element: (
                      <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                      >
                        <Invoices />
                      </MsalAuthenticationTemplate>
                    ),
                  },
                ],
              },
              {
                path: "toner-recycling",
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <TonerRecycling />
                  </MsalAuthenticationTemplate>
                ),
              },
              {
                path: "ask-something-else",
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <AskSomethingElse />
                  </MsalAuthenticationTemplate>
                ),
              },
              {
                path: "move-printer",
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <MovePrinter />
                  </MsalAuthenticationTemplate>
                ),
              },
              {
                path: "printer-drivers",
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <DownloadPrinterDrivers />
                  </MsalAuthenticationTemplate>
                ),
              },
              {
                path: "manuals",
                children: [
                  {
                    index: true,
                    element: (
                      <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                      >
                        <DownloadPrinterManuals />
                      </MsalAuthenticationTemplate>
                    ),
                  },
                  {
                    path: ":manualId",
                    element: (
                      <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                      >
                        <ViewManual />
                      </MsalAuthenticationTemplate>
                    ),
                  },
                ],
              },
              {
                path: "consumables",
                children: [
                  {
                    index: true,
                    element: (
                      <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                      >
                        <Consumables />
                      </MsalAuthenticationTemplate>
                    ),
                  },
                  {
                    path: ":section",
                    element: (
                      <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                      >
                        <Consumables />
                      </MsalAuthenticationTemplate>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "statistics",
            children: [
              {
                index: true,
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <Statistics />
                  </MsalAuthenticationTemplate>
                ),
              },
              {
                path: ":section",
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <Statistics />
                  </MsalAuthenticationTemplate>
                ),
              },
            ],
          },
          {
            path: "fleet-management",
            children: [
              {
                index: true,
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <FleetManagement />
                  </MsalAuthenticationTemplate>
                ),
              },
              {
                path: ":section",
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <FleetManagement />
                  </MsalAuthenticationTemplate>
                ),
              },
            ],
          },
          {
            path: "verify",
            element: (
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
              >
                <Verify />
              </MsalAuthenticationTemplate>
            ),
          },
          {
            path: "managed-it",
            element: (
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
              >
                <ManagedIT />
              </MsalAuthenticationTemplate>
            ),
          },
          {
            path: "telecom",
            element: (
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
              >
                <Telco />
              </MsalAuthenticationTemplate>
            ),
          },
          {
            path: "settings",
            children: [
              {
                index: true,
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <Settings />
                  </MsalAuthenticationTemplate>
                ),
              },
              {
                path: ":section",
                element: (
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                  >
                    <Settings />
                  </MsalAuthenticationTemplate>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: (
      <UauthenticatedLayout title="Not Found" />
    ),
    children: [
      {
        path: "*",
        element: <NotFound/>
      }
    ]
  },
];
