import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import { UserCircleIcon, UsersIcon } from "@heroicons/react/24/outline";

import UserDetails from "../../components/Settings/UserDetails";
import UsersList from "../../components/Settings/UserList";

import classNames from "../../utils/classNames";
import { withCustomer } from "../../data/withCustomer";
import FullscreenLoading from "../../components/shared/FullscreenLoading";
import usePageTitleContext from "../../contexts/PageTitleContext";
import useCurrentUserContext from "../../contexts/UserContext";

const subNavigation = [
  { name: "Profile", href: "", icon: UserCircleIcon, current: true, adminOnly: false },
  { name: "Users", href: "users", icon: UsersIcon, current: false, adminOnly: true },
];

function _Settings({ customerLoading, customer }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { section } = useParams();
  const { setTitle } = usePageTitleContext();

  const {
    currentUser: { user },
  } = useCurrentUserContext();

  const getPanel = () => {
    switch (section) {
      case "users":
        return <UsersList customer={customer} />;
      default:
        return <UserDetails customer={customer} />;
    }
  };

  const finalSubNav = user.isAdmin ? subNavigation : subNavigation.filter(sn => !sn.adminOnly);

  const handleNavigate = (item) => {
    finalSubNav.forEach((element) => {
      element.current = false;
    });

    item.current = true;
    navigate(`/${customer.reference.toLowerCase()}/settings/${item.href}`);
  };

  useEffect(() => {
    setTitle("Settings");
  }, [setTitle]);

  useEffect(() => {
    let urlParts = location.pathname.split("/");

    if (!urlParts[3]) {
      finalSubNav[0].current = true;
    } else {
      finalSubNav.forEach((element) => {
        element.current = false;
        if (element.href === urlParts[3]) {
          element.current = true;
        }
      });
    }
  }, [location.pathname]);

  if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <>
        <main className="mx-auto max-w-7xl pb-10 lg:py-12 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
              <nav className="space-y-1">
                {finalSubNav.map((item) => (
                  <button
                    key={item.name}
                    onClick={() => handleNavigate(item)}
                    className={classNames(
                      item.current
                        ? "bg-white text-ag-default hover:bg-white"
                        : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                      "group rounded-md px-3 py-2 flex items-center text-sm font-medium w-full"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-ag-default"
                          : "text-gray-400 group-hover:text-gray-500",
                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </button>
                ))}
              </nav>
            </aside>

            <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
              {getPanel()}
            </div>
          </div>
        </main>
      </>
    );
  }
}

const Settings = withCustomer(_Settings);

export default Settings;
