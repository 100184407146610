import { useEffect } from "react";
import { withCustomer } from "../../data/withCustomer";

import Breadcrumbs from "../../components/shared/Breadcrumbs";
import FullscreenLoading from "../../components/shared/FullscreenLoading";
import usePageTitleContext from "../../contexts/PageTitleContext";

import TelcoImg from "../../assets/Telco.jpg";

function _Telco({ companyLoading }) {
  const { setTitle } = usePageTitleContext();

  const pages = [{ to: "", label: "Telecommunications", current: true }];

  useEffect(() => {
    setTitle("Telecommunications");
  }, [setTitle]);

  if (companyLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="p-6 bg-white rounded-xl grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="grid grid-cols-1 gap-6">
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                Make sure your teams and customers stay connected and
                collaborative.
              </h1>
              <p className="text-gray-500">
                Traditional on-premise telephony is costly, inflexible and
                limiting for remote employees.
                <br />
                <br />
                We can connect you to our cloud telephony platform - delivering
                an enriched communications experience for your people, no matter
                where they are. Using just an internet connection, you'll get
                all the capability of traditional telephony, plus enhanced
                collaboration capabilities like: MS Teams integration,
                messaging, conferencing and more…
                <br />
                <br />
                <span className="font-semibold">
                  We'll provide enhanced communications for your people while
                  reducing costs and complexity.
                </span>
              </p>
            </div>
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                What is Hosted VoIP?
              </h1>
              <p className="text-gray-500">
                Hosted VoIP provides all the capability of an advanced
                on-premise business phone system, without the complex and pricey
                infrastructure. All the hardware and software is managed by a
                third party instead, through a cloud telephony platform - making
                CapEx a thing of the past. Aside from a handset or headset, an
                internet connection is all that is required. You use a simple
                web interface to make calls in a few clicks of a mouse.
                <br />
                <br />
                Scalable, secure and able to deliver a superior customer
                experience, business VoIP telephony solutions don't just deliver
                conventional voice. They give businesses the flexibility to take
                and make calls from anywhere; monitor and manage phone networks
                in real-time; and benefit from advanced and easy to use IP phone
                features such as call recording, auto-attendant, call queuing,
                instant messaging, hunt groups, conferencing and smart
                collaboration tools.
                <br />
                <br />
                And as it's cloud-based, you can rest assured you'll always have
                access to the latest features, rather than risk being stuck with
                outdated on-premise systems.
                <br />
                <br />
                <a
                  className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
                  href="https://www.agilico.co.uk/business-hosted-voip"
                >
                  Discover More
                </a>
                <br />
                <br />
                <a
                  className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
                  href="https://www.agilico.co.uk/download-voip-guide/"
                >
                  Download Guide
                </a>
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6">
            <div>
              <img
                src={TelcoImg}
                alt="Telecom"
                className="w-full"
              />
            </div>
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                The Benefits of Hosted VoIP
              </h1>
              <ol className="font-medium list-disc ml-4 space-y-0.5 text-gray-500">
                <li>Greater scalability</li>
                <li>Increased mobility</li>
                <li>Business continuity as standard</li>
                <li>Cost effective</li>
                <li>Microsoft Teams integration</li>
                <li>Monitor uptime and usage</li>
                <li>Choose multiple phone numbers</li>
                <li>Future proof</li>
                <li>Relocate with ease</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Telco = withCustomer(_Telco);

export default Telco;
