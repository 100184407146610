import { useQuery } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useQSBuilder from "../hooks/useQSBuilder";
import useCurrentUserContext from "../contexts/UserContext";

export function useLocationDashboard(customerId, dateRange) {
  const { currentUser: { actAs } } = useCurrentUserContext();
  const staleTime = Infinity;
  const { buildQS } = useQSBuilder();
  const { get } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
  });

  const locationsDevices = useQuery({
    queryKey: ["dashboard", "locations", customerId, dateRange],
    queryFn: () => get(`/dashboard/locationstats${buildQS(dateRange)}`),
    staleTime: staleTime,
    cacheTime: staleTime
  });

  return {
    locationsDevices
  };
}
