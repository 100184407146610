import LoadingWheel from "../shared/LoadingWheel";

import { DecreaseIcon, IncreaseIcon } from "../../assets/CustomIcons";

import { useEquipment } from "../../data/useEquipment";
import { useSupport } from "../../data/useSupport";
import useCurrentUserContext from "../../contexts/UserContext";
import { permissionsLookup, useRoleCheck } from "../../hooks/useRoleCheck";


function ServiceStatusCard({
  title,
  value,
  increase = false,
  decrease = false,
  loading = true,
}) {
  return (
    <div className="flex flex-col justify-between bg-white rounded-lg py-4 px-8 prose shadow">
      <p className="mb-0 text-ag-lightBlue text-center">{title}</p>
      <div className="flex items-end justify-center">
        {loading ? (
          <LoadingWheel />
        ) : (
          <>
            <h1 className="m-0 mt-2 font-semibold text-ag-darkBlue">{value}</h1>
            {increase && (
              <IncreaseIcon className="ml-2 w-6 h-6 text-green-500" />
            )}
            {decrease && <DecreaseIcon className="ml-2 w-6 h-6 text-red-500" />}
          </>
        )}
      </div>
    </div>
  );
}

export default function ServiceStatus({ customer }) {
  const { equipment } = useEquipment(customer.id, {
    onlyCopiers: true,
    includeTonerParts: true,
  });
  const { supportTickets } = useSupport(customer.id);
  const { checkAccess } = useRoleCheck();
  const { currentUser } = useCurrentUserContext();

  return (
    <div className={`w-full grid grid-cols-1 gap-4 ${currentUser.user.isAdmin || checkAccess(permissionsLookup.newTicket.realm) ? 'md:grid-cols-2' : 'md:grid-cols-1'}`}>
      <ServiceStatusCard
        title="MFD's (Printers)"
        value={equipment.data?.equipment.length}
        loading={equipment.isLoading}
      />

      {currentUser.user.isAdmin || checkAccess(permissionsLookup.newTicket.realm) ? (
        <ServiceStatusCard
          title="Open support tickets"
          value={supportTickets.data?.workUnits
            .filter((wu) => wu.completedDate === null)
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.reference === value.reference)
            ).length || 0}
          loading={supportTickets.isLoading}
        />
      ) : null}
      {/* <ServiceStatusCard
        increase
        title="Invoices being processed this month"
        value="250"
      />
      <ServiceStatusCard
        decrease
        title="Hybrid Mail items posted this month"
        value="50"
      /> */}
    </div>
  );
}
