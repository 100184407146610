import { useState } from "react";
import moment from "moment";

export function useStatsDate() {
  const today = new Date();
  const initalStart = moment(today)
    .utc()
    .add(-1, "year")
    .add(1, "month")
    .startOf("month")
    .toDate();
  const initalEnd = moment(today)
    .endOf("month")
    .utc()
    .toDate();

  const [startDate, setStartDate] = useState(initalStart);
  const [endDate, setEndDate] = useState(initalEnd);
  const [dateRangeError, setDateRangeError] = useState(undefined);

  const handleStartDateChange = (newStart) => {
    if (newStart) {
      let newDate = moment(newStart).startOf("month").set("hours", 1).toDate();

      if (moment(endDate).diff(newDate, "months") > 12) {
        setDateRangeError(true);
      } else {
        setDateRangeError(false);
      }

      setStartDate(newDate);
    }
  };

  const handleEndDateChange = (newEnd) => {
    if (newEnd) {
      let newDate = moment(newEnd).endOf("month").toDate();

      if (moment(newDate).diff(startDate, "months") > 12) {
        setDateRangeError(true);
      } else {
        setDateRangeError(false);
      }

      setEndDate(newDate);
    }
  };

  return {
    startDate,
    setStartDate: handleStartDateChange,
    endDate,
    setEndDate: handleEndDateChange,
    dateRangeError,
  };
}
