import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router";
import { useMsal } from "@azure/msal-react";
import useApiHelper from "../hooks/useApiHelper";

const actAsKey = "agilico-act-as";

function getActAs(search) {
  if (search.has("actAs")) {
    return search.get("actAs");
  } else {
    return localStorage.getItem(actAsKey);
  }
}

const initState = {
  loading: false,
  customer: { id: 0 },
  user: undefined,
  favouriteTiles: [],
  actAs: null,
};

const UserContext = createContext();

export const UserReducerActions = {
  startLoading: "startLoading",
  stopLoading: "stopLoading",
  switchCustomer: "switchCustomer",
  loadUser: "loadUser",
  logout: "logout",
  setFavouriteTiles: "setFavouriteTiles",
  setActAs: "setActAs",
  removeActAs: "removeActAs",
};

function userReducer(state, action) {
  switch (action.type) {
    case UserReducerActions.setFavouriteTiles:
      return { ...state, favouriteTiles: action.payload };
    case UserReducerActions.startLoading:
      return { ...state, loading: true };
    case UserReducerActions.setActAs:
      localStorage.setItem(actAsKey, action.payload);
      return { ...state, actAs: action.payload };
    case UserReducerActions.removeActAs:
      localStorage.removeItem(actAsKey);
      window.location.assign("/");
      return { ...state, actAs: null };
    case UserReducerActions.stopLoading:
      return { ...state, loading: false };
    case UserReducerActions.switchCustomer:
      return { ...state, customer: (action.payload) ? action.payload : state.user.customer };
    case UserReducerActions.loadUser:
      return { ...state, user: action.payload };
    case UserReducerActions.logout:
      return initState;
    default:
      return state;
  }
}

export function UserContextProvider({ children }) {

  const { accounts, inProgress } = useMsal();
  const [audit, setAudit] = useState({ run: false, handleRedirect: false });
  const [user, dispatch] = useReducer(userReducer, initState);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const actAs = getActAs(search);
  const { get, put } = useApiHelper({
    headers: {
      "X-CustomerId": encodeURIComponent(user.customer.id),
      "X-ActAs": actAs,
    },
  });

  const getUserInfo = async () => {

    dispatch({ type: UserReducerActions.startLoading });

    if (actAs) dispatch({ type: UserReducerActions.setActAs, payload: actAs });

    const user = await get("/user");
    delete user.apiKey;

    if(user) {
      dispatch({ type: UserReducerActions.loadUser, payload: user });
      dispatch({ type: UserReducerActions.setFavouriteTiles, payload: user.favorites, });
      dispatch({ type: UserReducerActions.stopLoading });
    }
  };

  const putUserLoginTracker = async () => {
    setAudit({ ...audit, handleRedirect: false, run: true });
    await put(`/logging/${user.user.id}/logintracker`, JSON.stringify(user.user));
  };

  useEffect(() => {
    const actionAsync = async () => {
      if (accounts.length > 0) await getUserInfo();
      if (inProgress === 'handleRedirect') setAudit({ ...audit, handleRedirect: true });
      if (user?.user && audit.handleRedirect && !audit.run) await putUserLoginTracker();
    };
    actionAsync();
  }, [inProgress, user.customer.id]);

  return (
    <UserContext.Provider value={{ currentUser: user, dispatch }}>
      {children}
    </UserContext.Provider>
  );
}

export default function useCurrentUserContext() {
  const ctx = useContext(UserContext);

  if (!ctx) {
    throw new Error(
      "useCurrentUserContext must be called inside a UserContextProvider"
    );
  }

  return ctx;
}
