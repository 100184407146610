import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { StarIcon as OutlineStarIcon } from "@heroicons/react/24/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/20/solid";

import classNames from "../../../utils/classNames";
import SupportTicket from "../../../assets/AgilicoIcons/SupportTicket.svg";
import Consumables from "../../../assets/AgilicoIcons/Consumables.svg";
import LiveChat from "../../../assets/AgilicoIcons/LiveChat.svg";
import SupportHistory from "../../../assets/AgilicoIcons/SupportHistory.svg";
import Fleet from "../../../assets/AgilicoIcons/Fleet.svg";
import Bills from "../../../assets/AgilicoIcons/Bills.svg";
import ChristmasTree from "../../../assets/AgilicoIcons/ChristmasTree.svg";
import ManagedIT from "../../../assets/AgilicoIcons/ManagedIT.svg";
import MovePrinter from "../../../assets/AgilicoIcons/MovePrinter.svg";
import PrinterManual from "../../../assets/AgilicoIcons/PrinterManual.svg";
import useCurrentUserContext, {
  UserReducerActions,
} from "../../../contexts/UserContext";
import useApiHelper from "../../../hooks/useApiHelper";
import { Result } from "postcss";

export default function WantTile({ type }) {
  const navigate = useNavigate();
  const { companyName } = useParams();
  const [mainText, setMainText] = useState("");
  const [background, setBackground] = useState("bg-white");
  const [mainTextColour, setMainTextColour] = useState("");
  const [wantTextColour, setWantTextColour] = useState("");
  const [icon, setIcon] = useState("");
  const [link, setLink] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const {
    currentUser: { favouriteTiles },
    dispatch,
  } = useCurrentUserContext();
  const { post } = useApiHelper();

  const postFavouriteUpdate = (favourites, cb) => {
    post("/user/favorites", JSON.stringify({ favorites: favourites }))
      .then((res) => {
        cb && cb(favourites);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const isFavourite = favouriteTiles.findIndex((ft) => ft === type) !== -1;

  const handleRemoveFavourite = (event) => {
    event.stopPropagation();

    let removeIndex = favouriteTiles.findIndex((ft) => ft === type);

    let favTilesCopy = [...favouriteTiles];
    favTilesCopy.splice(removeIndex, 1);

    postFavouriteUpdate(favTilesCopy, (updatedFavTiles) =>
      dispatch({
        type: UserReducerActions.setFavouriteTiles,
        payload: updatedFavTiles,
      })
    );
  };

  const handleFavourite = (event) => {
    event.stopPropagation();

    let newFavTiles = [...favouriteTiles, type];

    if (favouriteTiles.length === 4) {
      let favTilesCopy = [...favouriteTiles];
      favTilesCopy.shift();
      newFavTiles = [...favTilesCopy, type];
    }

    postFavouriteUpdate(newFavTiles, (updatedFavTiles) =>
      dispatch({
        type: UserReducerActions.setFavouriteTiles,
        payload: updatedFavTiles,
      })
    );
  };

  useEffect(() => {
    switch (type) {
      case "supportTicket":
        setMainText("Raise a new support ticket");
        setBackground("bg-ag-default");
        setMainTextColour("text-white");
        setWantTextColour("text-ag-lightGreen");
        setIcon(SupportTicket);
        setLink("support/tickets");
        break;
      case "ticketHistory":
        setMainText("View my ticket history");
        setBackground("bg-ag-gray");
        setMainTextColour("text-ag-default");
        setWantTextColour("text-ag-default");
        setIcon(SupportHistory);
        setLink("support/tickets/closed");
        break;
      case "bills":
        setMainText("Review my latest service bills");
        setBackground("bg-ag-gray");
        setMainTextColour("text-ag-default");
        setWantTextColour("text-ag-default");
        setIcon(Bills);
        setLink("support/invoices");
        break;
      case "consumables":
        setMainText("Manage consumable orders");
        setBackground("bg-ag-lightGreen");
        setMainTextColour("text-ag-default");
        setWantTextColour("text-ag-default");
        setIcon(Consumables);
        setLink("support/consumables");
        break;
      case "tonerRecycling":
        setMainText("Order toner recycling box");
        setBackground("bg-ag-lightGreen");
        setMainTextColour("text-ag-default");
        setWantTextColour("text-ag-default");
        setIcon(ChristmasTree);
        setLink("support/toner-recycling");
        break;
      case "asksomethingelse":
        setMainText("Ask about something else");
        setBackground("bg-ag-gray");
        setMainTextColour("text-ag-default");
        setWantTextColour("text-ag-default");
        setIcon(ManagedIT);
        setLink("support/ask-something-else");
        break;
      case "moveprinter":
        setMainText("Request a printer be moved");
        setBackground("bg-ag-default");
        setMainTextColour("text-white");
        setWantTextColour("text-ag-lightGreen");
        setIcon(MovePrinter);
        setLink("support/move-printer");
        break;
      case "drivers":
        setMainText("Download printer drivers");
        setBackground("bg-ag-gray");
        setMainTextColour("text-ag-default");
        setWantTextColour("text-ag-default");
        setIcon(ManagedIT);
        setLink("support/printer-drivers");
        break;
      case "manuals":
        setMainText("View guides and user manuals");
        setBackground("bg-ag-default");
        setMainTextColour("text-white");
        setWantTextColour("text-ag-lightGreen");
        setIcon(PrinterManual);
        setLink("support/manuals");
        break;
      case "submit-readings":
        setMainText("Submit meter readings");
        setBackground("bg-ag-lightGreen");
        setMainTextColour("text-ag-default");
        setWantTextColour("text-ag-default");
        setIcon(Consumables);
        setLink("support/consumables/submit-readings");
        break;
      case "kpax-reports":
        setMainText("View fleet reports");
        setBackground("bg-ag-default");
        setMainTextColour("text-white");
        setWantTextColour("text-ag-lightGreen");
        setIcon(ManagedIT);
        setLink("fleet-management/reports");
        break;
      default:
        break;
    }

    setIsLoading(false);
  }, [type]);

  const handleNavigate = () => {
    if (link) {
      navigate(`/${companyName}/${link}`);
    }
  };

  return (
    !isLoading && (
      <div
        className={classNames(
          background ? background : "",
          "rounded-md hover:cursor-pointer h-40"
        )}
        onClick={handleNavigate}
      >
        <div className="relative p-4">
          <p
            className={classNames(
              wantTextColour ? wantTextColour : "",
              "text-sm font-semibold"
            )}
          >
            I want to...
          </p>
          <div className="flex justify-between">
            <span
              className={classNames(
                mainTextColour ? mainTextColour : "",
                "text-xl font-semibold mt-8"
              )}
            >
              {mainText}
            </span>
            <span className="-mt-6 -mr-2 min-w-[25%]">
              {icon && <img src={icon} width={75} alt="" />}
              <div
                className="absolute top-32 right-2"
                onClick={isFavourite ? handleRemoveFavourite : handleFavourite}
              >
                {isFavourite ? (
                  <SolidStarIcon className={`w-6 h-6 ${wantTextColour}`} />
                ) : (
                  <OutlineStarIcon className={`w-6 h-6 ${wantTextColour}`} />
                )}
              </div>
            </span>
          </div>
        </div>
      </div>
    )
  );
}
