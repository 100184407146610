import { useEffect } from "react";
import { withCustomer } from "../../data/withCustomer";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import SectionTitle from "../../components/shared/SectionTitle";
import WantTile from "../../components/Dashboard/Home/WantTile";

import usePageTitleContext from "../../contexts/PageTitleContext";
import { useRoleCheck, permissionsLookup } from "../../hooks/useRoleCheck";

function _supportHome({ companyLoading, company }) {
  const { setTitle } = usePageTitleContext();
  const { checkAccess } = useRoleCheck();

  useEffect(() => {
    setTitle("Support");
  }, [setTitle]);

  if (companyLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <SectionTitle title={"Help for print"} />

        <div className="w-full xl:w-10/12 2xl:w-8/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 lg:mx-auto gap-x-10 gap-y-5">
          {checkAccess(permissionsLookup.newTicket.realm) ? (
            <WantTile type={"supportTicket"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.closedTicket.realm) ? (
            <WantTile type={"ticketHistory"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.viewInvoice.realm) ? (
            <WantTile type={"bills"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.placeConsumableOrder.realm) ||
          checkAccess(permissionsLookup.placeTonerOrder.realm) ||
          checkAccess(permissionsLookup.placeConsumableOrder.realm) ||
          checkAccess(permissionsLookup.orderHistory.realm) ||
          checkAccess(permissionsLookup.submitMeterReadings.realm) ? (
            <WantTile type={"consumables"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.orderRecyclingBox.realm) ? (
            <WantTile type={"tonerRecycling"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.askSomethingElse.realm) ? (
            <WantTile type={"asksomethingelse"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.printerMove.realm) ? (
            <WantTile type={"moveprinter"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.printerDriver.realm) ? (
            <WantTile type={"drivers"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.guides.realm) ? (
            <WantTile type={"manuals"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.submitMeterReadings.realm) ? (
            <WantTile type={"submit-readings"} />
          ) : (
            <></>
          )}
          {checkAccess(permissionsLookup.kpaxAlerts.realm) ||
          checkAccess(permissionsLookup.kpaxOverview.realm) ||
          checkAccess(permissionsLookup.kpaxReadings.realm) ||
          checkAccess(permissionsLookup.kpaxReports.realm) ? (
            <WantTile type={"kpax-reports"} />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

const SupportHome = withCustomer(_supportHome);

export default SupportHome;
