import { useEffect } from "react";
import { useForm } from "react-hook-form";

import useCurrentUserContext from "../../contexts/UserContext";

export default function CancelSupportRequest({ onConfirm, onClose }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const { currentUser } = useCurrentUserContext();

  //Autofill user info
  useEffect(() => {
    if (!currentUser.loading && currentUser.user) {
      reset({
        YourName: `${currentUser.user.firstname} ${currentUser.user.lastname}`,
        EmailAddress: currentUser.user.emailAddress,
        CompanyName: currentUser.user.customer.name,
        Department: currentUser.user.department,
        ContactNumber: currentUser.user.telephone,
      });
    }
  }, [currentUser.loading, currentUser.user, reset]);


  return (
    <form onSubmit={handleSubmit(onConfirm)} className="text-gray-500">
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">Your Name</label>
          <input
            type="text"
            {...register("YourName")}
            className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">Your Email</label>
          <input
            type="text"
            {...register("EmailAddress")}
            className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">
            Contact Number
          </label>
          <input
            type="text"
            {...register("ContactNumber")}
            className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">
            Company Name
          </label>
          <input
            type="text"
            {...register("CompanyName")}
            className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">Department</label>
          <input
            type="text"
            {...register("Department")}
            className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
          />
        </div>
        <div className="mt-5 col-span-2 space-x-3">
          <button
            type="submit"
            className="p-2 px-4 rounded-full bg-ag-default text-white border border-ag-default hover:bg-ag-lightDefault hover:border-ag-lightDefault"
          >
            Cancel Request
          </button>
          <button
            type="button"
            onClick={onClose}
            className="p-2 px-4 rounded-full bg-white text-ag-default border border-ag-default hover:text-ag-lightDefault hover:border-ag-lightDefault"
          >
            Close
          </button>
        </div>
      </div>
    </form>
  );
}
