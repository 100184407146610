export default function useQSBuilder() {
  function buildQS(input) {
    let qs = "";
    let keys = Object.keys(input);

    keys.forEach((k, i) => {
        qs = qs + `${i === 0 ? '?' : '&'}${k}=${input[k]}`
    });

    return qs;
  }

  return {
    buildQS
  }
}
