import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { withCustomer } from "../../data/withCustomer";

import { IncreaseIcon } from "../../assets/CustomIcons";
import FullscreenLoading from "../../components/shared/FullscreenLoading";
import SectionTitle from "../../components/shared/SectionTitle";
import { PaperAirplaneIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

function _LiveChat({ companyLoading, company }) {
  const [comments, setComments] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");

  const handleNewComment = () => {
    const newComment = {
      name: "You",
      content: currentMessage,
      time: "12:15"
    }

    comments.push(newComment);
    setCurrentMessage("");
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleNewComment();
    }
  }

  if (companyLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <SectionTitle title={"Live Chat"} />
        <div className="flex flex-col justify-between p-5 bg-white rounded-lg w-full min-h-[42vh]">
          <div>
            <div className="p-2 px-5 mr-10 rounded-none rounded-r-xl rounded-b-xl bg-ag-default">
              <div className="flex justify-between">
                <span className="font-bold text-md text-white">Monica Fernandez</span>
                <span className="text-md text-gray-400 font-light">12:11</span>
              </div>
              <p className="text-white py-2">Could you help me with an issue I'm having please?</p>
            </div>
            {comments.length > 0 && comments.map((comment, i) => (
              <div key={i}>
                <div className="mt-5 p-2 px-5 ml-10 text-ag-default rounded-none rounded-l-xl rounded-t-xl bg-gray-300">
                  <div className="flex justify-between">
                    <span className="font-bold text-md">{comment.name}</span>
                    <span className="text-md font-light">{comment.time}</span>
                  </div>
                  <p className="py-2">{comment.content}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-5 flex rounded-full border border-gray-300">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <input
                onChange={(e) => setCurrentMessage(e.target.value)}
                onKeyDown={(e) => handleKeyPress(e)}
                type="text"
                value={currentMessage}
                className="block w-full bg-[#f7fbff] rounded-none rounded-l-full text-ag-default border-gray-300 pl-5 p-3"
                placeholder="Type your message..."
              />
            </div>
            <button onClick={() => handleNewComment()} className="relative bg-[#f7fbff] items-center rounded-r-full px-4 py-2 text-sm font-medium hover:bg-gray-100">
              <PaperAirplaneIcon className="h-5 w-5 text-ag-default" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const LiveChat = withCustomer(_LiveChat);

export default LiveChat;
