import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useCurrentUserContext, {
  UserReducerActions,
} from "../contexts/UserContext";
import useApiHelper from "../hooks/useApiHelper";
import useQSBuilder from "../hooks/useQSBuilder";

export function useAdmin(customerId, queryParams = {}) {
  const {
    dispatch,
    currentUser: { actAs },
  } = useCurrentUserContext();
  const { get, put, patch, post } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
  });
  const { buildQS } = useQSBuilder();
  const queryClient = useQueryClient();

  const { data: users, refetch, isFetching} = useQuery({
    queryKey: ["admin", customerId, queryParams],
    queryFn: () => get(`/admin/users${buildQS(queryParams)}`),
  });

  const updateUser = useMutation(
    (updateReq) => put("/admin/user", JSON.stringify(updateReq)),
    {
      onSettled: () => {
        queryClient.invalidateQueries("admin");
      },
    }
  );

  const updateMe = useMutation(
    (updateReq) => patch("/admin/me", JSON.stringify(updateReq)),
    {
      onSettled: () => {
        get("/user").then((userInfo) => {
          delete userInfo.apiKey;
          dispatch({ type: UserReducerActions.loadUser, payload: userInfo });
        });
      },
    }
  );

  const inviteUser = useMutation(
    (inviteReq) => post("/invite", JSON.stringify(inviteReq)),
  )

  const deleteUser = useMutation(
    (updateReq) => put("/admin/deleteuser", JSON.stringify(updateReq)),
    {
      onSettled: () => {
        queryClient.invalidateQueries("admin");
      },
    }
  );

  return {
    users,
    refetch,
    isFetching,
    updateUser,
    updateMe,
    inviteUser,
    deleteUser
  };
}
