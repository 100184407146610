import { useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import useQSBuilder from "../hooks/useQSBuilder";
import useCurrentUserContext from "../contexts/UserContext";

export function useDeviceDashboard(customerId, dateRange) {
  const { currentUser: { actAs } } = useCurrentUserContext();
  const staleTime = Infinity;
  const { buildQS } = useQSBuilder();
  const { get } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs  },
  });

  const accountManagers = useQuery({
    queryKey: ["dashboard", "accountManagers", customerId, dateRange],
    queryFn: () => get(`/dashboard/accountmanagers`),
    staleTime: staleTime,
  });

  const volumeSummary = useQuery({
    queryKey: ["dashboard", "volumeSummary", customerId, dateRange],
    queryFn: () => get(`/dashboard/volumesummary${buildQS(dateRange)}`),
    staleTime: staleTime,
  });

  const serviceSpend = useQuery({
    queryKey: ["dashboard", "serviceSpend", customerId, dateRange],
    queryFn: () => get(`/dashboard/servicespend${buildQS(dateRange)}`),
    staleTime: staleTime,
  });
  
  return {
    accountManagers,
    volumeSummary,
    serviceSpend,
  };
}
