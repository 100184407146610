import { useState, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import {
  MagnifyingGlassIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";

import LoadingWheel from "../shared/LoadingWheel";
import TablePaginationControls from "../shared/TablePaginationControls";

import { useEquipment } from "../../data/useEquipment";
import useNotification from "../../hooks/useNotifications";
import SiteDropdown from "../shared/SiteDropdown";
import YourContactDetails from "../shared/YourContactDetails";

function consumableReducer(state, action) {
  switch (action.type) {
    case "EDIT_CONSUMABLE":
      const fieldName = action.payload.fieldName;
      const value = action.payload.value;
      const rowData = action.payload.rowData;

      let existingIndex = state.findIndex(
        (st) => st.serial === rowData.serialNumber
      );

      if (existingIndex !== -1) {
        //Update the exisitng item if we have found one
        let orderItem = { ...state[existingIndex] };

        orderItem[fieldName] = parseInt(value);

        if (
          orderItem.staples === 0 &&
          orderItem.bookletStaples === 0
        ) {
          return [...state.filter((s, i) => i !== existingIndex)];
        }

        return [...state.map((s, i) => (i === existingIndex ? orderItem : s))];
      } else {
        //Create a new order item if we don't find one
        let orderItem = {
          serial: rowData.serialNumber,
          deviceName: rowData.description,
          location: rowData.location,
          accountNo: rowData.accountNumber,
          assetNo: rowData.assetNumber,
          equipmentId: rowData.id,
          black: 0,
          magenta: 0,
          yellow: 0,
          cyan: 0,
          waste: 0,
          staples: 0,
          bookletStaples: 0,
        };

        orderItem[fieldName] = parseInt(value);

        return [...state, orderItem];
      }
    case "RESET":
      return [];
    default:
      console.warn("Invalid action passed to tonerReducer");
      return state;
  }
}

function EquipmentTable({
  data,
  consumables,
  consumablesDispatch,
  setFilterSite,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const getValue = (info, fieldName) => {
    let c = consumables.find(
      (c) => c.serial === info.row.original.serialNumber
    );
    let value = 0;

    if (c) {
      value = c[fieldName];
    }

    return value;
  };

  const NumberInputCell = (info, fieldName) => {
    return (
      <input
        type="number"
        min={0}
        defaultValue={0}
        value={getValue(info, fieldName)}
        onChange={(e) => {
          consumablesDispatch({
            type: "EDIT_CONSUMABLE",
            payload: {
              rowData: info.row.original,
              fieldName: fieldName,
              value: e.target.value,
            },
          });
        }}
        className="w-28 border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
      />
    );
  };

  const cols = [
    {
      accessorKey: "serialNumber",
      header: "Serial",
    },
    {
      accessorKey: "description",
      header: "Device Name",
      cell: (info) => (
        <div className="space-y-2">
          <p>{info.getValue()}</p>
        </div>
      ),
    },
    {
      accessorKey: "name",
      header: "Company Name",
    },
    {
      accessorKey: "location",
      header: "Onsite Location",
    },
    {
      header: "Staples",
      cell: (info) =>
        info.row.original.categoryId !== 4 && info.row.original.categoryId !== 79 ? (
          NumberInputCell(info, "staples")
        ) : (
          <div className="text-center">N/A</div>
        ),
    },
    {
      header: "Booklet Staples",
      cell: (info) =>
        info.row.original.categoryId !== 4 && info.row.original.categoryId !== 79  ? (
          NumberInputCell(info, "bookletStaples")
        ) : (
          <div className="text-center">N/A</div>
        ),
    },
  ];

  const table = useReactTable({
    data: data,
    columns: cols,
    filterFns: {
      fuzzy: basicIncludesFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: basicIncludesFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="relative mb-4 md:my-4">
        <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
          <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
            <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              placeholder="Search"
            />
          </div>
          <SiteDropdown
            onChange={(e) => setFilterSite(e.target.value)}
            className="w-full md:w-1/4"
          />
        </div>
      </div>
      <div className="block overflow-y-hidden overflow-x-auto">
        <table className="text-black min-w-full divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, ri) => {
              let rowCells = row.getVisibleCells();
              return (
                <tr
                  key={row.id}
                  className={`${ri % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                >
                  {rowCells.map((cell, ci) => (
                    <td
                      key={cell.id}
                      width={ci > 3 ? "10%" : "auto"}
                      className={`px-3 py-4 text-sm ${
                        ci === 0 ? "pl-5 font-bold" : "font-medium"
                      } ${
                        ci > 0 && ci < 4 ? "align-text-top" : ""
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePaginationControls table={table} />
    </>
  );
}

export default function PlaceConsumablesOrderPartial({ customer }) {
  const [consumables, consumablesDispatch] = useReducer(consumableReducer, []);
  const { addNotification } = useNotification();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { equipment: allEquipment, requestConsumables } = useEquipment(
    customer.id
  );
  // const { currentUser } = useCurrentUserContext();
  // const [staplePricesOpen, setStaplePricesOpen] = useState(false);
  // const [itemIds, setItemIds] = useState([]);
  const [filterSite, setFilterSite] = useState("*");
  const [equipment, setEquipment] = useState([]);

  const onSubmit = (formData) => {

    let consumableRequest = {
      yourName: formData.YourName,
      emailAddress: formData.EmailAddress,
      contactNumber: formData.ContactNumber,
      companyName: formData.CompanyName,
      department: formData.Department,
      notes: formData.notes,
      consumablesOrdered: consumables,
      urgent: formData.isUrgent,
      urgentDeliveryType: formData.urgentDeliveryType,
      urgentDeliverySignature: formData.urgentDeliverySignature,
      poNumber: formData.poNumber
    };

    requestConsumables.mutate(consumableRequest, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Consumable Request Submitted",
          secondaryText:
            "Your consumable request has been submitted to the team for processing",
        });
        reset();
        consumablesDispatch({ type: "RESET" });
      },
    });
  };

  useEffect(() => {
    if (filterSite !== "*" && !allEquipment.isLoading) {
      setEquipment(
        allEquipment.data.equipment.filter(
          (e) => e.customerId === parseInt(filterSite)
        )
      );
    } else if (!allEquipment.isLoading) {
      setEquipment(allEquipment.data.equipment);
    }
  }, [filterSite, allEquipment.data, allEquipment.isLoading]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="text-gray-600">
        <YourContactDetails register={register} reset={reset} errors={errors} />

        <div className="mt-8 flex justify-between items-start">
          <div className="flex flex-col">
            <h1 className="text-xl font-medium text-ag-default">Devices</h1>
          </div>
        </div>

        <div className="my-5">
          {allEquipment.isLoading ? (
            <LoadingWheel />
          ) : (
            <>
              <EquipmentTable
                data={equipment.sort((a, b) => {
                  const accountNumberA = a.accountNumber;
                  const accountNumberB = b.accountNumber;

                  if (accountNumberA < accountNumberB) {
                    return -1;
                  }
                  if (accountNumberA > accountNumberB) {
                    return 1;
                  }
                  return 0;
                }).filter(i => i.description.toLowerCase().indexOf("scanner") === -1 && 
                  ![7, 9, 10, 13, 17].includes(i.categoryId))}
                consumables={consumables}
                consumablesDispatch={consumablesDispatch}
                setFilterSite={setFilterSite}
              />
              {/* <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setStaplePricesOpen(true)}
                  className="mt-5 px-3 py-1 rounded-full text-sm text-ag-darkBlue hover:bg-ag-green"
                >
                  View staple prices
                </button>
              </div> */}
            </>
          )}
        </div>

        <div className="my-5 grid grid-cols-1 md:grid-cols-3 gap-4">
          <h1 className="md:col-span-3 text-xl font-medium text-ag-default">
            Extra Information
          </h1>

          <div className="md:col-span-3 rounded-md bg-red-50 my-4 p-4 w-full">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-red-700">
                  Please note that these maybe chargeable depending on your
                  contract, our Toner Team will contact you with a quote for any
                  chargeable items.
                </p>
              </div>
            </div>
          </div>

          <div className="my-5 grid md:col-span-3 md:grid-cols-3 gap-4">
            <div className="flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                Your PO Number
              </label>
              <input
                type="text"
                {...register("poNumber")}
                className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              />
            </div>
          </div>

          <div className="md:col-span-3 w-full">
            <input
              type="checkbox"
              {...register("isUrgent")}
              className="h-4 w-4 rounded text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
            />
            <span className="ml-2 text-sm">Next Day</span>
            <div className="mt-2 text-sm">
              By ticking this box you agree to an additional Next Day delivery
              charge for this order. Our standard delivery service takes 1-2
              working days and postage is free for contracted customers. If you
              have any queries please contact our Toner Team on 0208 688 4243
              (opt 2) or{" "}
              <a className="text-ag-default" href="mailto: toner@agilico.co.uk">
                toner@agilico.co.uk
              </a>
            </div>
            {watch("isUrgent") && (
              <div className="mt-4 rounded-md p-4 bg-red-50 text-red-700 text-sm">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h2 className="text-lg leading-6 mb-2">
                      Urgent Order Form
                    </h2>
                    <div className="text-sm mb-4">
                      Please fill in the below information in order to confirm
                      that you agree to be charged for the quoted price for your
                      order.
                    </div>
                    <div className="flex flex-col w-1/2 mb-4">
                      <label className="mb-1">Delivery Type</label>
                      <select
                        {...register("urgentDeliveryType")}
                        className="border p-2 bg-red-50 border-red-700"
                      >
                        <option value="Next Day">Next Day - (£8.95 + vat)</option>
                        <option value="Next Day before 12.00 midday">
                          Next Day Before 12:00 midday - (£20.00 + vat)
                        </option>
                        <option value="Next Day before 10.30am">
                          Next Day Before 10:30am - (£30.00 + vat)
                        </option>
                      </select>
                    </div>
                    <div className="flex flex-col w-1/2 mb-4">
                      <label className="mb-1">I Agree</label>
                      <input
                        {...register("urgentDeliverySignature")}
                        type="text"
                        placeholder="Type your Name to confirm"
                        className="border p-2 bg-red-50 border-red-700"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mt-8">
          <button
            type="submit"
            className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
          >
            Submit Request
          </button>
        </div>
      </form>
    </div>
  );
}
