import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

import AgilicoLogo from "../assets/logo.svg";

import ErrorBoundary from "../ErrorBoundary";

export default function UauthenticatedLayout({ title = "Invite" }) {
  return (
    <div className="flex h-full flex-col">
      {/* Bottom section */}
      <div className="flex min-h-0 flex-1 overflow-hidden">
        {/* Narrow sidebar*/}
        <nav
          aria-label="Sidebar"
          className="hidden md:flex md:flex-col md:flex-shrink-0 md:overflow-y-auto md:bg-ag-darkBlue"
        >
          <div className="md:my-4 xl:my-6 mx-4">
            <img src={AgilicoLogo} alt="Agilico Logo" />
          </div>
          <div className="flex flex-col md:w-16 xl:w-20 space-y-4"></div>
        </nav>

        {/* Main area */}
        <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">
          <section className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-first bg-gray-100 p-6 md:px-10">
            <div className="w-full grid grid-cols-6">
              <div className="prose-xl md:prose-2xl col-span-5 md:col-span-4">
                <h2 className="!mb-4 !mt-0 font-light text-ag-lightBlue">
                  <strong className="font-bold text-ag-darkBlue">
                    Agilico -
                  </strong>{" "}
                  {title}
                </h2>
              </div>
            </div>
            {/* Here to change max width of content */}
            <div className="w-full mt-10">
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
