import { useEffect } from "react";

export default function VerifyVideo() {
  useEffect(() => {
    const videoScript = document.createElement("script");
    const generalScript = document.createElement("script");

    videoScript.src = "https://fast.wistia.com/embed/medias/31xyugh22a.jsonp";
    videoScript.async = true;

    generalScript.src = "https://fast.wistia.com/assets/external/E-v1.js";
    generalScript.async = true;

    document.body.appendChild(videoScript);
    document.body.appendChild(generalScript);
  });

  return (
    <div
      className="wistia_responsive_padding"
      style={{ padding: "56.25% 0 0 0", position: "relative" }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <span
          className="wistia_embed wistia_async_31xyugh22a popover=true popoverAnimateThumbnail=true videoFoam=true"
          style={{
            display: "inline-block",
            height: "100%",
            position: "relative",
            width: "100%",
          }}
        >
          &nbsp;
        </span>
      </div>
    </div>
  );
}
