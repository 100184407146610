import { useState } from "react";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import LoadingWheel from "../../components/shared/LoadingWheel";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import useCurrentUserContext from "../../contexts/UserContext";
import { useAdmin } from "../../data/useAdmin";
import { UserEdit } from "./UserEdit";
import UserInvite from "./UserInvite";

function UserRow({ currentUser, user, index, onEditClick }) {
  return (
    <li
      className={`flex items-center justify-between py-3 pl-3 pr-4 text-sm ${
        index % 2 === 0 && "bg-gray-100"
      }`}
    >
      <div className="flex w-0 flex-1 items-center">
        <span className="ml-2 w-0 flex-1 font-medium truncate">
          {user.firstname} {user.lastname}
          <br />
          <span className="text-xs font-normal text-gray-800">{user.emailAddress}</span>
        </span>
      </div>
      <div className="ml-4 flex-shrink-0">
        {currentUser.user.isAdmin === 1 ? (
          <button
            onClick={() => onEditClick(user)}
            className="p-1.5 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
          >
            Edit
          </button>
        ) : (
          <div className="text-gray-600">{user.isAdmin ? "Admin" : ""}</div>
        )}
      </div>
    </li>
  );
}

export default function UserList({ customer }) {
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [showInvite, setShowInvite] = useState(false);
  const { currentUser } = useCurrentUserContext();
  const { users, refetch, isFetching } = useAdmin(customer.id);

  if (currentUser.loading) {
    <FullscreenLoading />;
  } else {
    if (selectedUser) {
      return (
        <UserEdit
          user={selectedUser}
          setUser={setSelectedUser}
          customer={customer}
          refetch={refetch}
        />
      );
    } else if (showInvite) {
      return <UserInvite customer={customer} setShowInvite={setShowInvite} refetch={refetch} />;
    } else {
      return (
        <section aria-labelledby="users-list-heading">
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            
            {currentUser.user.isAdmin === 1 && (
              <div className="bg-gray-50 p-2 text-right sm:px-6 space-x-4">
                <button
                  onClick={() => setShowInvite(true)}
                  className="p-1.5 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
                >
                  Add User
                </button>
              </div>
            )}
            
            <div className="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2
                  id="users-list-heading"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Admins
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  View admins of your portal.
                </p>
              </div>

              <div className="mt-6 gap-6">
                {isFetching ? (
                  <LoadingWheel />
                ) : (
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    {users
                      .filter((u) => u.isAdmin === 1)
                      .map((user, i) => (
                        <UserRow
                          key={`user-${i}`}
                          user={user}
                          index={i}
                          onEditClick={() => setSelectedUser(user)}
                          currentUser={currentUser}
                        />
                      ))}
                  </ul>
                )}
              </div>
              <div className="mt-8">
                <h2
                  id="users-list-heading"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Users
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Update your users permissions and accounts.
                </p>
              </div>

              <div className="mt-6 gap-6">
                {isFetching ? (
                  <LoadingWheel />
                ) : (
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    {users
                      .filter((u) => u.isAdmin === 0)
                      .map((user, i) => (
                        <UserRow
                          key={`user-${i}`}
                          user={user}
                          index={i}
                          onEditClick={() => setSelectedUser(user)}
                          currentUser={currentUser}
                        />
                      ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}
