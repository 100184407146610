import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Bars3Icon } from "@heroicons/react/20/solid";

import classNames from "../utils/classNames";
import { withCustomer } from "../data/withCustomer";
import AgilicoLogo from "../assets/logo.svg";
import {
  AccountIcon,
  SupportIcon,
  DashbaordIcon,
  FleetManagementIcon,
  HomeIcon,
  ItManagedServicesIcon,
  LogoutIcon,
  VerifyIcon,
  TelcoIcon,
} from "../assets/CustomIcons";

import { useStatsDate } from "../hooks/useStatsDate";
import { useServiceDashboard } from "../data/useServiceDashboard";
import { useDeviceDashboard } from "../data/useDeviceDashboard";
import { useLocationDashboard } from "../data/useLocationDashboard";

import SwitchCustomer from "../components/Dashboard/SwitchCustomer";
import Sidebar from "../components/Dashboard/Sidebar/Sidebar";
import NotificationFlyout from "../components/Dashboard/Sidebar/NotificationFlyout";
import FullscreenLoading from "../components/shared/FullscreenLoading";
import usePageTitleContext from "../contexts/PageTitleContext";
import MobileNavigation from "../components/shared/MobileNavigation";
import { permissionsLookup, useRoleCheck, useAdminCheck } from "../hooks/useRoleCheck";
import ErrorBoundary from "../ErrorBoundary";
import useCurrentUserContext, {
  UserReducerActions,
} from "../contexts/UserContext";

function _DashboardLayout({ customer, customerLoading }) {
  const { currentUser, dispatch } = useCurrentUserContext();
  const { checkAccess,  } = useRoleCheck();
  const { checkAdminAccess,  } = useAdminCheck();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const { title } = usePageTitleContext();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [open, setOpen] = useState(false);

  //Preloading dashboard data
  const { startDate, endDate } = useStatsDate();
  /* eslint-disable */
  const dateRange = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
  const { breakdownCalls, calls, firstFixRate, serviceStats } =
    useServiceDashboard(customer.id, dateRange);
  const {
    accountManagers,
    volumeSummary,
    serviceSpend,
    monoColourVolumes,
    colourPercent,
  } = useDeviceDashboard(customer.id, dateRange);
  const { locationsDevices } = useLocationDashboard(customer.id, dateRange);
  /* eslint-enable */

  const sidebarNavigation = useMemo(
    () => [
      {
        name: "Home",
        href: "",
        icon: HomeIcon,
        current: false,
        hasPermission: true,
      },
      {
        name: "Support",
        href: "support",
        icon: SupportIcon,
        current: false,
        hasPermission: true,
      },
      {
        name: "Dashboard",
        href: "statistics",
        icon: DashbaordIcon,
        current: false,
        hasPermission:
          checkAccess(permissionsLookup.dashboardStats.realm) ||
          checkAccess(permissionsLookup.dashboardService.realm) ||
          checkAccess(permissionsLookup.dashboardLocation.realm),
      },
      {
        name: "Fleet Manager",
        href: "fleet-management",
        icon: FleetManagementIcon,
        current: false,
        hasPermission:
          checkAccess(permissionsLookup.kpaxAlerts.realm) ||
          checkAccess(permissionsLookup.kpaxOverview.realm) ||
          checkAccess(permissionsLookup.kpaxReadings.realm) ||
          checkAccess(permissionsLookup.kpaxReports.realm),
      },
      {
        name: "Verify",
        href: "verify",
        icon: VerifyIcon,
        current: false,
        hasPermission: true,
      },
      {
        name: "IT",
        href: "managed-it",
        icon: ItManagedServicesIcon,
        current: false,
        hasPermission: true,
      },
      {
        name: "Telco",
        href: "telecom",
        icon: TelcoIcon,
        current: false,
        hasPermission: true,
      },
      {
        name: "Account",
        href: "settings",
        icon: AccountIcon,
        current: false,
        hasPermission: checkAdminAccess(),
      },
    ],
    [checkAccess, checkAdminAccess]
  );

  const handleNavigate = (item) => {
    if (mobileNavOpen) {
      setMobileNavOpen(false);
    }

    sidebarNavigation.forEach((element) => {
      element.current = false;
    });
    item.current = true;
    navigate(`/${customer.reference.toLowerCase()}/${item.href}`);
  };

  useEffect(() => {
    let urlParts = location.pathname.split("/");

    sidebarNavigation.forEach((element) => {
      element.current = false;
      if (element.href === urlParts[2]) {
        element.current = true;
      }
    });
  }, [location, sidebarNavigation]);

  const handleLogout = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };

  return customerLoading ? (
    <FullscreenLoading />
  ) : (
    <>
      <NotificationFlyout open={open} setOpen={setOpen} title={""}>
        <Sidebar />
      </NotificationFlyout>
      <MobileNavigation
        sidebarOpen={mobileNavOpen}
        setSidebarOpen={setMobileNavOpen}
        sidebarNavigation={sidebarNavigation}
        handleNavigate={handleNavigate}
      />
      {currentUser.actAs && (
        <div className={`sticky top-0 z-40 ${currentUser?.user?.actAsAuth ? 'bg-blue-100' : 'bg-red-100'} flex items-center justify-center`}>
          {currentUser?.user?.actAsAuth && <div className={`text-blue-800 font-medium text-sm py-2`}>
            {`You are currently viewing and acting as: ${currentUser.user.firstname} ${currentUser.user.lastname} - ${currentUser.customer.name}`}
            <button
              onClick={() => dispatch({ type: UserReducerActions.removeActAs })}
              className="bg-blue-500 text-xs text-white px-2 py-1 rounded-md ml-2"
            >
              Logout of {currentUser.customer.name}
            </button>
          </div>}
          {!currentUser?.user?.actAsAuth && <div className={`text-blue-800 font-medium text-sm py-2`}>
            {`You do not have permission to view this customer`}
            <button
              onClick={() => dispatch({ type: UserReducerActions.removeActAs })}
              className="bg-blue-500 text-xs text-white px-2 py-1 rounded-md ml-2"
            >
              Close and Continue
            </button>
          </div>}
        </div>
      )}
      <div className="flex h-full flex-col">
        {/* Bottom section */}
        <div className="flex min-h-0 flex-1 overflow-hidden">
          {/* Narrow sidebar*/}
          <nav
            aria-label="Sidebar"
            className="h-screen hidden md:flex md:flex-col md:flex-shrink-0 md:overflow-y-auto md:bg-ag-darkBlue"
          >
            <div className="md:my-4 xl:my-6 mx-4">
              <a href="https://www.agilico.co.uk/">
                <img src={AgilicoLogo} alt="Agilico Logo" />
              </a>
            </div>
            <div className="flex flex-col md:w-16 xl:w-20 space-y-4">
              {sidebarNavigation.map(
                (item) =>
                  item.hasPermission && (
                    <button
                      type="button"
                      key={item.name}
                      onClick={() => handleNavigate(item)}
                      className={classNames(
                        item.current
                          ? "text-ag-green border-r-4 border-ag-green"
                          : "text-white hover:text-gray-400",
                        "flex-shrink-0 inline-flex flex-col items-center justify-center md:w-16 xl:w-20"
                      )}
                    >
                      <item.icon
                        className="h-[32px] w-[32px] xl:h-[42px] xl:w-[42px] my-1"
                        aria-hidden="true"
                      />
                      <span className="font-semibold md:text-[0.65rem] xl:text-xs">
                        {item.name}
                      </span>
                    </button>
                  )
              )}
              <button onClick={handleLogout} className="self-center ">
                <div className="flex flex-col items-center justify-center text-white hover:text-ag-gray !mb-20">
                  <LogoutIcon className="h-[32px] w-[32px] xl:h-[42px] xl:w-[42px] my-1" />
                  <span className="font-semibold text-xs">Log out</span>
                </div>
              </button>
            </div>
          </nav>

          {/* Main area */}
          <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">
            <section className={`flex ${currentUser.actAs ? 'h-calc-100-minus-40' : ''} min-w-0 flex-1 flex-col overflow-y-auto lg:order-first bg-gray-100 p-6 md:px-10`}>
              <div className="w-full grid grid-cols-6">
                <button
                  type="button"
                  className="border-r border-gray-200 text-gray-500  md:hidden"
                  onClick={() => setMobileNavOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="prose-xl md:prose-2xl col-span-5 md:col-span-4">
                  <h2 className="!mb-4 !mt-0 font-light text-ag-lightBlue">
                    <strong className="font-bold text-ag-darkBlue">
                      Agilico -
                    </strong>{" "}
                    {title}
                  </h2>
                </div>
                <div className="col-span-6 md:col-span-2">
                  <SwitchCustomer />
                </div>
              </div>
              {/* Here to change max width of content */}
              <div className="w-full mt-10">
                <ErrorBoundary>
                  <Outlet />
                </ErrorBoundary>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}

const DashboardLayout = withCustomer(_DashboardLayout);

export default DashboardLayout;
