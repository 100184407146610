import { useEffect, useState } from "react";
import { useParams } from "react-router";
import ReactPlayer from "react-player";
import { Document, Page } from "react-pdf";

import { withCustomer } from "../../data/withCustomer";
import { useContent } from "../../data/useContent";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import usePageTitleContext from "../../contexts/PageTitleContext";

function _ViewManual({ customerLoading, customer }) {
  const { manualId } = useParams();
  const { content } = useContent(customer.id);
  const { setTitle } = usePageTitleContext();
  const [manual, setManual] = useState(null);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [pdfPage, setPdfPage] = useState(1);
  const [pdfError, setPdfError] = useState(false);

  const pages = [
    { to: "..", label: "Guides", current: false },
    { to: "", label: "View Manual", current: true },
  ];

  useEffect(() => {
    setTitle("View Manual");
    if (manualId && !content.isLoading) {
      let manual = content.data.content.find(
        (c) => c.id === parseInt(manualId)
      );
      setManual(manual);
      setPdfLoading(manual.fileType === "pdf");
    }
  }, [content.isLoading, content.data, manualId]);

  if (customerLoading || content.isLoading || !manual) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <Breadcrumbs pages={pages} />

        <div className="w-full mt-8 p-4 bg-white shadow rounded-lg">
          {manual.fileType === "mp4" ? (
            <ReactPlayer
              url={`https://360.agilico.co.uk/externalpages/assets/${manual.fileUrl}`}
              playing
              width={"100%"}
              height={"100%"}
              controls={true}
            />
          ) : (
            <>
              <Document
                file={`https://360.agilico.co.uk/externalpages/assets/${manual.id}/${manual.fileUrl}`}
                onLoadSuccess={() => setPdfLoading(false)}
                onLoadError={() => setPdfError(true)}
              >
                <Page pageNumber={pdfPage} renderAnnotationLayer={false} />
              </Document>
              {
                <div className="flex justify-center">
                  <div className="w-48 flex justify-around items-center">
                    <button
                      type="button"
                      className={`rounded px-2 py-1 text-white bg-ag-lightDefault hover:bg-ag-default ${
                        pdfPage === 1 && "!bg-gray-200"
                      }`}
                      onClick={() => setPdfPage((oldPage) => oldPage + 1)}
                      disabled={pdfPage === 1}
                    >
                      {"<"}
                    </button>
                    <label>Page</label>
                    <input
                      type="number"
                      min="1"
                      value={pdfPage}
                      onChange={(e) => setPdfPage(e.target.value)}
                      className="w-16 text-center border text-black bg-gray-50 border-gray-400 p-1 focus:border-ag-darkBlue"
                    />
                  </div>
                  <button
                      type="button"
                      className="rounded px-2 py-1 text-white bg-ag-lightDefault hover:bg-ag-default"
                      onClick={() => setPdfPage((oldPage) => oldPage - 1)}
                    >
                      {">"}
                    </button>
                </div>
              }
            </>
          )}
        </div>
      </div>
    );
  }
}

const ViewManual = withCustomer(_ViewManual);

export default ViewManual;
