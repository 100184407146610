import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";

import LoadingWheel from "../shared/LoadingWheel";
import TablePaginationControls from "../shared/TablePaginationControls";

import { useEquipment } from "../../data/useEquipment";
import { ConsumableType } from "../../enums/ConsumableType";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import useNotification from "../../hooks/useNotifications";
import SiteDropdown from "../shared/SiteDropdown";
import useCurrentUserContext from "../../contexts/UserContext";
import { useDeviceMeters } from "../../data/useDeviceMeters";
import YourContactDetails from "../shared/YourContactDetails";

function tonerReducer(state, action, error, setError) {
  if (error) {
    setError(undefined);
  }

  switch (action.type) {
    case "ADD_TONER":
      const fieldName = action.payload.fieldName;
      const rowData = action.payload.rowData;

      let existingIndex = state.findIndex(
        (st) => st.serial === rowData.serialNumber
      );

      if (existingIndex !== -1) {
        //Update the exisitng item if we have found one
        let orderItem = { ...state[existingIndex] };

        orderItem[fieldName] = orderItem[fieldName] + 1;

        return [...state.map((s, i) => (i === existingIndex ? orderItem : s))];
      } else {
        //Create a new order item if we don't find one
        let orderItem = {
          serial: rowData.serialNumber,
          deviceName: rowData.description,
          location: rowData.location,
          accountNo: rowData.accountNumber,
          assetNo: rowData.assetNumber,
          equipmentId: rowData.id,
          black: 0,
          magenta: 0,
          yellow: 0,
          cyan: 0,
          waste: 0,
          staples: 0,
          bookletStaples: 0,
          printHeads: 0,
          wideFormatCart: 0,
        };

        orderItem[fieldName] = orderItem[fieldName] + 1;

        return [...state, orderItem];
      }
    case "REMOVE_TONER":
      {
        const fieldName = action.payload.fieldName;
        const rowData = action.payload.rowData;

        let existingIndex = state.findIndex(
          (st) => st.serial === rowData.serialNumber
        );

        if (existingIndex !== -1) {
          //Update the exisitng item if we have found one
          let orderItem = { ...state[existingIndex] };
          orderItem[fieldName] = orderItem[fieldName] - 1;

          if (
            orderItem.black === 0 &&
            orderItem.cyan === 0 &&
            orderItem.magenta === 0 &&
            orderItem.yellow === 0 &&
            orderItem.waste === 0
          ) {
            return [...state.filter((s, i) => i !== existingIndex)];
          }

          return [
            ...state.map((s, i) => (i === existingIndex ? orderItem : s)),
          ];
        }
      }
      break;
    case "RESET":
      return [];
    default:
      console.warn("Invalid action passed to tonerReducer");
      return state;
  }
}

function EquipmentTable({
  data,
  selectedToners,
  selectedTonerDispatch,
  setFilterSite,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const ActiveOrder = ({ order }) => {
    let classString;
    let consumableLabel = ConsumableType[order.consumableTypeId];

    switch (consumableLabel) {
      case "black":
        classString = "text-white bg-black";
        break;
      case "cyan":
        classString = "text-black bg-ag-cyan";
        break;
      case "magenta":
        classString = "text-white bg-ag-magenta";
        break;
      case "yellow":
        classString = "text-black bg-ag-yellow";
        break;
      case "waste":
      case "staple cartridge":
        classString = "text-black border border-gray-500";
        break;
      default:
        classString = "text-black";
        break;
    }

    return (
      <div
        className={`rounded-lg p-2 text-[0.775rem] leading-[1.15] text-center ${classString}`}
      >
        {capitalizeFirstLetter(consumableLabel)} on Order <br />
        Ref: {order.reference} <br />
        Part No: {order.partNumber}
      </div>
    );
  };

  const getChecked = (info, fieldName) => {
    let st = selectedToners.find(
      (st) => st.serial === info.row.original.serialNumber
    );
    let checked = false;

    if (st) {
      checked = st[fieldName] === 1 ? true : false;
    }

    return checked;
  };

  const CheckboxCell = (info, fieldName, getChecked) => {
    const val = info.getValue();

    return val !== "N/A" ? (
      <div className="text-center">
        <input
          name={fieldName}
          type="checkbox"
          className="rounded h-6 w-6 border text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
          checked={getChecked(info, fieldName)}
          onChange={(e) => {
            if (e.target.checked) {
              selectedTonerDispatch({
                type: "ADD_TONER",
                payload: {
                  fieldName: fieldName,
                  rowData: info.row.original,
                },
              });
            } else {
              selectedTonerDispatch({
                type: "REMOVE_TONER",
                payload: {
                  fieldName: fieldName,
                  rowData: info.row.original,
                },
              });
            }
          }}
        />
        <br />
        {/* <span className="text-xs">PN: {val}</span> */}
      </div>
    ) : (
      <div className="text-center">N/A</div>
    );
  };

  const cols = [
    {
      accessorKey: "serialNumber",
      header: "Serial",
    },
    {
      accessorKey: "description",
      header: "Device Name",
      cell: (info) => (
        <div className="space-y-2">
          <p>{info.getValue()}</p>
          {info.row.original.activeOrders
            .sort((a, b) => a.consumableTypeId - b.consumableTypeId)
            .map((ao, i) => (
              <ActiveOrder key={`${ao.reference}_${i}`} order={ao} />
            ))}
        </div>
      ),
    },
    {
      accessorKey: "name",
      header: "Company Name",
    },
    {
      accessorKey: "location",
      header: "Onsite Location",
    },
    {
      id: "blackToner",
      header: () => (
        <div className="flex items-center">
          <div className="mr-1 w-4 h-4 bg-black rounded-full"></div>
          Black Toner
        </div>
      ),
      accessorFn: (row) =>
        row.compatibleItems.black ? row.compatibleItems.black.desc : "N/A",
      cell: (info) => CheckboxCell(info, "black", getChecked),
    },
    {
      id: "cyanToner",
      header: () => (
        <div className="flex items-center">
          <div className="mr-1 w-4 h-4 bg-ag-cyan rounded-full"></div>
          Cyan Toner
        </div>
      ),
      accessorFn: (row) =>
        row.compatibleItems.cyan ? row.compatibleItems.cyan.desc : "N/A",
      cell: (info) => CheckboxCell(info, "cyan", getChecked),
    },
    {
      id: "magentaToner",
      header: () => (
        <div className="flex items-center">
          <div className="mr-1 w-4 h-4 bg-ag-magenta rounded-full"></div>
          Magenta Toner
        </div>
      ),
      accessorFn: (row) =>
        row.compatibleItems.magenta ? row.compatibleItems.magenta.desc : "N/A",
      cell: (info) => CheckboxCell(info, "magenta", getChecked),
    },
    {
      id: "yellowToner",
      header: () => (
        <div className="flex items-center">
          <div className="mr-1 w-4 h-4 bg-ag-yellow rounded-full"></div>
          Yellow Toner
        </div>
      ),
      accessorFn: (row) =>
        row.compatibleItems.yellow ? row.compatibleItems.yellow.desc : "N/A",
      cell: (info) => CheckboxCell(info, "yellow", getChecked),
    },
    {
      id: "wasteToner",
      header: () => <div className="flex items-center">Waste Toner</div>,
      accessorFn: (row) =>
        row.compatibleItems.waste ? row.compatibleItems.waste.desc : "N/A",
      cell: (info) => CheckboxCell(info, "waste", getChecked),
    },
  ];

  const table = useReactTable({
    data: data,
    columns: cols,
    filterFns: {
      fuzzy: basicIncludesFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: basicIncludesFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="relative mb-4 md:my-4">
        <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
          <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
            <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              placeholder="Search"
            />
          </div>
          <SiteDropdown
            onChange={(e) => setFilterSite(e.target.value)}
            className="w-full md:w-1/4"
          />
        </div>
      </div>
      <div className="rounded-md bg-red-50 my-4 p-4 w-full">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-red-700">
              This form allows one set of toners per printer to be ordered,
              should you require more, please contact the Toner Team on,{"  "}
              <a className="text-red-900" href="mailto: toner@agilico.co.uk">
                toner@agilico.co.uk
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="block overflow-y-hidden overflow-x-auto">
        <table className="text-black min-w-full divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, ri) => {
              let rowCells = row.getVisibleCells();
              return (
                <tr
                  key={row.id}
                  className={`${ri % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                >
                  {rowCells.map((cell, ci) => {
                    //if its not a wide format device or its the first 3 cols - display normally
                    if ((row.original.categoryId !== 4 && row.original.categoryId !== 79) || ci <= 3) {
                      return (
                        <td
                          key={cell.id}
                          width={ci > 2 ? "160" : "auto"}
                          className={`px-3 py-4 text-sm ${
                            ci === 0 ? "pl-5 font-bold" : "font-medium"
                          } ${
                            ci > 0 && ci < 4 ? "align-text-top" : ""
                          }`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                      //if it is a wide format device and the first toner col - display a cell with a colspan
                    } else if (ci === 4 && (row.original.categoryId === 4 || row.original.categoryId === 79)) {
                      return (
                        <td
                          key={cell.id}
                          width="auto"
                          colSpan={5}
                          className="px-3 py-4 text-sm text-red-700 font-medium text-center"
                        >
                          Contact toner team on{" "}
                          <a
                            className="text-red-900"
                            href="mailto: toner@agilico.co.uk"
                          >
                            toner@agilico.co.uk
                          </a>
                        </td>
                      );
                    }
                    else {
                      return (
                        <td
                          key={cell.id}
                          width="auto"
                          colSpan={1}
                        ></td>
                      )
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePaginationControls table={table} />
    </>
  );
}

function MeterTable({ customer, data, onSubmit, setStep }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ reValidateMode: "onBlur" });

  const submit = (data) => {
    let flatReading = [];

    //Flatten readings for posting to API
    data.meterReadings.forEach((outerEl) => {
      if (outerEl.meter) {
        outerEl.meter.forEach((el) => {
          flatReading.push({
            current: parseInt(el.current.replace(/[,\sE]/g, ''), 10),
            new: parseInt(el.new),
            serialNo: el.serialNo,
            type: el.type,
          });
        });
      }
    });

    onSubmit({ meterReadings: flatReading });
  };

  const MeterRow = ({ device, rowNo }) => {
    const { meters } = useDeviceMeters(customer.id, device.serial);

    const MeterTypeRow = ({ meter, index }) => {
      return (
        <tr>
          <input
            {...register(`meterReadings.${rowNo}.meter.${index}.serialNo`, {
              value: device.serial,
            })}
            type="hidden"
          />
          <td className="w-4/12 py-2 px-1">
            <input
              {...register(`meterReadings.${rowNo}.meter.${index}.type`, {
                value: meter.type,
              })}
              type="hidden"
            />
            {meter.type}
          </td>
          <td className="w-4/12 py-2 px-1">
            <div className="flex flex-col items-center">
              <label className="mb-1 text-gray-500 text-sm">
                Current Reading
              </label>
              <input
                {...register(`meterReadings.${rowNo}.meter.${index}.current`, {
                  value: `${new Intl.NumberFormat("en-GB").format(meter.current)} ${(meter.readingTypeId === 3 ? 'E' : '')}`,
                })}
                disabled
                className="border w-28 text-center text-gray-600 bg-gray-200 border-gray-400 px-2 py-1 focus:border-ag-darkBlue $`"
              />
            </div>
          </td>
          <td className="w-4/12 py-2 px-1">
            <div className="flex flex-col items-center">
              <label className="mb-1 text-gray-500 text-sm">New Reading</label>
              <input
                {...register(`meterReadings.${rowNo}.meter.${index}.new`, {
                  required: "Please enter a value",
                  min: {
                    value: meter.current,
                    message: "Reading cannot be less than current",
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: "Please enter only numbers",
                  },
                })}
                type="number"
                aria-invalid={
                  errors.meterReadings?.[rowNo]?.meter[index]?.new
                    ? "true"
                    : "false"
                }
                className={`border w-28 text-center text-black bg-gray-50 border-gray-400 px-2 py-1 focus:border-ag-darkBlue ${
                  errors.meterReadings?.[rowNo]?.meter[index]?.new &&
                  "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                }`}
              />
              {errors.meterReadings?.[rowNo]?.meter[index]?.new && (
                <p role="alert" className="text-red-600">
                  {errors.meterReadings?.[rowNo]?.meter[index]?.new?.message}
                </p>
              )}
            </div>
          </td>
        </tr>
      );
    };

    return (
      <tr className={`${rowNo % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
        <td className="px-3 py-4 pl-5 text-sm font-bold">{device.serial}</td>
        <td className="px-3 py-4 text-sm font-medium">{device.deviceName}</td>
        <td className="px-3 text-sm font-medium">
          <table className="w-full">
            <tbody>
              {!meters.isLoading ? (
                meters.data.meters.length > 0 ? (
                  meters.data.meters.map((meter, i) => (
                    <MeterTypeRow
                      meter={meter}
                      index={i}
                      key={`meterRow-${i}`}
                    />
                  ))
                ) : (
                  <tr>
                    <td className="text-gray-500">
                      No current readings, please call 0208 688 4243 (opt 2)
                    </td>
                  </tr>
                )
              ) : (
                <LoadingWheel />
              )}
            </tbody>
          </table>
        </td>
      </tr>
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <table className="text-black min-w-full divide-y border divide-gray-300">
        <thead>
          <tr className="border-b-2 border-gray-300">
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Serial Number
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Device Name
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Meters
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((device, i) => (
            <MeterRow key={i} rowNo={i} device={device} />
          ))}
        </tbody>
      </table>
      <div className="mt-10 mb-5 space-x-4">
        <button
          onClick={() => setStep(0)}
          className="p-2 px-4 rounded-full bg-white text-ag-default border border-ag-default hover:text-ag-lightDefault hover:border-ag-lightDefault"
        >
          Back
        </button>
        <button
          type="submit"
          className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
        >
          Submit Request
        </button>
      </div>
    </form>
  );
}

export default function PlaceTonerOrderPartial({ customer }) {
  const { addNotification } = useNotification();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { urgentDeliveryType: "None" } });
  const {
    equipment: allEquipment,
    requestConsumables,
    submitReadings,
  } = useEquipment(customer.id, {
    includeTonerParts: true,
    inclueOrders: true,
  });
  const { currentUser } = useCurrentUserContext();
  const [filterSite, setFilterSite] = useState("*");
  const [equipment, setEquipment] = useState([]);
  const [step, setStep] = useState(0);
  const [tonerData, setTonerData] = useState(undefined);
  const [selectedTonerError, setSelectedTonerError] = useState(undefined);
  const [selectedToners, selectedTonerDispatch] = useReducer(
    (state, action) =>
      tonerReducer(state, action, selectedTonerError, setSelectedTonerError),
    []
  );

  const nextStep = (formData) => {
    if (!selectedToners.length) {
      setSelectedTonerError("Please select at least 1 toner to order");
      return;
    }

    setStep(1);

    let tonerRequest = {
      yourName: formData.YourName,
      emailAddress: formData.EmailAddress,
      contactNumber: formData.ContactNumber,
      companyName: formData.CompanyName,
      department: formData.Department,
      notes: formData.notes,
      consumablesOrdered: selectedToners,
      urgent: formData.isUrgent,
      poNumber: formData.poNumber,
      urgentDeliveryType: formData.urgentDeliveryType,
      urgentDeliverySignature: formData.urgentDeliverySignature,
    };

    setTonerData(tonerRequest);
  };

  const submit = (data) => {
    let readingsData = {
      yourName: tonerData.yourName,
      emailAddress: tonerData.emailAddress,
      customer: tonerData.companyName,
      ...data,
    };

    submitReadings.mutate(readingsData, {
      onSuccess: () => {
        requestConsumables.mutate(tonerData, {
          onSuccess: () => {
            addNotification({
              variant: "success",
              primaryText: "Toner Request Submitted",
              secondaryText:
                "Your toner request has been submitted to the team for processing",
            });
            setStep(0);
            selectedTonerDispatch({ type: "RESET" });
            reset();
          },
        });
      },
    });
  };

  useEffect(() => {
    if (filterSite !== "*" && !allEquipment.isLoading) {
      setEquipment(
        allEquipment.data.equipment.filter(
          (e) => e.customerId === parseInt(filterSite)
        )
      );
    } else if (!allEquipment.isLoading) {
      setEquipment(allEquipment.data.equipment);
    }

    console.log('EQUIPMENT: ', allEquipment.data);

  }, [filterSite, allEquipment.data, allEquipment.isLoading]);

  //Reset site filter if customer changes
  useEffect(() => {
    setFilterSite("*");
  }, [customer.id]);

  return step === 0 ? (
    <div>
      <form onSubmit={handleSubmit(nextStep)} className="text-gray-600">
        <YourContactDetails register={register} reset={reset} errors={errors} />

        <div className="mt-8 flex justify-between items-start">
          <div className="flex flex-col">
            <h1 className="text-xl font-medium text-ag-default">Devices</h1>
          </div>
        </div>

        <div className="my-5">
          {allEquipment.isLoading ? (
            <LoadingWheel />
          ) : (
            <>
              <div className="block overflow-y-hidden overflow-x-auto">
                <EquipmentTable
                  data={equipment.sort((a, b) => {
                    const accountNumberA = a.accountNumber;
                    const accountNumberB = b.accountNumber;

                    if (accountNumberA < accountNumberB) {
                      return -1;
                    }
                    if (accountNumberA > accountNumberB) {
                      return 1;
                    }
                    return 0;
                  }).filter(i => i.description.toLowerCase().indexOf("scanner") === -1 && 
                    ![7, 9, 10, 13, 17].includes(i.categoryId))}
                  selectedToners={selectedToners}
                  selectedTonerDispatch={selectedTonerDispatch}
                  setFilterSite={setFilterSite}
                />
              </div>
              {selectedTonerError && (
                <p role="alert" className="text-red-600">
                  {selectedTonerError}
                </p>
              )}
            </>
          )}
        </div>

        <div className="my-5 grid grid-cols-1 md:grid-cols-3 gap-4">
          <h1 className="md:col-span-3 text-xl font-medium text-ag-default">
            Extra Information
          </h1>
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-ag-default">
              Your PO Number
            </label>
            <input
              type="text"
              {...register("poNumber")}
              className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
            />
          </div>

          <div className="md:col-span-3 w-full">
            <input
              type="checkbox"
              {...register("isUrgent")}
              className="w-4 h-4 rounded text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
            />
            <span className="ml-2 text-sm">Next Day</span>
            <div className="mt-2 text-sm">
              By ticking this box you agree to an additional Next Day delivery
              charge for this order. Our standard delivery service takes 1-2
              working days and postage is free for contracted customers. If you
              have any queries please contact our Toner Team on 0208 688 4243
              (opt 2) or{" "}
              <a className="text-ag-default" href="mailto: toner@agilico.co.uk">
                toner@agilico.co.uk
              </a>
            </div>
            {watch("isUrgent") && (
              <div className="mt-4 rounded-md p-4 bg-red-50 text-red-700 text-sm">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-full">
                    <h2 className="text-lg leading-6 mb-2">
                      Urgent Order Form
                    </h2>
                    <div className="text-sm mb-4">
                      Please fill in the below information in order to confirm
                      that you agree to be charged for the quoted price for your
                      order.
                    </div>
                    <div className="grid grid-cols-1 md:flex md:flex-col md:w-1/2 mb-4">
                      <label className="mb-1 font-medium">Delivery Type</label>
                      <select
                        {...register("urgentDeliveryType")}
                        className="border p-2 bg-red-50 border-red-700"
                      >
                        <option value="Next Day">Next Day - (£8.95 + vat)</option>
                        <option value="Next Day before 12.00 midday">
                          Next Day Before 12:00 midday - (£20.00 + vat)
                        </option>
                        <option value="Next Day before 10.30am">
                          Next Day Before 10:30am - (£30.00 + vat)
                        </option>
                      </select>
                    </div>
                    <div className="grid grid-cols-1 md:flex md:flex-col md:w-1/2 mb-4">
                      <label className="mb-1 font-medium">I Agree</label>
                      <input
                        {...register("urgentDeliverySignature", {
                          required:
                            "Please enter your name to confirm delivery",
                          minLength: {
                            value: 3,
                            message: "Please enter a valid name with 3 or more characters",
                          },
                        })}
                        type="text"
                        placeholder="Type your Name to confirm"
                        className="border p-2 bg-red-50 border-red-700"
                        aria-invalid={
                          errors.urgentDeliverySignature ? "true" : "false"
                        }
                      />
                      {errors.urgentDeliverySignature && (
                        <p role="alert" className="text-red-600">
                          {errors.urgentDeliverySignature?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mt-8">
          <button
            type="submit"
            className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
          >
            Next step
          </button>
        </div>
      </form>
    </div>
  ) : (
    <div className="min-h-[55vh]">
      <h1 className="mb-4 text-xl font-medium text-ag-default">
        Update Meter Readings
      </h1>
      <div className="rounded-md bg-red-50 my-4 p-4 w-full">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-red-700">
              Where a reading ends with an E e.g. 12345 E this indicates the reading is an estimate, if your actual reading is significantly lower than the estimate please contact{"  "}
              <a className="text-red-900" href="mailto: meterreadings@agilico.co.uk">
                meterreadings@agilico.co.uk
              </a>
            </p>
          </div>
        </div>
      </div>
      <MeterTable
        customer={customer}
        data={selectedToners}
        onSubmit={submit}
        setStep={setStep}
      />
    </div>
  );
}
