import { useEffect, useState } from "react";
import date from "date-and-time";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import { withCustomer } from "../../data/withCustomer";
import FullscreenLoading from "../../components/shared/FullscreenLoading";
import SectionTitle from "../../components/shared/SectionTitle";
import InvoiceCard from "../../components/Invoices/InvoiceCard";
import LoadingWheel from "../../components/shared/LoadingWheel";
import TablePaginationControls from "../../components/shared/TablePaginationControls";

import Breadcrumbs from "../../components/shared/Breadcrumbs";
import useApiHelper from "../../hooks/useApiHelper";
import { useInvoices } from "../../data/useInvoices";
import usePageTitleContext from "../../contexts/PageTitleContext";
import ViewSelect from "../../components/shared/ViewSelect";
import { permissionsLookup, useRoleCheck } from "../../hooks/useRoleCheck";
import { NoPermission } from "../../components/shared/NoPermission";

function InoviceTable({ data, cardView, setCardView, onDownloadClick }) {
  const [searchTerm, setSearchTerm] = useState("");

  const cols = [
    {
      accessorKey: "invoiceDate",
      header: "Invoice Date",
      cell: (info) => (
        <span>{date.format(new Date(info.getValue()), "DD/MM/YY")}</span>
      ),
    },
    {
      accessorKey: "reference",
      header: "Invoice No.",
    },
    {
      accessorKey: "name",
      header: "Company Name",
    },
    {
      accessorKey: "baseTotalValue",
      header: "Value",
      cell: (info) => <span>£{info.getValue()}</span>,
    },
    {
      id: "actions",
      cell: (info) => (
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => onDownloadClick(info.row.original)}
            className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
          >
            Download
          </button>
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data: data,
    columns: cols,
    filterFns: {
      fuzzy: basicIncludesFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: basicIncludesFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="relative mb-4">
        <div className="w-full grid grid-cols-1 md:px-3 md:pb-4 md:flex md:items-center md:justify-between">
          <div className="w-full -mt-5 mb-4 md:mb-0 md:w-96">
            <div className="pointer-events-none relative inset-y-8 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              placeholder="Search"
            />
          </div>
          <ViewSelect value={cardView} onChange={setCardView} />
        </div>
        <div className="block overflow-y-hidden overflow-x-auto">
          <table className="text-black min-w-full divide-y border divide-gray-300">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, ri) => {
                let rowCells = row.getVisibleCells();
                return (
                  <tr
                    key={row.id}
                    className={`${ri % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                  >
                    {rowCells.map((cell, ci) => (
                      <td
                        key={cell.id}
                        width={ci > 3 ? "11%" : "auto"}
                        className={`px-3 py-4 text-sm ${
                          ci === 0 ? "pl-5 font-bold" : "font-medium"
                        }`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <TablePaginationControls table={table} />
    </>
  );
}

function _Invoices({ customerLoading, customer }) {
  const { checkAccess } = useRoleCheck();
  const { setTitle } = usePageTitleContext();
  const { invoices: allInvoices } = useInvoices(customer.id);
  const { get } = useApiHelper();
  const [cardView, setCardView] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [invoices, setInvoices] = useState(undefined);

  const pages = [
    { to: "../..", label: "Support", current: false },
    { to: "", label: "Invoices", current: true },
  ];

  const handleDownload = (invoice) => {
    get(`/invoice/download/${invoice.id}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice-" + invoice.reference + ".pdf");
        document.body.appendChild(link);
        link.click();
      });
  };

  useEffect(() => {
    if (!allInvoices.isLoading) {
      if (searchTerm !== "") {
        let term = searchTerm.toLowerCase();
        setInvoices(
          allInvoices.data.invoices.filter(
            (invoice) =>
              invoice.reference.toLowerCase().includes(term) ||
              invoice.name.toLowerCase().includes(term)
          )
        );
      } else {
        setInvoices(allInvoices.data.invoices);
      }
    }
  }, [searchTerm, allInvoices.isLoading, allInvoices.data]);

  //Set page title
  useEffect(() => {
    setTitle("Invoices");
  }, [setTitle]);
  
  if(!checkAccess(permissionsLookup.viewInvoice.realm)) {
    return <NoPermission />
  } else if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>

        {allInvoices.isLoading || !invoices ? (
          <LoadingWheel />
        ) : cardView ? (
          <ul className="grid grid-cols-1 gap-6 md:grid-cols-3">
            <li className="md:col-span-3 py-4 px-6 rounded-lg bg-white shadow grid grid-cols-1 md:flex md:justify-between">
              <div className="relative">
                <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
                  <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
                    <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="text"
                      id="search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="block w-full text-black bg-gray-50 pl-10 border border-gray-400 p-2 focus:border-ag-darkBlue"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
              <ViewSelect value={cardView} onChange={setCardView} />
            </li>
            {invoices
              .sort((a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate))
              .map((invoice) => (
                <InvoiceCard
                  key={invoice.id}
                  invoice={invoice}
                  onDownloadClick={handleDownload}
                />
              ))}
          </ul>
        ) : (
          <div className="p-4 bg-white shadow rounded-md">
            <InoviceTable
              cardView={cardView}
              setCardView={setCardView}
              data={allInvoices.data.invoices}
              onDownloadClick={handleDownload}
            />
          </div>
        )}
      </div>
    );
  }
}

const Invoices = withCustomer(_Invoices);

export default Invoices;
