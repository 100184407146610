import { useMutation } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useCurrentUserContext from "../contexts/UserContext";

export function usePermissions(customerId, queryParams = {}) {
    const {
        currentUser: { actAs },
    } = useCurrentUserContext();
    const { post } = useApiHelper({
        headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
    });

    const createPermissions = useMutation(
        (permissionsReq) => post("/permission/permissions", JSON.stringify(permissionsReq)),
    )
    
    // const deletePermissions = useMutation(
    //     (permissionsReq) => post("/admin/deletepermissions", JSON.stringify(permissionsReq)),
    // )

    return {
        createPermissions
        //deletePermissions
    };
}