import { useEffect } from "react";
import { withCustomer } from "../../data/withCustomer";

import Breadcrumbs from "../../components/shared/Breadcrumbs";
import FullscreenLoading from "../../components/shared/FullscreenLoading";
import usePageTitleContext from "../../contexts/PageTitleContext";

import ManagedITImg from "../../assets/ManagedITServices.jpg";

function _ManagedIT({ companyLoading }) {
  const { setTitle } = usePageTitleContext();

  const pages = [{ to: "", label: "Managed IT Services", current: true }];

  useEffect(() => {
    setTitle("Managed IT Services");
  }, [setTitle]);

  if (companyLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="p-6 bg-white rounded-xl grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="grid grid-cols-1 gap-6">
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                Get continuous IT support with Managed IT Services
              </h1>
              <p className="text-gray-500">
                Managed IT Services are being utilised by an increasing number
                of businesses, who recognise that this key part of their
                operations needs to be properly controlled.
                <br />
                <br />
                When technology works, it's great. But when it doesn't, it can
                quickly have a detrimental effect on your workforce and
                customers.
                <br />
                <br />
                Many organisations lack the skills and resources to keep their
                IT effective and aligned to the business.
                <br />
                <br />
                <span className="font-semibold">
                  We'll design and deliver a Managed IT service that gives your
                  people the applications, systems and support they need to
                  fully effective
                </span>
              </p>
            </div>
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                What Are Managed IT Services?
              </h1>
              <p className="text-gray-500">
                As your out-sourced IT department, a Managed IT Service takes
                away the headache, stress and frustration of managing IT,
                leaving you to get on with day-to-day operations.
                <br />
                <br />
                Managed IT services can include a broad span of solutions, like:
                IT helpdesk, security, infrastructure management, application
                support and more.
                <br />
                <br />
                You'll have access to the expertise of a specialist IT team,
                able to design, implement and support a complete technology
                solution in the most cost-effective way, as well as helping
                ensure your systems are as secure as possible.
                <br />
                <br />
                <span className="font-semibold">
                  We'll help you build a better technology infrastructure and
                  reduce your operational costs.
                </span>
                <br />
                <br />
                <a
                  className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
                  href="https://www.agilico.co.uk/managed-it-services/"
                >
                  Discover More
                </a>
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6">
            <div>
            <img src={ManagedITImg} alt="IT Managed Serviced" className="w-full" />
            </div>
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
              Key Managed IT Benefits
              </h1>
              <ol className="font-medium list-disc ml-4 space-y-0.5 text-gray-500">
                <li>Customise your own IT service</li>
                <li>Provide scale and flexibility to your business</li>
                <li>Expertise, resource and technology without the investment</li>
                <li>Replace variable costs with a predictable monthly fee</li>
                <li>24/7/365 UK-based support</li>
                <li>Portal to enable users to log tickets and service requests</li>
                <li>Access to professional resources and skills to add value</li>
                <li>Tailored monthly service reporting and contracted SLAs</li>
                <li>Expert IT Helpdesk</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ManagedIT = withCustomer(_ManagedIT);

export default ManagedIT;
