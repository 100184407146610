import { useEffect } from "react";

import { useLocationDashboard } from "../../data/useLocationDashboard";
import useCurrentUserContext from "../../contexts/UserContext";
import LoadingWheel from "../shared/LoadingWheel";
import { buildAddressString } from "../../utils/buildAddressString";

export default function LocationTab({ customer, dateFilters }) {
  const { locationsDevices } = useLocationDashboard(customer.id, dateFilters);
  const {
    currentUser: {
      loading,
      user: { sites },
    },
  } = useCurrentUserContext();

  useEffect(() => {}, [dateFilters]);

  return (
    <>
      {loading ||
      locationsDevices.isLoading ||
      !locationsDevices.data?.results ? (
        <div className="col-span-4 md:col-span-4 bg-white shadow rounded-md p-5">
          <LoadingWheel />
        </div>
      ) : (
        sites
          .sort((a, b) => {
            var nameA = a.name + " " + a.town + " " + a.postcode;
            var nameB = b.name + " " + b.town + " " + b.postcode;

            if (nameA < nameB) {
              return -1;
            }

            if (nameA > nameB) {
              return 1;
            }

            return 0;
          })
          .map((site, i) => {
            let siteDevices = locationsDevices.data.results.filter(
              (dev) => dev.accountNo === site.reference
            );

            return (
              <div
                key={`site-${i}`}
                className="col-span-4 md:col-span-4 bg-white shadow rounded-md p-5"
              >
                <h1 className="mb-2 text-xl font-medium text-ag-default">
                  {site.name} -{" "}
                  {buildAddressString(
                    site.address1,
                    site.address2,
                    site.address3,
                    site.town,
                    site.postCode
                  )}
                </h1>
                <div>
                  <table className="text-black min-w-full divide-y border divide-gray-300">
                    <thead>
                      <tr className="border-b-2 border-gray-300">
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Device
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Serial Number
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Asset Number
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Onsite Location
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Total Mono
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Total Colour
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Total Pages
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Avg Monthly Volume
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Reccomended Monthly Pages
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Colour %
                        </th>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Breakdown Calls
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {siteDevices.length > 0 ? (
                        siteDevices.map((sd, i) => (
                          <tr
                            key={`device-${i}`}
                            className={i % 2 === 0 ? "bg-gray-100" : "bg-white"}
                          >
                            <td className="px-3 py-4 border text-sm">
                              {sd.deviceName}
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.serialNo}
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.refNo}
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.deviceLocation}
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.monoTotal}
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.colourTotal}
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.volumesTotal}
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.averageMonthlyVolume}
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.deviceMaxMonthly}
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.colourPercent.toFixed(2)}%
                            </td>
                            <td className="px-3 py-4 border text-sm">
                              {sd.breakdownCalls}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={11}
                            className="px-3 py-4 border text-sm text-center"
                          >
                            No Devices for this site
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })
      )}
    </>
  );
}
