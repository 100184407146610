import { Fragment, useState, useEffect } from "react";
import date from "date-and-time";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import LoadingWheel from "../shared/LoadingWheel";
import TablePaginationControls from "../shared/TablePaginationControls";

import { useConsumables } from "../../data/useConsumables";
import useCurrentUserContext from "../../contexts/UserContext";
import SiteDropdown from "../shared/SiteDropdown";

import { buildAddressString } from "../../utils/buildAddressString";

const AddressCell = (info) => {
  const { currentUser } = useCurrentUserContext();
  let site = currentUser.user.sites.find(
    (s) => s.id === info.row.original.customerId
  );
  if (site) {
    return buildAddressString(
      site.address1,
      site.address2,
      site.address3,
      site.town,
      site.postcode
    );
  } else {
    return buildAddressString(
      info.row.original.address1,
      info.row.original.address2
    );
  }
};

function OrderHistoryTable({
  data,
  setFilterSite,
  daysSinceToday,
  setDaysSinceToday,
  daysSinceOptions,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const cols = [
    {
      accessorKey: "serialNumber",
      header: "Serial",
    },
    {
      accessorKey: "description",
      header: "Device Name",
    },
    {
      accessorKey: "name",
      header: "Company Name",
    },
    {
      id: "address",
      header: "Address",
      cell: (info) => AddressCell(info),
    },
    {
      accessorKey: "location",
      header: "Onsite Location",
    },
    {
      accessorKey: "orderDate",
      header: "Date Ordered",
      cell: (info) => (
        <>{date.format(new Date(info.getValue()), "DD/MM/YYYY")}</>
      ),
    },
    {
      accessorKey: "reference",
      header: "Order Number",
    },
    {
      id: "orderNotes",
      header: "Description",
      cell: (info) => (
        <>
          {info.row.original.orderItems.map((item, i) => (
            <Fragment key={`${i}`}>
              {item} <br />
            </Fragment>
          ))}
        </>
      ),
    },
  ];

  const table = useReactTable({
    data: data,
    columns: cols,
    filterFns: {
      fuzzy: basicIncludesFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: basicIncludesFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="relative my-4">
        <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
          <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
            <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              placeholder="Search"
            />
          </div>
          <div className="md:space-x-2 md:flex md:items-center w-7/8 justify-end">
            <SiteDropdown
              onChange={(e) => setFilterSite(e.target.value)}
              className="w-full md:w-1/2 mb-4 md:mb-0"
            />
            <select
              className="border text-ag-default bg-gray-50 border-gray-400 w-full md:w-1/2 p-2"
              onChange={(e) => setDaysSinceToday(e.target.value)}
              value={daysSinceToday}
            >
              {daysSinceOptions.map((opt) => (
                <option key={opt} value={opt}>{`${opt} Days`}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="block overflow-y-hidden overflow-x-auto">
        <table className="text-black min-w-full divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, ri) => {
              let rowCells = row.getVisibleCells();
              return (
                <tr
                  key={row.id}
                  className={`${ri % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                >
                  {rowCells.map((cell, ci) => (
                    <td
                      key={cell.id}
                      className={`px-3 py-4 text-sm ${
                        ci === 0 ? "pl-5 font-bold" : "font-medium"
                      } ${
                        ci > 0 && ci < 5 ? "align-text-top" : ""
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePaginationControls table={table} />
    </>
  );
}

export default function OrderHistoryPartial({ customer }) {
  const daysSinceOptions = [30, 60, 90, 120, 180];
  const [daysSinceToday, setDaysSinceToday] = useState(90);
  const { consumables: allConsumables } = useConsumables(customer.id, {
    getOrderDetails: true,
    daysSinceToday: daysSinceToday,
  });
  const [filterSite, setFilterSite] = useState("*");
  const [consumables, setConsumables] = useState([]);

  useEffect(() => {
    if (filterSite !== "*" && !allConsumables.isLoading) {
      setConsumables(
        allConsumables.data.consumables.filter(
          (e) => e.customerId === parseInt(filterSite)
        )
      );
    } else if (!allConsumables.isLoading) {
      setConsumables(allConsumables.data.consumables);
    }
  }, [filterSite, allConsumables.data, allConsumables.isLoading]);

  return (
    <div className="min-h-[55vh]">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-start">
          <h1 className="text-xl font-medium text-ag-default">Order History</h1>
        </div>
        {allConsumables.isLoading ? (
          <LoadingWheel />
        ) : (
          <>
            <OrderHistoryTable
              data={consumables}
              setFilterSite={setFilterSite}
              daysSinceToday={daysSinceToday}
              setDaysSinceToday={setDaysSinceToday}
              daysSinceOptions={daysSinceOptions}
            />
          </>
        )}
      </div>
    </div>
  );
}
