import { useEffect } from "react";
import { withCustomer } from "../../data/withCustomer";

import Breadcrumbs from "../../components/shared/Breadcrumbs";
import FullscreenLoading from "../../components/shared/FullscreenLoading";
import usePageTitleContext from "../../contexts/PageTitleContext";

import VerifyPriceComparison from "../../assets/VerifyPriceComparison.png";
import VerifyProcess from "../../assets/VerifyProcess.png";
import VerifyVideo from "../../components/Verify/VerifyVideo";

function _Verify({ companyLoading, company }) {
  const { setTitle } = usePageTitleContext();

  const pages = [{ to: "", label: "Verify", current: true }];

  useEffect(() => {
    setTitle("Verify");
  }, [setTitle]);

  if (companyLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="p-6 bg-white rounded-xl grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="grid grid-cols-1 gap-6">
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                Discover Verify Automated Invoice Processing
              </h1>
              <p className="text-gray-500">
                Keeping on top of invoice processing is a demanding task for
                most finance teams and business owners. Without a clearly
                defined, efficient process for managing supplier payments and
                outgoings, it’s easy for accounts payable departments to become
                overloaded and payments to be missed. Manual activities cannot
                be the answer to timely and cost effective invoice processing.
                In today’s world of digital, automated workplace systems,
                innovative technologies provide solutions for a multitude of
                organisational headaches. What if technology could scan,
                categorise and file invoices automatically and make them
                accessible to your approvers anytime, anywhere?
                <br />
                <br />
                Introducing Verify, automated invoice processing…
                <br />
                <br />
                <a
                  className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
                  href="https://www.agilico.co.uk/automated-invoice-processing/"
                >
                  Discover More
                </a>
              </p>
            </div>
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                What is Automated Invoice Processing?
              </h1>
              <p className="text-gray-500">
                Verify, our automated invoice processing solution involves the
                use of intelligent software to automatically capture, extract,
                route and post invoices into a finance system. It helps
                organisations set up approval chains and shift from data entry
                tasks to automated data capture and posting processes,
                delivering visibility and control over the entirety of an
                organisation's invoice processing activities.
              </p>
            </div>
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                Typical Costs Involved
              </h1>
              <p className="text-gray-500">
                Market research suggests that, on average, it costs £4-25 to
                process an invoice. With invoice automation solutions this can
                be hugely reduced, by minimising the time your accounts team are
                spending on data entry.
              </p>
              <div className="mt-4 flex justify-center">
                <img
                  src={VerifyPriceComparison}
                  className="w-3/4"
                  alt="Price Comaprison"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6">
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                Watch the online Verify demo
              </h1>
              <VerifyVideo />
            </div>
            <div>
              <h1 className="mb-2 text-ag-default font-bold text-xl">
                How Does The Process Work?
              </h1>
              <div className="">
                <img src={VerifyProcess} alt="Verify Process" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Verify = withCustomer(_Verify);

export default Verify;
