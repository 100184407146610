export default function SectionTitle({ title }) {
  return (
    <>
      <div className="text-ag-default border-b-2 mb-6">
        <h1 className="font-bold text-2xl py-2 mb-0">{title}</h1>
        <div className="bg-ag-default rounded-lg w-20 h-1 ml-3"></div>
      </div>
    </>
  );
}
