import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { ExclamationIcon, MessageIcon, InvoiceIcon } from '../../../assets/CustomIcons'
import classNames from '../../../utils/classNames'

const timeline = [
  {
    id: 1,
    title: "Open Support Ticket",
    content: 'There is a ticket requiring your attention!',
    icon: <ExclamationIcon className="h-7 w-7" />,
    iconBackground: "bg-red-200"
  },
  {
    id: 2,
    title: "New Message",
    content: 'Make sure to respond!',
    icon: <MessageIcon className="h-7 w-7 p-0.5 text-[#f8a611] stroke-[#f8a611]" />,
    iconBackground: 'bg-[#fce5b3]',
  },
  {
    id: 3,
    title: "New Invoice Available",
    content: 'You should check this out!',
    icon: <InvoiceIcon className="h-7 w-7 text-gray-500" />,
    iconBackground: 'bg-gray-300',
  },
  {
    id: 4,
    title: "Toner Replenishment Shipped",
    content: "A recharge on the way!",
    icon: <ArrowPathIcon strokeWidth={2.5} className='h-7 w-7 text-ag-lightGreen' />,
    iconBackground: 'bg-[#b6f5cb]',
  },
]

export default function Feed() {
  return (
    <div className="flow-root">
      <div className="grid grid-cols-5 py-4 gap-6">
        <div className="col-span-3 text-left text-xl font-bold">
          Reminders
        </div>
        <div className="col-span-2 text-right text-sm font-bold text-ag-lightGreen">
          {"View All >"}
        </div>
      </div>
      <ul role="list" className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
              <div className="relative flex items-center pb-5 space-x-3">
                <div className="mr-4">
                  <span
                    className={classNames(
                      event.iconBackground,
                      'h-12 w-12 rounded-xl p-1 flex items-center justify-center ring-8 ring-white'
                    )}
                  >
                    {event.icon}
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4">
                  <div>
                    <h2 className="font-semibold">{event.title}</h2>
                    <p className="text-sm text-gray-500">
                      {event.content}
                    </p>
                  </div>
                </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
