export function HomeIcon({ className }) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path
        fill="currentColor"
        d="m34.86,42h-6c-.62,0-1.13-.51-1.13-1.13v-5.62h2.24v4.5h4.87c.2,0,.38-.17.38-.38v-14.99c0-.62.51-1.13,1.13-1.13h2.82l-15.18-14.55-15.18,14.55h2.82c.62,0,1.13.51,1.13,1.13v14.98c0,.2.17.38.38.38h4.87v-8.62c0-1.44,1.18-2.62,2.62-2.62h6.74c1.44,0,2.62,1.18,2.62,2.62v1.88h-2.24v-1.88c0-.21-.17-.38-.38-.38h-6.74c-.21,0-.38.17-.38.38v9.74c0,.62-.51,1.13-1.13,1.13h-6c-1.44,0-2.62-1.18-2.62-2.62v-13.87h-2.62c-1.03,0-1.88-.84-1.88-1.88,0-.5.2-.98.56-1.34L23.22,6.32c.43-.42,1.12-.42,1.56,0l16.64,15.94c.37.37.57.85.57,1.35,0,1.03-.84,1.88-1.88,1.88h-2.62v13.87c0,1.45-1.18,2.64-2.62,2.64Z"
      />
      <path fill="currentColor" d="m19.13,39.73h9.74v2.24h-9.74v-2.24h0Z" />
    </svg>
  );
}

export function SupportIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path
        fill="currentColor"
        d="m41.17,21.53c-.23,0-.44-.06-.63-.19l-3.81-2.54c-.31-.21-.68-.32-1.06-.32h-8.99c-1.47,0-2.67-1.2-2.67-2.67v-6.87c0-1.47,1.2-2.67,2.67-2.67h12.97c1.47,0,2.67,1.2,2.67,2.67v11.45c0,.42-.24.8-.6,1.01-.17.09-.36.14-.55.14h0Zm-14.49-12.97c-.21,0-.39.17-.39.39v6.87c0,.21.17.39.39.39h8.99c.84,0,1.63.25,2.33.71l2.03,1.35v-9.3c0-.21-.17-.39-.39-.39h-12.96Z"
      />
      <path
        fill="currentColor"
        d="m6.83,24.57c-.18,0-.38-.04-.54-.14-.38-.2-.6-.59-.6-1.01v-11.44c0-1.47,1.2-2.67,2.67-2.67h11.45v2.29h-11.46c-.2,0-.39.17-.39.39v9.31l2.04-1.35c.68-.46,1.48-.71,2.33-.71h12.04v2.29h-12.04c-.38,0-.75.12-1.05.32l-3.83,2.54c-.18.12-.4.18-.62.18Z"
      />
      <path
        fill="currentColor"
        d="m14.09,33.73c-2.52,0-4.58-2.05-4.58-4.58s2.05-4.58,4.58-4.58,4.58,2.05,4.58,4.58c-.01,2.53-2.06,4.58-4.58,4.58Zm0-6.87c-1.27,0-2.29,1.03-2.29,2.29s1.03,2.29,2.29,2.29,2.29-1.03,2.29-2.29-1.03-2.29-2.29-2.29Z"
      />
      <path
        fill="currentColor"
        d="m22.48,41.74h-2.29v-2.29c0-1.05-.86-1.91-1.91-1.91h-8.39c-1.05,0-1.91.86-1.91,1.91v2.29h-2.3v-2.29c0-2.32,1.88-4.19,4.19-4.19h8.39c2.32,0,4.19,1.88,4.19,4.19v2.29h.01Z"
      />
      <path
        fill="currentColor"
        d="m33.91,33.73c-2.52,0-4.58-2.05-4.58-4.58s2.05-4.58,4.58-4.58,4.58,2.05,4.58,4.58-2.05,4.58-4.58,4.58Zm0-6.87c-1.27,0-2.29,1.03-2.29,2.29s1.03,2.29,2.29,2.29,2.29-1.03,2.29-2.29-1.02-2.29-2.29-2.29Z"
      />
      <path
        fill="currentColor"
        d="m42.31,41.74h-2.29v-2.29c0-1.05-.86-1.91-1.91-1.91h-8.39c-1.05,0-1.91.86-1.91,1.91v2.29h-2.29v-2.29c0-2.32,1.88-4.19,4.19-4.19h8.39c2.32,0,4.19,1.88,4.19,4.19v2.29h0Z"
      />
    </svg>
  );
}

export function DashbaordIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path
        fill="currentColor"
        d="m41.25,41.83H8.28c-1.48,0-2.69-1.21-2.69-2.69V6.17h2.3v32.98c0,.22.17.39.39.39h32.98v2.3Z"
      />
      <path
        fill="currentColor"
        d="m18.25,34.16h-3.84c-1.48,0-2.69-1.21-2.69-2.69v-8.43c0-1.48,1.21-2.69,2.69-2.69h3.84c1.48,0,2.69,1.21,2.69,2.69v8.43c0,1.48-1.21,2.69-2.69,2.69Zm-3.84-11.51c-.22,0-.39.17-.39.39v8.43c0,.22.17.39.39.39h3.84c.22,0,.39-.17.39-.39v-8.43c0-.22-.17-.39-.39-.39h-3.84Z"
      />
      <path
        fill="currentColor"
        d="m28.98,34.16h-3.84c-1.48,0-2.69-1.21-2.69-2.69v-13.04c0-1.48,1.21-2.69,2.69-2.69h3.84c1.48,0,2.69,1.21,2.69,2.69v13.04c0,1.48-1.21,2.69-2.69,2.69Zm-3.83-16.11c-.22,0-.39.17-.39.39v13.04c0,.22.17.39.39.39h3.84c.22,0,.39-.17.39-.39v-13.04c0-.22-.17-.39-.39-.39h-3.84Z"
      />
      <path
        fill="currentColor"
        d="m39.72,34.16h-3.84c-1.48,0-2.69-1.21-2.69-2.69V12.31c0-1.48,1.21-2.69,2.69-2.69h3.84c1.48,0,2.69,1.21,2.69,2.69v19.17c0,1.48-1.21,2.69-2.69,2.69Zm-3.84-22.25c-.22,0-.39.17-.39.39v19.17c0,.22.17.39.39.39h3.84c.22,0,.39-.17.39-.39V12.31c0-.22-.17-.39-.39-.39h-3.84Z"
      />
    </svg>
  );
}

export function InvoiceIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 16"
      className={className}
    >
      <path
        id="Path_102"
        data-name="Path 102"
        d="M14,0H2A.945.945,0,0,0,1,1V16l3-2,2,2,2-2,2,2,2-2,3,2V1A.945.945,0,0,0,14,0ZM12,10H4V8h8Zm0-4H4V4h8Z"
        transform="translate(-1)"
        fill="currentColor"
      />
    </svg>
  );
}

export function FleetManagementIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 48 48"
    >
      <path fill="currentColor" d="m17.7,30.68h12.62v2.22h-12.62v-2.22Z" />
      <path fill="currentColor" d="m17.7,35.14h12.62v2.22h-12.62v-2.22Z" />
      <path
        fill="currentColor"
        d="m37.74,32.9h-2.96v-2.22h2.96c1.02,0,1.86-.84,1.86-1.86v-9.65c0-1.02-.84-1.86-1.86-1.86H10.27c-1.02,0-1.86.84-1.86,1.86v9.65c0,1.02.84,1.86,1.86,1.86h2.96v2.22h-2.96c-2.25,0-4.08-1.83-4.08-4.08v-9.65c0-2.25,1.83-4.08,4.08-4.08h27.46c2.25,0,4.08,1.83,4.08,4.08v9.65c.01,2.25-1.83,4.08-4.07,4.08Z"
      />
      <path
        fill="currentColor"
        d="m31.8,41.82h-15.6c-2.25,0-4.08-1.83-4.08-4.08v-11.14c0-.62.5-1.12,1.12-1.12h21.52c.62,0,1.12.5,1.12,1.12v11.14c0,2.24-1.84,4.08-4.08,4.08Zm-17.44-14.1v10.02c0,1.02.84,1.86,1.86,1.86h15.59c1.02,0,1.86-.84,1.86-1.86v-10.02H14.36Z"
      />
      <path fill="currentColor" d="m17.7,30.68h12.62v2.22h-12.62v-2.22Z" />
      <path fill="currentColor" d="m17.7,35.14h12.62v2.22h-12.62v-2.22Z" />
      <path
        fill="currentColor"
        d="m35.88,16.2h-2.22v-5.94c0-1.02-.84-1.86-1.86-1.86h-15.6c-1.02,0-1.86.84-1.86,1.86v5.94h-2.22v-5.94c0-2.25,1.83-4.08,4.08-4.08h15.59c2.25,0,4.08,1.83,4.08,4.08v5.94h.01Z"
      />
    </svg>
  );
}

export function ItManagedServicesIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        d="m40.8,11.46h-6.28c.35.76.52,1.62.5,2.47h5.78s.01,0,.02,0c.22,0,.4.19.4.41v22.13s0,.01,0,.02c0,.22-.19.4-.41.4H7.2s-.01,0-.02,0c-.22,0-.4-.19-.4-.41V14.34s0-.01,0-.02c0-.22.19-.4.41-.4h5.22c-.04-.84.1-1.7.43-2.47h-5.65c-1.58,0-2.87,1.29-2.87,2.87v22.13c0,1.58,1.29,2.87,2.87,2.87h33.61c1.58,0,2.87-1.29,2.87-2.87V14.33c0-1.58-1.29-2.87-2.87-2.87Z"
      />
      <path fill="currentColor" d="m5.56,31.13h36.88v2.46H5.56v-2.46Z" />
      <path
        fill="currentColor"
        d="m29.32,44.25h-10.65c-.5,0-.95-.3-1.14-.76-.19-.46-.08-.99.27-1.34,1.08-1.17,1.8-2.63,2.07-4.2l2.43.32c-.2,1.24-.62,2.43-1.22,3.52h5.77c-.6-1.1-1.01-2.28-1.22-3.52l2.43-.35c.27,1.51.93,2.92,1.94,4.08.33.23.53.61.53,1.01.01.66-.51,1.21-1.18,1.22-.01,0-.02,0-.03,0h-.01Z"
      />
      <path
        fill="currentColor"
        d="m27.14,13.18h-6.81c-1.73,0-3.15-1.41-3.15-3.15,0-1.53,1.1-2.8,2.54-3.09.55-1.86,2.28-3.2,4.27-3.2,1.57,0,3.02.83,3.82,2.16,1.71.31,2.99,1.81,2.99,3.6,0,2.03-1.65,3.67-3.67,3.67Zm-3.15-7.86c-1.42,0-2.65,1.06-2.85,2.47-.05.39-.38.68-.78.68-.91,0-1.61.71-1.61,1.57s.71,1.57,1.57,1.57h6.81c1.16,0,2.09-.94,2.09-2.09s-.86-2.03-1.97-2.09c-.29-.01-.55-.19-.67-.45-.45-1.01-1.47-1.66-2.59-1.66Z"
      />
      <path
        fill="currentColor"
        d="m20.59,28.91h-6.81c-1.3,0-2.36-1.06-2.36-2.36v-5.76c0-1.3,1.06-2.36,2.36-2.36h6.81c1.3,0,2.36,1.06,2.36,2.36v5.76c0,1.3-1.06,2.36-2.36,2.36Zm-6.82-8.91c-.43,0-.79.35-.79.79v5.76c0,.43.35.79.79.79h6.81c.43,0,.79-.35.79-.79v-5.76c0-.43-.35-.79-.79-.79h-6.81Z"
      />
      <path fill="currentColor" d="m12.21,21.56h9.96v1.57h-9.96v-1.57Z" />
      <path
        fill="currentColor"
        d="m34.22,28.91h-6.81c-1.3,0-2.36-1.06-2.36-2.36v-5.76c0-1.3,1.06-2.36,2.36-2.36h6.81c1.3,0,2.36,1.06,2.36,2.36v5.76c0,1.3-1.06,2.36-2.36,2.36Zm-6.82-8.91c-.43,0-.79.35-.79.79v5.76c0,.43.35.79.79.79h6.81c.43,0,.79-.35.79-.79v-5.76c0-.43-.35-.79-.79-.79h-6.81Z"
      />
      <path fill="currentColor" d="m25.83,21.56h9.96v1.57h-9.96v-1.57Z" />
      <path
        fill="currentColor"
        d="m31.59,19.21h-1.57v-2.09c0-.15-.12-.27-.27-.27h-11.53c-.15,0-.27.12-.27.27v2.09h-1.57v-2.09c0-1.01.83-1.83,1.83-1.83h11.53c1.01,0,1.83.83,1.83,1.83v2.09h0Z"
      />
      <path fill="currentColor" d="m23.21,12.66h1.57v3.4h-1.57v-3.4Z" />
    </svg>
  );
}

export function TelcoIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        d="m34.22,30.61c.34.62.41,1.34.21,2.02l-1.44,4.86c-.2.7-.7,1.29-1.36,1.61-.68.34-1.47.37-2.18.1-11.43-4.42-17.98-16.46-15.47-28.45.16-.73.61-1.37,1.25-1.75.63-.38,1.39-.48,2.09-.27l4.75,1.37c1.17.34,1.96,1.44,1.91,2.66l-.17,4.23c-.04,1.07-.72,2.01-1.73,2.38l-2.09.77c.77,3.32,2.41,6.37,4.77,8.83l1.85-1.37c.86-.64,2.01-.7,2.93-.15l3.67,2.15c.42.24.76.6,1,1.03h0Zm-15.86-3.85c2.58,4.78,6.79,8.49,11.87,10.44.15.06.32.05.47-.02.12-.06.21-.17.25-.3l1.43-4.86c.06-.23-.03-.46-.24-.58l-3.67-2.16c-.18-.11-.41-.1-.58.03l-2.59,1.92c-.42.31-1,.27-1.37-.09-3.24-3.08-5.42-7.11-6.21-11.51-.09-.51.2-1.01.68-1.19l2.96-1.09c.19-.07.32-.25.33-.45l.17-4.23c0-.24-.15-.45-.37-.51l-4.74-1.37c-.13-.04-.28-.02-.4.05-.13.08-.23.22-.26.37-1.14,5.29-.33,10.81,2.27,15.56h0Z"
      />
    </svg>
  );
}

export function AccountIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        d="m35.93,13.97l1.52-.47c-.35-.51-1-.73-1.59-.54l-.96.3c.36.22.7.46,1.03.72h0Z"
      />
      <path
        fill="currentColor"
        d="m35.93,34.04c-.32.27-.68.48-1.03.72l.96.3c.59.19,1.24-.03,1.6-.54l-1.52-.48Z"
      />
      <path
        fill="currentColor"
        d="m24.4,41.69h-4.79c-.61,0-1.1-.49-1.1-1.1v-4.32l-3.19.86c-.3.1-.61.15-.92.14-1.8,0-3.26-1.47-3.26-3.27v-3.36h-2.59c-1.01,0-1.83-.82-1.83-1.83,0-.37.11-.73.32-1.03l3.3-5.74c.42-.66.66-1.41.69-2.19,0-.05-.01-.1-.01-.16,0-3,1-5.91,2.85-8.27l1.74,1.36c-1.51,1.92-2.35,4.28-2.38,6.72,0,.06.01.12.01.17,0,1.24-.35,2.45-1.01,3.49l-3.02,5.25h3.03c.61,0,1.1.49,1.1,1.1v4.47c0,.58.46,1.06,1.05,1.06.09,0,.17,0,.26-.04l4.67-1.26c.33-.09.69-.02.96.19.27.21.43.53.43.88v4.66h2.58V8.58c-2.11.2-4.12,1-5.79,2.31l-1.36-1.75c2.34-1.84,5.22-2.83,8.2-2.83.62,0,1.13.48,1.16,1.1v33.17c0,.61-.49,1.1-1.1,1.1h0Zm-15.51-12.71h0Z"
      />
      <path
        fill="currentColor"
        d="m26.85,41.69h-2.45v-2.21h2.45c.16,0,.29-.11.32-.26l.75-3.33c.08-.36.34-.66.69-.79,1.2-.45,2.31-1.1,3.3-1.91.29-.24.68-.31,1.03-.2l3.24,1.02c.14.05.3,0,.38-.13l2.46-4.24c.08-.15.05-.34-.08-.45l-2.47-2.27c-.27-.25-.4-.62-.35-.98.09-.64.14-1.28.13-1.93,0-.64-.04-1.29-.13-1.93-.05-.36.08-.73.35-.98l2.49-2.28c.11-.12.14-.29.05-.43l-2.44-4.23c-.07-.14-.24-.21-.39-.16l-3.25,1.02c-.36.11-.74.03-1.03-.2-.99-.81-2.1-1.46-3.3-1.91-.35-.13-.6-.43-.69-.79l-.75-3.33c-.03-.15-.16-.25-.31-.25h-2.45v-2.22h2.45c1.19,0,2.22.82,2.48,1.98l.63,2.77c1.03.44,1.99,1,2.88,1.67l2.68-.84c1.15-.37,2.4.12,2.99,1.17l2.44,4.21c.59,1.03.4,2.32-.45,3.14l-2.1,1.92c.11,1.11.11,2.22,0,3.33l2.07,1.9c.87.82,1.06,2.13.47,3.16l-2.45,4.23c-.59,1.04-1.83,1.52-2.97,1.15l-2.68-.84c-.89.67-1.86,1.23-2.88,1.67l-.63,2.77c-.26,1.16-1.29,1.98-2.48,1.98h0Z"
      />
      <path
        fill="currentColor"
        d="m24.4,32.11v-2.21c3.26,0,5.9-2.64,5.9-5.9s-2.64-5.9-5.9-5.9v-2.21c4.48,0,8.1,3.63,8.1,8.11,0,4.47-3.63,8.1-8.1,8.1Z"
      />
    </svg>
  );
}

export function MessageIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 14"
      className={className}
    >
      <path
        id="Path_358"
        data-name="Path 358"
        d="M15,1H1A1,1,0,0,0,0,2V14a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V2A1,1,0,0,0,15,1ZM14,13H2V6.723L7.5,9.868a1,1,0,0,0,.992,0L14,6.723Zm0-8.58L8,7.849,2,4.42V3H14Z"
        transform="translate(0 -1)"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChatRoomIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        id="Union_11"
        data-name="Union 11"
        d="M11-382H6l1.828-2H14a1,1,0,0,0,1-1v-6h1a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1H14v3Zm-7-4H2a1,1,0,0,1-1-1v-7a1,1,0,0,1,1-1H12a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1H7l-3,3Z"
        transform="translate(-1 395)"
        fill="currentColor"
      />
    </svg>
  );
}

export function VerifyIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path
        fill="currentColor"
        d="m40.8,35.47H7.2c-1.58,0-2.87-1.29-2.87-2.87V10.47c0-1.58,1.29-2.87,2.87-2.87h33.61c1.58,0,2.87,1.29,2.87,2.87v22.13c0,1.58-1.29,2.87-2.87,2.87ZM7.2,10.07c-.22,0-.41.17-.41.4,0,0,0,.01,0,.02v22.13c0,.22.17.41.4.41,0,0,.01,0,.02,0h33.61c.22,0,.41-.17.41-.4,0,0,0-.01,0-.02V10.48c0-.22-.17-.41-.4-.41,0,0-.01,0-.02,0H7.2Z"
      />
      <path fill="currentColor" d="m5.56,27.28h36.88v2.46H5.56v-2.46Z" />
      <path
        fill="currentColor"
        d="m29.32,40.39h-10.65c-.5,0-.95-.3-1.14-.76-.19-.46-.08-.99.27-1.34,1.08-1.17,1.8-2.63,2.07-4.2l2.43.32c-.2,1.24-.62,2.43-1.22,3.52h5.77c-.6-1.1-1.01-2.28-1.22-3.52l2.43-.35c.27,1.51.93,2.92,1.94,4.08.33.23.53.61.53,1.01.01.66-.51,1.21-1.18,1.22-.01,0-.02,0-.03,0h-.01Z"
      />
      <path
        fill="currentColor"
        d="m29.79,12.36h-3.11l-.37.72-.78,1.53-.55,1.09c-.14.24-.27.48-.39.72l-2,3.9-.5-.98c-.26-.52-.53-1.04-.79-1.55h-3.09l3.29,6.44c.32.62,1.08.87,1.7.55.24-.12.43-.31.55-.55l5.92-11.62h0l.12-.25Z"
      />
    </svg>
  );
}

export function SettingsIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      className={className}
    >
      <path
        id="Path_108"
        data-name="Path 108"
        d="M21.612,8.45,23.4,5.037,21.125,2.762,17.713,4.55a5.821,5.821,0,0,0-1.788-.65L14.625,0h-3.25l-1.3,3.737a6.791,6.791,0,0,0-1.625.65L5.037,2.6,2.6,5.037,4.387,8.45a6.791,6.791,0,0,0-.65,1.625L0,11.375v3.25l3.737,1.3c.162.65.487,1.137.65,1.788L2.6,21.125,4.875,23.4l3.413-1.788a5.821,5.821,0,0,0,1.787.65L11.375,26h3.25l1.3-3.738c.65-.163,1.137-.487,1.788-.65L21.125,23.4,23.4,21.125l-1.788-3.413a5.821,5.821,0,0,0,.65-1.788L26,14.625v-3.25l-3.738-1.3A6.791,6.791,0,0,0,21.612,8.45ZM13,17.875A4.788,4.788,0,0,1,8.125,13,4.788,4.788,0,0,1,13,8.125,4.788,4.788,0,0,1,17.875,13,4.788,4.788,0,0,1,13,17.875Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LogoutIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <path fill="currentColor" d="m26.37,20.95h12.86v2.03h-12.86v-2.03Z" />
      <path
        fill="currentColor"
        d="m34.87,27.76l-1.43-1.43,4.36-4.36-4.36-4.36,1.44-1.44,5.08,5.08c.4.4.4,1.04,0,1.44l-5.08,5.08Z"
      />
      <path
        fill="currentColor"
        d="m18.59,40.24c-.29,0-.57-.04-.84-.13l-8.14-2.71c-1.11-.39-1.84-1.42-1.84-2.57V10.46c0-1.49,1.21-2.71,2.71-2.71.29,0,.57.04.84.13l8.14,2.71c1.11.39,1.84,1.42,1.84,2.57v24.36c0,1.49-1.21,2.71-2.71,2.71ZM10.46,9.79c-.37,0-.68.3-.68.68v24.36c0,.29.19.56.47.65l8.11,2.7c.06.02.13.03.22.03.37,0,.68-.3.68-.68V13.17c0-.29-.19-.56-.47-.65l-8.11-2.7c-.06-.02-.13-.03-.22-.03Z"
      />
      <path
        fill="currentColor"
        d="m29.41,17.57h-2.03v-6.09c0-.93-.76-1.69-1.69-1.69h-15.23v-2.03h15.23c2.05,0,3.72,1.67,3.72,3.72v6.09Z"
      />
      <path
        fill="currentColor"
        d="m25.69,36.18h-5.41v-2.03h5.41c.93,0,1.69-.76,1.69-1.69v-6.09h2.03v6.09c0,2.05-1.67,3.72-3.72,3.72Z"
      />
    </svg>
  );
}

export function IncreaseIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 10"
      className={className}
    >
      <path
        id="increase-2"
        data-name="increase"
        d="M81.007,298.673h-3a1,1,0,0,0,0,2h.453l-4.522,4.2-3.332-2.5a1,1,0,0,0-1.269.057l-5,4.5a1,1,0,0,0,1.338,1.486l4.389-3.95,3.343,2.506a1,1,0,0,0,1.28-.067l5.319-4.939v.706a1,1,0,0,0,2,0v-3A1,1,0,0,0,81.007,298.673Z"
        transform="translate(-64.007 -298.673)"
        fill="currentColor"
      />
    </svg>
  );
}

export function DecreaseIcon({ className }) {
  return (
    <svg
      id="decrease"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 10"
      className={className}
    >
      <path
        id="decrease-2"
        data-name="decrease"
        d="M81.007,308.673h-3a1,1,0,1,1,0-2h.453l-4.522-4.2-3.332,2.5a1,1,0,0,1-1.269-.057l-5-4.5a1,1,0,0,1,1.338-1.486l4.389,3.95,3.343-2.506a1,1,0,0,1,1.28.067l5.319,4.939v-.706a1,1,0,0,1,2,0v3A1,1,0,0,1,81.007,308.673Z"
        transform="translate(-64.007 -298.673)"
        fill="currentColor"
      />
    </svg>
  );
}

export function SearchIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      className={className}
    >
      <path
        id="Search"
        d="M587.672,2033.672a1.125,1.125,0,0,1-1.589,0l-3.536-3.538a7.861,7.861,0,1,1,1.588-1.588l3.538,3.538a1.124,1.124,0,0,1,0,1.588m-5.84-13.781a5.615,5.615,0,1,0,0,7.941,5.614,5.614,0,0,0,0-7.941"
        transform="translate(-570 -2016)"
        fill="currentColor"
      />
    </svg>
  );
}

export function NotificationIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.444 18.5"
      className={className}
    >
      <path
        id="Notifications"
        d="M19.952,11.888,19.58,8.677A6.329,6.329,0,0,0,15.2,2.724v-.8a1.923,1.923,0,0,0-3.845,0v.8A6.33,6.33,0,0,0,6.981,8.677l-.372,3.21a2.089,2.089,0,0,0-.8,1.653v.126c0,1.569,1.535,1.569,2.112,1.569h2.121a3.133,3.133,0,0,0,6.223,0h2.374c.578,0,2.113,0,2.113-1.569V13.54A2.087,2.087,0,0,0,19.952,11.888Zm-6.8,4.534a1.571,1.571,0,0,1-1.4-.933h2.806A1.57,1.57,0,0,1,13.154,16.422Zm6.02-2.792a3.661,3.661,0,0,1-.534.027H7.921a3.619,3.619,0,0,1-.534-.027V13.54a.531.531,0,0,1,.306-.479l.392-.189.47-4.058.005-.091A4.738,4.738,0,0,1,12.309,4.1l.628-.131V1.923a.344.344,0,0,1,.688,0V3.972l.628.131A4.739,4.739,0,0,1,18,8.724l.475,4.149.391.189a.531.531,0,0,1,.306.478v.089Z"
        transform="translate(-5.558 0.25)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export function ExclamationIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4.8"
      height="21.51"
      viewBox="0 0 4.8 21.51"
      className={className}
    >
      <path
        id="Path_41"
        data-name="Path 41"
        d="M2.58-8.46V-21.51h4.8V-8.46ZM2.58,0V-4.98h4.8V0Z"
        transform="translate(-2.58 21.51)"
        fill="#f35162"
      />
    </svg>
  );
}

export function UserIcon({ className }) {
  return (
    <svg
      id="user"
      xmlns="http://www.w3.org/2000/svg"
      width="89.998"
      height="100"
      viewBox="0 0 89.998 100"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <circle
            id="Ellipse_11"
            data-name="Ellipse 11"
            cx="44.999"
            cy="44.999"
            r="44.999"
            transform="translate(0 0)"
            fill="#322a7d"
          />
        </clipPath>
      </defs>
      <circle
        id="Ellipse_10"
        data-name="Ellipse 10"
        cx="44.999"
        cy="44.999"
        r="44.999"
        transform="translate(0 10.002)"
        fill="#322a7d"
      />
      <circle
        id="Ellipse_6"
        data-name="Ellipse 6"
        cx="4.45"
        cy="4.45"
        r="4.45"
        transform="translate(60.328 26.815)"
        fill="#d98f67"
      />
      <circle
        id="Ellipse_7"
        data-name="Ellipse 7"
        cx="4.45"
        cy="4.45"
        r="4.45"
        transform="translate(21.758 26.815)"
        fill="#d98f67"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M27.6,50.448,17,37.724V25H38.206V37.724Z"
        transform="translate(17.646 19.534)"
        fill="#d98f67"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M51.172,18.35c0-16.117-38.172-10.6-38.172,0V33.195a19.086,19.086,0,0,0,38.172,0Z"
        transform="translate(13.164 0.736)"
        fill="#e59e76"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M33.206,4C20.271,4,12,14.391,12,27.327V32.2l4.241,3.605v-10.6l25.448-8.483,8.483,8.483v10.6L54.413,32.2V27.327c0-8.483-2.121-16.965-12.724-19.086L39.568,4H33.206Z"
        transform="translate(12.043 -4)"
        fill="#000001"
      />
      <g
        id="Mask_Group_1"
        data-name="Mask Group 1"
        transform="translate(0 10.002)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Path_7"
          data-name="Path 7"
          d="M50.534,31h0a25.73,25.73,0,0,1-10.6,2.121A25.73,25.73,0,0,1,29.327,31S6,35.241,6,58.568L42.517,98.395,73.861,58.568C73.861,35.241,50.534,31,50.534,31Z"
          transform="translate(5.319 16.255)"
          fill="#ffa101"
        />
      </g>
    </svg>
  );
}

export function DownloadIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.803"
      height="15.646"
      viewBox="0 0 16.803 15.646"
      className={className}
    >
      <g id="download-1" transform="translate(0.5 0.5)">
        <g id="Group_21" data-name="Group 21">
          <path
            id="Path_20"
            data-name="Path 20"
            d="M15.35,228.217a.451.451,0,0,0-.453.453v4.118a2.032,2.032,0,0,1-2.029,2.029H2.935a2.032,2.032,0,0,1-2.029-2.029V228.6a.453.453,0,0,0-.906,0v4.185a2.938,2.938,0,0,0,2.935,2.935h9.934a2.938,2.938,0,0,0,2.935-2.935V228.67A.453.453,0,0,0,15.35,228.217Z"
            transform="translate(0 -221.077)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1"
          />
          <path
            id="Path_21"
            data-name="Path 21"
            d="M139.239,28.3a.456.456,0,0,0,.319.134.442.442,0,0,0,.319-.134l2.877-2.878a.453.453,0,0,0-.641-.641l-2.1,2.106V17.7a.453.453,0,1,0-.906,0v9.186L137,24.782a.453.453,0,0,0-.641.641Z"
            transform="translate(-131.656 -17.25)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
}
