import { useQuery } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useQSBuilder from "../hooks/useQSBuilder";
import useCurrentUserContext from "../contexts/UserContext";

export function useConsumables(
  customerId,
  queryParams = {
    countOnly: false,
    startDate: "",
    endDate: "",
    daysSinceToday: 30,
    onlyShipped: false,
    onlyAwaitingShipped: false,
    getOrderDetails: false,
  }
) {
  const { currentUser: { actAs } } = useCurrentUserContext();
  const { get } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs  },
  });
  const { buildQS } = useQSBuilder();

  const consumables = useQuery({
    queryKey: ["consumable", customerId, queryParams],
    queryFn: () => get(`/consumable${buildQS(queryParams)}`),
  });

  return {
    consumables,
  };
}
