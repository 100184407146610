import { useQuery } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useQSBuilder from "../hooks/useQSBuilder";
import useCurrentUserContext from "../contexts/UserContext";

export function useServiceDashboard(customerId, dateRange) {
  const {
    currentUser: { actAs },
  } = useCurrentUserContext();
  const staleTime = Infinity;
  const { buildQS } = useQSBuilder();
  const { get } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
  });

  const calls = useQuery({
    queryKey: ["dashboard", "calls", customerId, dateRange],
    queryFn: () => get(`/dashboard/calls${buildQS(dateRange)}`),
    staleTime: staleTime,
    cacheTime: staleTime,
  });

  return {
    calls,
  };
}
