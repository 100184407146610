import { useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import useQSBuilder from "../hooks/useQSBuilder";
import useCurrentUserContext from "../contexts/UserContext";

export function useStatisticsReport(customerId, dateRange) {
  const {
    currentUser: { actAs },
  } = useCurrentUserContext();
  const staleTime = Infinity;
  const { buildQS } = useQSBuilder();
  const { get } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
  });

  const reportDevices = useQuery({
    queryKey: ["dashboard", "report", "devices", customerId],
    queryFn: () => get(`/dashboard/report/devices`),
    staleTime: staleTime,
    cacheTime: staleTime,
  });

  const reportVolumes = useQuery({
    queryKey: ["dashboard", "report", "volumes", customerId, dateRange],
    queryFn: () => get(`/dashboard/report/volumes${buildQS(dateRange)}`),
    staleTime: staleTime,
    cacheTime: staleTime,
  });

  const reportEvents = useQuery({
    queryKey: ["dashboard", "report", "events", customerId, dateRange],
    queryFn: () => get(`/dashboard/report/events${buildQS(dateRange)}`),
    staleTime: staleTime,
    cacheTime: staleTime,
  });

  const reportFixTimes = useQuery({
    queryKey: ["dashboard", "report", "fixTimes", customerId, dateRange],
    queryFn: () => get(`/dashboard/report/fixtime${buildQS(dateRange)}`),
    staleTime: staleTime,
    cacheTime: staleTime,
  });

  return {
    reportDevices,
    reportVolumes,
    reportEvents,
    reportFixTimes,
  };
}
