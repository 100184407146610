import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import FullscreenLoading from "../../components/shared/FullscreenLoading";

import useNotification from "../../hooks/useNotifications";
import usePageTitleContext from "../../contexts/PageTitleContext";

import Breadcrumbs from "../../components/shared/Breadcrumbs";
import { withCustomer } from "../../data/withCustomer";
import { useSupport } from "../../data/useSupport";
import useCurrentUserContext from "../../contexts/UserContext";

import { buildAddressString } from "../../utils/buildAddressString";
import { permissionsLookup, useRoleCheck } from "../../hooks/useRoleCheck";
import { NoPermission } from "../../components/shared/NoPermission";

function _TonerRecycling({ customerLoading, customer }) {
  const { checkAccess } = useRoleCheck();
  const { addNotification } = useNotification();
  const { currentUser } = useCurrentUserContext();
  const { setTitle } = usePageTitleContext();
  const { requestBoxes } = useSupport(customer.id);
  const [selectedSite, setSelectedSite] = useState(undefined);
  const [selectedSiteError, setSelectedSiteError] = useState(undefined);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const pages = [
    { to: "..", label: "Support", current: false },
    { to: "", label: "Order Recycling Box", current: true },
  ];

  const onSubmit = (data) => {
    if (!selectedSite) {
      setSelectedSiteError("You must select a delivery address");
      return;
    }

    const site = currentUser.user.sites.find((s) => s.id === selectedSite);

    requestBoxes.mutate(
      {
        ...data,
        companyName: site.name,
        addressLine1: site.address1,
        addressLine2: site.address2,
        addressLine3: site.address3,
        town: site.town,
        postcode: site.postCode
      },
      {
        onSuccess: () => {
          setSelectedSite(undefined);
          addNotification({
            variant: "success",
            primaryText: "Recycling Boxes Requested",
            secondaryText:
              "Your recycling box request has been submitted to the team",
          });
        },
      }
    );
    reset();
  };

  //Autofill user info
  useEffect(() => {
    if (!currentUser.loading && currentUser.user) {
      reset({
        YourName: `${currentUser.user.firstname} ${currentUser.user.lastname}`,
        EmailAddress: currentUser.user.emailAddress,
        ContactNumber: currentUser.user.telephone,
      });
    }
  }, [currentUser.loading, currentUser.user, reset]);

  useEffect(() => {
    setTitle("Order Recycling Box");
  }, [setTitle]);

  if (!checkAccess(permissionsLookup.orderRecyclingBox.realm)) {
    return <NoPermission />;
  } else if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="bg-white rounded-md shadow p-8">
          <h1 className="text-xl font-medium text-ag-default mb-6">
            Request Recycling Box
          </h1>
          <p className="text-gray-900 mb-10">
            There are a number of options when it comes to recycling toners,
            these will depend on the manufacturer of the toner. Please complete
            the request form below and one of the Toner Team will come back to
            you with the available options.
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className="text-gray-500">
            <div className="mt-5 grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col">
                <label className="mb-1 font-medium text-ag-default">
                  Your Name
                </label>
                <input
                  type="text"
                  {...register("YourName", {
                    required: "Please enter your name",
                    minLength: {
                      value: 3,
                      message: "Please enter a valid name with 3 or more characters",
                    },
                  })}
                  aria-invalid={errors.YourName ? "true" : "false"}
                  className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.YourName && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.YourName && (
                  <p role="alert" className="text-red-600">
                    {errors.YourName?.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="mb-1 font-medium text-ag-default">
                  Your Email
                </label>
                <input
                  type="text"
                  {...register("EmailAddress", {
                    required: "Please enter your email address",
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  aria-invalid={errors.EmailAddress ? "true" : "false"}
                  className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.EmailAddress && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.EmailAddress && (
                  <p role="alert" className="text-red-600">
                    {errors.EmailAddress?.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="mb-1 font-medium text-ag-default">
                  Contact Number
                </label>
                <input
                  type="text"
                  {...register("ContactNumber", {
                    required: "Please enter a contact number",
                    minLength: {
                      value: 5,
                      message: "Please enter a valid contact number",
                    },
                  })}
                  aria-invalid={errors.ContactNumber ? "true" : "false"}
                  className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.ContactNumber &&
                    "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.ContactNumber && (
                  <p role="alert" className="text-red-600">
                    {errors.ContactNumber?.message}
                  </p>
                )}
              </div>
            </div>

            <div className="block overflow-y-hidden overflow-x-auto my-8">
              <table className="text-black min-w-full divide-y border divide-gray-300">
                <thead>
                  <tr className="border-b-2 border-gray-300">
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    ></th>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Company Name
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Address Line 1
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Address Line 2
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Address Line 3
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Town
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Postcode
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentUser.user.sites.map((site, i) => {
                    return (
                      <tr
                        key={`row-${i}`}
                        className={`text-sm ${
                          i % 2 === 0 ? "bg-gray-100" : "bg-white"
                        }`}
                      >
                        <td className="p-3 text-sm align-text-top font-medium">
                          <div className="p-3 items-center">
                              <input
                                type="checkbox"
                                className="rounded h-6 w-6 border text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
                                checked={selectedSite === site.id}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedSite(site.id);
                                    if (selectedSiteError) {
                                      setSelectedSiteError(undefined);
                                    }
                                  } else {
                                    setSelectedSite(undefined);
                                  }
                                }}
                              />
                            <br />
                            {/* <span className="text-xs">PN: {val}</span> */}
                          </div>
                        </td>
                        <td className="p-3 text-sm align-text-top font-medium">{site.name}</td>
                        <td className="p-3 text-sm align-text-top font-medium">{site.address1}</td>
                        <td className="p-3 text-sm align-text-top font-medium">{site.address2}</td>
                        <td className="p-3 text-sm align-text-top font-medium">{site.address3}</td>
                        <td className="p-3 text-sm align-text-top font-medium">{site.town}</td>
                        <td className="p-3 text-sm align-text-top font-medium">{site.postCode}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {selectedSiteError && (
                <p role="alert" className="mt-1 text-red-600">
                  {selectedSiteError}
                </p>
              )}
            </div>

            <div className="mt-4 w-full flex flex-col">
              <label className="mb-1 font-medium text-ag-default">Notes</label>
              <textarea
                {...register("Notes")}
                aria-invalid={errors.DeliveryAddress ? "true" : "false"}
                className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
                rows={5}
              />
            </div>

            <div className="mt-8">
              <button
                type="submit"
                className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
              >
                Submit Request
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const TonerRecycling = withCustomer(_TonerRecycling);

export default TonerRecycling;
