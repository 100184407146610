import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";

import { XMarkIcon } from "@heroicons/react/20/solid";

import AgilicoLogo from "../../assets/logo.svg";

import classNames from "../../utils/classNames";

export default function MobileNavigation({
  sidebarOpen,
  setSidebarOpen,
  sidebarNavigation,
  handleNavigate,
}) {
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 md:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed w-5/12 inset-0 bg-ag-default bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative w-5/12 flex flex-col items-center pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 pr-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-8 w-8 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                <a href="https://www.agilico.co.uk/">
                  <img
                    src={AgilicoLogo}
                    className="w-16 h-16"
                    alt="Agilico Logo"
                  />
                </a>
              </div>
              <div className="mt-5 h-0 flex-1 overflow-y-auto">
                <nav className="space-y-6 text-center">
                  {sidebarNavigation.map((item) => (
                    <button
                      type="button"
                      key={item.name}
                      onClick={() => handleNavigate(item)}
                      className={classNames(
                        item.current
                          ? "text-ag-green border-r-4 border-ag-green"
                          : "text-white hover:text-gray-400",
                        "flex-shrink-0 inline-flex flex-col items-center justify-center w-full"
                      )}
                    >
                      <span className="font-semibold text-sm">{item.name}</span>
                    </button>
                  ))}
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
