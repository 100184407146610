import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

function NotFound() {
  return (
    <div className="p-8 bg-white rounded shadow flex flex-col items-center">
      <ExclamationTriangleIcon className="w-32 h-32 text-ag-darkBlue" />
      <h1 className="text-xl">Whoops!</h1>
      <p>
        Looks like you've made it to a page that doesn't exist, use the button
        below to go back
      </p>
      <button
        onClick={() => window.history.back()}
        className="mt-5 p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
      >
        Take me back
      </button>
    </div>
  );
}

export default NotFound;
