import date from "date-and-time";
import { DownloadIcon } from "../../assets/CustomIcons";

export default function InvoiceCard({ invoice, onDownloadClick }) {
  return (
    <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
      <div className="w-full items-center pb-3 pt-6 px-6">
        <div className="flex items-center space-x-3">
          <h3 className="truncate text-md font-semibold text-gray-900">{`Invoice ${invoice.reference}`}</h3>
        </div>

        <div className="grid grid-cols-1 mt-4">
          <div className="grid grid-span-1">
            <span className="text-gray-500 text-sm">Customer</span>
            <span>{invoice.name}</span>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-4">
          <div className="grid grid-span-1">
            <span className="text-gray-500 text-sm">Invoice Date</span>
            <span>
              {date.format(new Date(invoice.invoiceDate), "DD/MM/YYYY")}
            </span>
          </div>
          <div className="grid grid-span-1">
            <span className="text-gray-500 text-sm">Value</span>
            <span>{`£${invoice.baseTotalValue}`}</span>
          </div>
        </div>
      </div>

      <div>
        <div
          onClick={() => onDownloadClick(invoice)}
          className="w-full py-4 flex items-center justify-center text-gray-700 hover:text-gray-500 hover:cursor-pointer"
        >
          <DownloadIcon className="w-5 h-5 mr-2" />
          <span>Download</span>
        </div>
      </div>
    </li>
  );
}
