import { NoSymbolIcon } from "@heroicons/react/24/outline";

export function NoPermission() {
  return (
    <div className="flex items-center justify-center">
      <div className="py-4 px-6 bg-white py-10 flex items-center justify-center">
        <div className="space-x-4 flex items-center justify-center">
          <NoSymbolIcon className="text-ag-default h-12 w-12" />
          <div>
            <h2 className="text-ag-default text-xl">Access Denied</h2>
            <p>
              You do not have access to this section, please get in contact with
              an administrator to request access
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
