import { SearchIcon } from "../../../assets/CustomIcons";

export default function Search() {
  return (
    <div className="px-4 py-3 w-full flex items-center rounded-full bg-gray-100">
      <SearchIcon className="w-6 h-4 flex-shrink-0 text-ag-darkBlue" />
      <input placeholder="Search keyword" type="text" className="w-full pl-2 mx-2 border-l-2 border-gray-300 bg-gray-100 focus:outline-none" />
    </div>
  );
}
