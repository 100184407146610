import { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import date from "date-and-time";

import { useDeviceDashboard } from "../../data/useDeviceDashboard";

import LoadingWheel from "../shared/LoadingWheel";

function VolumeStatsTable({ volumes, spend }) {
  let overallMonoTotal = 0;
  let overallColourTotal = 0;
  let overallTotalVolumes = 0;
  let overallColourPercent = 0;
  let overallMeterTotal = 0;

  const mergedResults = volumes.map((vol, i) => {
    let matchedSpend = spend.find(
      (sp) => sp.month === vol.month && sp.year === vol.year
    );

    return {
      ...vol,
      spend: matchedSpend?.value,
    };
  });

  return (
    <table className="text-black min-w-full divide-y border divide-gray-300">
      <thead>
        <tr className="border-b-2 border-gray-300">
          <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"></th>
          {mergedResults.map((col, i) => (
            <th className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 lg:table-cell">
              {col.label}
            </th>
          ))}
          <th className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 lg:table-cell text-center">
            Totals
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-gray-100">
          <td className="py-2 pl-5 px-1 font-semibold">Mono Total</td>
          {mergedResults.map((col) => {
            overallMonoTotal += col.mono;
            return <td className="py-2 px-1 text-center">{col.mono}</td>;
          })}
          <td className="py-2 px-1 text-center">{overallMonoTotal}</td>
        </tr>
        <tr>
          <td className="py-2 pl-5 px-1 font-semibold">Colour Total</td>
          {mergedResults.map((col) => {
            overallColourTotal += col.colour;
            return <td className="py-2 px-1 text-center">{col.colour}</td>;
          })}
          <td className="py-2 px-1 text-center">{overallColourTotal}</td>
        </tr>
        <tr className="bg-gray-100 font-semibold">
          <td className="py-2 pl-5 px-1">Total Volume</td>
          {mergedResults.map((col) => {
            overallTotalVolumes += col.mono + col.colour;
            return (
              <td className="py-2 px-1 text-center">{col.mono + col.colour}</td>
            );
          })}
          <td className="py-2 px-1 font-normal text-center">
            {overallTotalVolumes}
          </td>
        </tr>
        <tr className="font-semibold">
          <td className="py-2 pl-5 px-1 font-semibold">Colour %</td>
          {volumes.map((col) => {
            let colourPercent = (col.colour / (col.mono + col.colour)) * 100;
            if (isNaN(colourPercent) || colourPercent === Infinity) colourPercent = 0;
            overallColourPercent += colourPercent;
            return (
              <td className="py-2 px-1 text-center">
                {!isNaN(colourPercent) ? colourPercent.toFixed(2) : 0}%
              </td>
            );
          })}
          <td className="font-normal text-center">
            {!isNaN(Number(overallColourPercent / volumes.length)) ? Number(overallColourPercent / volumes.length).toFixed(2) : 0}%
          </td>
        </tr>
        <tr className="bg-gray-100 font-semibold">
          <td className="py-2 pl-5 px-1 font-semibold">Service Spend</td>
          {mergedResults.map((col) => {
            let meterTotal = col.spend;
            if (isNaN(col.spend) || col.spend === Infinity) meterTotal = 0;
            overallMeterTotal += meterTotal;
            return (
              <td className="py-2 px-1 text-center">
                £{!isNaN(meterTotal) ? meterTotal.toFixed(2) : 0}
              </td>
            );
          })}
          <td className="font-normal text-center">
            £{!isNaN(overallMeterTotal) ? overallMeterTotal.toFixed(2) : 0}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default function DeviceTab({ customer, dateFilters, barGraphOptions }) {
  const { volumeSummary, serviceSpend } = useDeviceDashboard(
    customer.id,
    dateFilters
  );

  useEffect(() => {}, [dateFilters]);

  const getServiceSpendData = () => ({
    labels: [...serviceSpend.data.results.map((el) => el.label)],
    datasets: [
      {
        label: `Service Spend`,
        data: [...serviceSpend.data.results.map((el) => el.value)],
        borderColor: "rgb(9, 0, 65)",
        backgroundColor: "rgb(9, 0, 65)",
      },
    ],
  });

  const getMonoColourVolumesData = () => ({
    labels: [...volumeSummary.data.results.map((el) => el.label)],
    datasets: [
      {
        label: `Mono`,
        data: [...volumeSummary.data.results.map((el) => el.mono)],
        borderColor: "rgb(9, 0, 65)",
        backgroundColor: "rgb(9, 0, 65)",
      },
      {
        label: `Colour`,
        data: [...volumeSummary.data.results.map((el) => el.colour)],
        borderColor: "rgb(44, 222, 84)",
        backgroundColor: "rgb(44, 222, 84)",
      },
    ],
  });

  const getColourPercentData = () => ({
    labels: [...volumeSummary.data.results.map((el) => el.label)],
    datasets: [
      {
        label: `Colour %`,
        data: [
          ...volumeSummary.data.results.map((el) => {
            let colourPercent = (el.colour / (el.mono + el.colour)) * 100;
            if (isNaN(colourPercent) || colourPercent === Infinity) colourPercent = 0;
            return colourPercent.toFixed(2);
          }),
        ],
        borderColor: "rgb(9, 0, 65)",
        backgroundColor: "rgb(9, 0, 65)",
      },
    ],
  });

  const exportCSV = () => {
    let csvString = ",";
    volumeSummary.data.results.map((s) => (csvString += `${s.label},`));
    csvString += "Totals,\n";

    csvString += "monoTotal,";
    let monoTotal = 0;
    volumeSummary.data.results.map((s) => {
      monoTotal += s.mono;
      return (csvString += `${s.mono},`);
    });
    csvString += `${monoTotal},\n`;

    csvString += "colourTotal,";
    let colourTotal = 0;
    volumeSummary.data.results.map((s) => {
      colourTotal += s.colour;
      return (csvString += `${s.colour},`);
    });
    csvString += `${colourTotal},\n`;

    csvString += "totalVolume,";
    let totalVolume = 0;
    volumeSummary.data.results.map((s) => {
      totalVolume += s.colour + s.mono;
      return (csvString += `${s.colour + s.mono},`);
    });
    csvString += `${totalVolume},\n`;

    csvString += "colorPercent,";
    let totalColourPercent = 0;
    volumeSummary.data.results.map((s) => {
      let colourPercent = (s.colour / (s.mono + s.colour)) * 100;
      totalColourPercent += colourPercent;

      return (csvString += `${colourPercent.toFixed(2)},`);
    });
    csvString += `${(totalColourPercent / volumeSummary.data.results.length).toFixed(
      2
    )},\n`;

    csvString += "serviceSpend,";
    let totalSpend = 0;
    serviceSpend.data.results.map((s) => {
      totalSpend += s.value;
      return (csvString += `${s.value},`);
    });
    csvString += `${totalSpend}`;

    const downloadLink = document.createElement("a");
    downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(csvString);
    downloadLink.target = "_blank";
    downloadLink.download = `VolumeStatsExport-${date.format(
      new Date(),
      "DDMMYYYY"
    )}.csv`;
    downloadLink.click();
  };

  const barGraphOptionsGBP = {
    ...barGraphOptions, 
    plugins: {
      ...barGraphOptions.plugins, 
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(context.parsed.y);
            }
            return label;
          }
        }
      }
    }
  };

  const barGraphOptionsPercentage = {
    ...barGraphOptions,
    plugins: {
      ...barGraphOptions.plugins,
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
  
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(2) + '%';
            }
            return label;
          }
        }
      }
    }
  };

  return (
    <>
      <div className="col-span-4 md:col-span-4 bg-white shadow rounded-md p-5">
        <div className="flex items-center justify-between">
          <h1 className="mb-2 text-xl font-medium text-ag-default">
            Volume Stats Summary
          </h1>
          <button
            className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
            onClick={exportCSV}
          >
            Export
          </button>
        </div>
        <p className="mb-4 italic">
          <span className="font-semibold">Note: </span>The figures shown below
          is the actual volume invoiced in that period.
        </p>
        {!volumeSummary.isLoading &&
        !serviceSpend.isLoading &&
        volumeSummary.data?.results &&
        serviceSpend.data?.results ? (
          <VolumeStatsTable
            volumes={volumeSummary.data.results}
            spend={serviceSpend.data.results}
          />
        ) : (
          <LoadingWheel />
        )}
      </div>

      <div className="col-span-4 md:col-span-4 bg-white shadow rounded-md p-5">
        <h1 className="mb-4 text-xl font-medium text-ag-default">
          Service Spend
        </h1>
        {!serviceSpend.isLoading && serviceSpend.data?.results ? (
          <Bar
            height={60}
            options={barGraphOptionsGBP}
            data={getServiceSpendData()}
          />
        ) : (
          <LoadingWheel />
        )}
      </div>

      <div className="col-span-4 md:col-span-2 bg-white shadow rounded-md p-5">
        <h1 className="mb-4 text-xl font-medium text-ag-default">
          Mono/Colour Volumes
        </h1>
        {!volumeSummary.isLoading && volumeSummary.data?.results ? (
          <Bar
            height={120}
            options={barGraphOptions}
            data={getMonoColourVolumesData()}
          />
        ) : (
          <LoadingWheel />
        )}
      </div>

      <div className="col-span-4 md:col-span-2 bg-white shadow rounded-md p-5">
        <h1 className="mb-4 text-xl font-medium text-ag-default">
          Colour % Against Total Volume
        </h1>

        {!volumeSummary.isLoading && volumeSummary.data?.results ? (
          <Bar
            height={120}
            options={barGraphOptions}
            data={getColourPercentData()}
          />
        ) : (
          <LoadingWheel />
        )}
      </div>
    </>
  );
}
