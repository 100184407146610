import { withCustomer } from "../../data/withCustomer";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import usePageTitleContext from "../../contexts/PageTitleContext";
import Breadcrumbs from "../../components/shared/Breadcrumbs";

import CanonLogo from "../../assets/BrandLogos/CanonLogo.png";
import RicohLogo from "../../assets/BrandLogos/RicohLogo.png";
import SharpLogo from "../../assets/BrandLogos/SharpLogo.png";
import ToshibaLogo from "../../assets/BrandLogos/ToshibaLogo.png";
import XeroxLogo from "../../assets/BrandLogos/XeroxLogo.png";
import KyoceraLogo from "../../assets/BrandLogos/KyoceraLogo.png";
import HpLogo from "../../assets/BrandLogos/HpLogo.png";
import KonicaMinoltaLogo from "../../assets/BrandLogos/KonicaMinoltaLogo.png";
import { useEffect } from "react";
import { permissionsLookup, useRoleCheck } from "../../hooks/useRoleCheck";
import { NoPermission } from "../../components/shared/NoPermission";

function _DownloadPrinterDrivers({ companyLoading, company }) {
  const { checkAccess } = useRoleCheck();
  const { setTitle } = usePageTitleContext();

  const pages = [
    { to: "..", label: "Support", current: false },
    { to: "", label: "Driver Downloads", current: true },
  ];

  useEffect(() => {
    setTitle("Driver Downloads");
  }, [setTitle]);

  if (!checkAccess(permissionsLookup.printerDriver.realm)) {
    return <NoPermission />;
  } else if (companyLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="bg-white rounded-md shadow p-4">
          <div className="grid grid-cols-1 gap-5">
            <div className="p-4">
              <h1 className="text-xl font-medium text-ag-default mb-6">
                Let's find the drivers for your printer
              </h1>
              <p className="mt-5 text-gray-500">
                Please click on the brand icons for the printer you require
                drivers for, the link will take you to the manufacturer's
                website where you can download the latest drivers.
              </p>
            </div>

            <div className="p-16 grid grid-cols-1 md:grid-cols-4 gap-32">
              <div>
                <a
                  href="https://www.canon.co.uk/support/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={CanonLogo} alt="Canon Logo" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.ricoh.co.uk/support"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={RicohLogo} alt="Ricoh Logo" />
                </a>
              </div>
              <div>
                <a
                  href="http://global.sharp/restricted/products/copier/downloads/select_us_driver.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={SharpLogo} alt="Sharp Logo" />
                </a>
              </div>
              <div>
                <a
                  href="https://business.toshiba.com/support"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ToshibaLogo} alt="Toshiba Logo" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.support.xerox.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={XeroxLogo} alt="Xerox Logo" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.kyoceradocumentsolutions.co.uk/en/support/downloads.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={KyoceraLogo} alt="Kyocera Logo" />
                </a>
              </div>
              <div>
                <a
                  href="https://support.hp.com/us-en/drivers/printers"
                  target="_blank"
                  className="flex justify-center"
                  rel="noreferrer"
                >
                  <img className="w-20 h-auto" src={HpLogo} alt="HP Logo" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.konicaminolta.co.uk/en-gb/customer-support/download-centre"
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center"
                >
                  <img
                    className="w-32 h-auto"
                    src={KonicaMinoltaLogo}
                    alt="Konica Minolta Logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DownloadPrinterDrivers = withCustomer(_DownloadPrinterDrivers);

export default DownloadPrinterDrivers;
