import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useQSBuilder from "../hooks/useQSBuilder";
import useCurrentUserContext from "../contexts/UserContext";

export function useDeviceMeters(
  customerId,
  serialNo
) {
  const { currentUser: { actAs } } = useCurrentUserContext();
  const { get } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
  });

  const meters = useQuery({
    queryKey: ["consumables", "meters", customerId, serialNo],
    queryFn: () => get(`/consumable/meters/${serialNo}`),
  });

  return {
    meters
  };
}
