import { useEffect } from "react";
import { useForm } from "react-hook-form";

import useNotification from "../../hooks/useNotifications";
import useCurrentUserContext from "../../contexts/UserContext";
import { useAdmin } from "../../data/useAdmin";

export default function UserDetails({ customer }) {
  const { currentUser } = useCurrentUserContext();
  const { addNotification } = useNotification();
  const { updateMe } = useAdmin(customer.id);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (!currentUser.loading && currentUser.user) {
      reset({
        FirstName: currentUser.user.firstname,
        LastName: currentUser.user.lastname,
        Telephone: currentUser.user.telephone,
        Department: currentUser.user.department,
      });
    }
  }, [currentUser.loading, currentUser.user, reset]);

  const onSubmit = (data) => {
    updateMe.mutate(data, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Updated successfully",
          secondaryText: "Your details were successfully updated",
        });
      }
    })
  };

  return (
    <section aria-labelledby="user-details-heading">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="user-details-heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                User Details
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Update your personal information.
              </p>
            </div>

            <div className="mt-6 grid grid-cols-4 gap-6">
              <div className="col-span-4 md:col-span-2">
                <label className="mb-1 font-medium text-ag-default">
                  First Name
                </label>
                <input
                  type="text"
                  {...register("FirstName", {
                    required: "Please enter your first name",
                    minLength: {
                      value: 2,
                      message: "Please enter a valid name with 2 or more characters",
                    },
                  })}
                  aria-invalid={errors.FirstName ? "true" : "false"}
                  className={`w-full border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.FirstName && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.FirstName && (
                  <p role="alert" className="text-red-600">
                    {errors.FirstName?.message}
                  </p>
                )}
              </div>

              <div className="col-span-4 md:col-span-2">
                <label className="mb-1 font-medium text-ag-default">
                  Last Name
                </label>
                <input
                  type="text"
                  {...register("LastName", {
                    required: "Please enter your last name",
                    minLength: {
                      value: 2,
                      message: "Please enter a valid name with 2 or more characters",
                    },
                  })}
                  aria-invalid={errors.LastName ? "true" : "false"}
                  className={`w-full border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.LastName && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.LastName && (
                  <p role="alert" className="text-red-600">
                    {errors.LastName?.message}
                  </p>
                )}
              </div>

              <div className="col-span-4 md:col-span-2">
                <label className="mb-1 font-medium text-ag-default">
                  Department
                </label>
                <input
                  type="text"
                  {...register("Department", {
                    required: "Please enter your department",
                    minLength: {
                      value: 2,
                      message: "Please enter a valid department with 2 or more characters",
                    },
                  })}
                  aria-invalid={errors.Department ? "true" : "false"}
                  className={`w-full border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.Department && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.Department && (
                  <p role="alert" className="text-red-600">
                    {errors.Department?.message}
                  </p>
                )}
              </div>

              <div className="col-span-4 md:col-span-2">
                <label className="mb-1 font-medium text-ag-default">
                  Telephone
                </label>
                <input
                  type="text"
                  {...register("Telephone", {
                    required: "Please enter your phone number",
                    minLength: {
                      value: 7,
                      message: "Please enter a valid phone number",
                    },
                  })}
                  aria-invalid={errors.Telephone ? "true" : "false"}
                  className={`w-full border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.Telephone && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.Telephone && (
                  <p role="alert" className="text-red-600">
                    {errors.Telephone?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}
