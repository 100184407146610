import { useState, Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/20/solid";
import classNames from "../../utils/classNames";
import useCurrentUserContext from "../../contexts/UserContext";
import { useLocation, useNavigate } from "react-router";
import LoadingWheel from "../shared/LoadingWheel";

export default function SwitchCustomer() {
  const location = useLocation();
  const {
    currentUser: { loading: userLoading, user, customer },
  } = useCurrentUserContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (user && customer) {
      setSelected(customer);
      setLoading(false);
    }
  }, [user, customer, userLoading]);

  const switchCustomer = (newCustomer) => {
    let parts = location.pathname.split("/");
    parts.splice(0, 2);
    navigate(`/${newCustomer.reference.toLowerCase()}/${parts.join("/")}`);
  };

  const getUniqueSites = (user) => {
    const uniqueSites = Array.from(new Set(user.customers.map(x => JSON.stringify(x))));
    return uniqueSites.map(x => JSON.parse(x));
  };

  if (loading) {
    return <LoadingWheel />;
  } else {

    return (
      <Listbox value={selected} onChange={switchCustomer}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700"></Listbox.Label>
            <div className="relative">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-ag-default focus:border-ag-default sm:text-sm">
                <span className="block truncate">{selected.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {getUniqueSites(user)
                    .sort((a, b) => {
                        const nameA = a.name.trim().toUpperCase().replace(/\s+/g, ' ');
                        const nameB = b.name.trim().toUpperCase().replace(/\s+/g, ' ');
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    })
                    .map((customer) => (
                      <Listbox.Option
                        key={customer.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-ag-default"
                              : "text-gray-900",
                            "cursor-default select-none relative py-2 pl-8 pr-4"
                          )
                        }
                        value={customer}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {customer.name}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-ag-default",
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    );
  }
}
