export function buildAddressString(
  address1 = undefined,
  address2 = undefined,
  town = undefined,
  postcode = undefined
) {
  let addrString = "";

  if (address1) {
    addrString += `${address1}`;
  }

  if (address2) {
    addrString += `, ${address2}`;
  }

  if (town) {
    addrString += `, ${town}`;
  }

  if (postcode) {
    addrString += `, ${postcode}`;
  }

  return addrString;
}
