import date from "date-and-time";

export default function SupportTicketCard({ ticket, onCancelClick }) {
  return (
    <li className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow">
      <div className="w-full items-center pb-3 pt-6 px-6">

        <div className="grid grid-cols-2 mt-4">
          <div className="grid grid-span-1">
            <span className="text-gray-500 text-sm">Onsite Location</span>
            {ticket.location ? (
              <span>{ticket.location}</span>
            ) : (
              <span className="text-gray-400">N/A</span>
            )}
          </div>

          <div className="grid grid-span-1">
            <span className="text-gray-500 text-sm">Device Serial</span>
            <span>{ticket.serialNumber}</span>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-4">
          <div className="grid grid-span-1">
            <span className="text-gray-500 text-sm">Ticket No.</span>
            <span>{ticket.reference}</span>
          </div>
          <div className="grid grid-span-1">
            <span className="text-gray-500 text-sm">Ticket Type</span>
            <span>{ticket.category}</span>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-4">
          <div className="grid grid-span-1">
            <span className="text-gray-500 text-sm">Opened On</span>
            <span>
              {date.format(new Date(ticket.loggedDate), "DD/MM/YY HH:mm")}
            </span>
          </div>
        </div>

        <div className="mt-4 border-t-2">
          <div className="grid grid-cols-2 mt-3">
            <div className="grid grid-span-1">
              <span className="font-semibold text-ag-default">
                Ticket Status
              </span>
            </div>
            <div>
              <span className="font-semibold">{ticket.engineerStatusText}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-grow !border-0"></div>
      <div>
        <div className="flex divide-x divide-gray-200">
          {ticket.completedDate == null && (
            <div className="flex w-0 flex-1 justify-center py-3">
              <button
                type="button"
                onClick={() => onCancelClick(ticket)}
                className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
              >
                Cancel
              </button>
            </div>
          )}
          {/* <div className="-ml-px flex w-0 flex-1">
            <Link
              to={`../ticket/${ticket.reference}`}
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
            >
              <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">View</span>
            </Link>
          </div> */}
        </div>
      </div>
    </li>
  );
}
