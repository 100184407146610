import useCurrentUserContext from "../contexts/UserContext";

export const permissionsCategories = {
  support: { name: "Support" },
  consumables: { name: "Consumables & Orders" },
  fleetManagement: { name: "Fleet Management" },
  statistics: { name: "Statistics" },
  billing: { name: "Billing" },
};

export const permissionsLookup = {
  newTicket: { realm: "newTicket", name: "New Ticket", category: "support" },
  openTicket: {
    realm: "openTicket",
    name: "Open Tickets",
    category: "support",
  },
  closedTicket: {
    realm: "closedTicket",
    name: "Closed Tickets",
    category: "support",
  },
  placeTonerOrder: {
    realm: "placeTonerOrder",
    name: "Place Toner Order",
    category: "consumables",
  },
  placeConsumableOrder: {
    realm: "placeConsumableOrder",
    name: "Place Consumable Order",
    category: "consumables",
  },
  orderHistory: {
    realm: "orderHistory",
    name: "Order History",
    category: "consumables",
  },
  submitMeterReadings: {
    realm: "submitMeterReadings",
    name: "Submit Meter Readings",
    category: "consumables",
  },
  orderRecyclingBox: {
    realm: "orderRecyclingBox",
    name: "Order Recycling Box",
    category: "consumables",
  },
  askSomethingElse: {
    realm: "askSomethingElse",
    name: "Ask Something Else",
    category: "support",
  },
  printerMove: {
    realm: "printerMove",
    name: "Printer Move Request",
    category: "support",
  },
  printerDriver: {
    realm: "printerDriver",
    name: "Driver Downloads",
    category: "support",
  },
  guides: {
    realm: "guides",
    name: "Guides & Manuals",
    category: "support",
  },
  kpaxOverview: {
    realm: "kpaxOverview",
    name: "Overview",
    category: "fleetManagement",
  },
  kpaxAlerts: {
    realm: "kpaxAlerts",
    name: "Alerts",
    category: "fleetManagement",
  },
  kpaxReports: {
    realm: "kpaxReports",
    name: "Reports",
    category: "fleetManagement",
  },
  kpaxReadings: {
    realm: "kpaxReadings",
    name: "Readings",
    category: "fleetManagement",
  },
  dashboardService: {
    realm: "dashboardService",
    name: "Service",
    category: "statistics",
  },
  dashboardStats: {
    realm: "dashboardStats",
    name: "Dashboard/Stats",
    category: "statistics",
  },
  dashboardLocation: {
    realm: "dashboardLocation",
    name: "Location",
    category: "statistics",
  },
  viewInvoice: {
    realm: "viewInvoice",
    name: "View Invoices",
    category: "billing",
  },
};

export const permissionTemplate = (customerId) => {
  return {
    askSomethingElse: true,
    closedTicket: true,
    customerId: customerId,
    dashboardLocation: true,
    dashboardService: true,
    dashboardStats: true,
    guides: true,
    kpaxAlerts: true,
    kpaxOverview: true,
    kpaxReadings: true,
    kpaxReports: true,
    kpaxTraining: true,
    newTicket: true,
    openTicket: true,
    orderHistory: true,
    orderRecyclingBox: true,
    placeConsumableOrder: true,
    placeTonerOrder: true,
    printerDriver: true,
    printerMove: true,
    submitMeterReadings: true,
    viewInvoice: true,
    isActive: true
  }
}

export const useRoleCheck = () => {
  const { currentUser } = useCurrentUserContext();

  const checkAccess = (realm) => {

    if(currentUser.user && currentUser.user.isAdmin) {
      return true;
    }

    if (!currentUser.loading && currentUser.user) {
      const matchedSitePermission = currentUser.user.sitePermissions.find(sitePermission => {
        return sitePermission.customerId === currentUser.user.customer.id;
      });

      if (matchedSitePermission) {
        return matchedSitePermission[realm];
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return {
    checkAccess,
  };
};

export const useAdminCheck = () => {
  const { currentUser } = useCurrentUserContext();

  const checkAdminAccess = () => {

    if(currentUser?.user?.demoPortal) return false;

    if(currentUser?.actAs) {
      return currentUser?.user?.adUser?.groups.some(x => x.displayName === 'PORTAL - Customer Portal Admin');
    } else {
      return true;
    }
  }

  return {
    checkAdminAccess,
  };
};
