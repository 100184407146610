import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useCurrentUserContext from "../contexts/UserContext";

export function useKpax(customerId) {
  const { currentUser: { actAs } } = useCurrentUserContext();
  const { get, post, del } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
  });
  const queryClient = useQueryClient();

  const devices = useQuery({
    queryKey: ["kpax", "devices", customerId],
    queryFn: () => get("/kpax/devices"),
  });

  const alerts = useQuery({
    queryKey: ["kpax", "alerts", customerId],
    queryFn: () => get("/kpax/alerts"),
  });

  const reportAuth = useQuery({
    queryKey: ["kpax","auth", customerId],
    queryFn: () => get("/kpax/token")
  })

  const createAlert = useMutation(
    (alertRequest) => post("/kpax/alerts", JSON.stringify(alertRequest)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["kpax", "alerts"]);
      },
    }
  );

  const removeAlert = useMutation((alertId) => del(`/kpax/alerts/${alertId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["kpax", "alerts"]);
    },
  });

  return {
    devices,
    alerts,
    reportAuth,
    createAlert,
    removeAlert
  };
}
