import { useEffect, useMemo, useRef, useState } from "react";

import date from "date-and-time";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment/moment";

import classNames from "../../utils/classNames";
import useApiHelper from "../../hooks/useApiHelper";
import useQSBuilder from "../../hooks/useQSBuilder";
import { withCustomer } from "../../data/withCustomer";
import { useStatsDate } from "../../hooks/useStatsDate";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import DeviceTab from "../../components/Statistics/DeviceTab";
import useCurrentUserContext from "../../contexts/UserContext";
import ServiceTab from "../../components/Statistics/ServiceTab";
import usePageTitleContext from "../../contexts/PageTitleContext";
import LocationTab from "../../components/Statistics/LocationTab";
import { NoPermission } from "../../components/shared/NoPermission";
import FullscreenLoading from "../../components/shared/FullscreenLoading";
import { permissionsLookup, useRoleCheck } from "../../hooks/useRoleCheck";
import { StatisticsReport } from "../../components/Statistics/StatisticsReport";
import LoadingWheel from "../../components/shared/LoadingWheel";

function _Statistics({ customerLoading, customer }) {
  const { buildQS } = useQSBuilder();
  const { checkAccess } = useRoleCheck();
  const {
    currentUser: { actAs },
  } = useCurrentUserContext();
  const { get } = useApiHelper({
    headers: { "X-CustomerId": customer.id, "X-ActAs": actAs },
  });
  const navigate = useNavigate();
  const { companyName, section } = useParams();
  const { setTitle } = usePageTitleContext();
  const { startDate, endDate, dateRangeError, setStartDate, setEndDate } =
    useStatsDate();
  const [generate, setGenerate] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const reportRef = useRef();

  const pages = [{ to: "", label: "Dashboard", current: true }];

  const barGraphOptions = {
    type: "bar",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          display: true,
          stepSize: 1,
        },
        grid: {
          display: true,
          drawBorder: true,
        },
      },
    },
  };

  const getPanel = useMemo(() => {
    const checkRender = (realm, component) => {
      if(!checkAccess(realm)) return <NoPermission />;
      return component;
    };

    switch (section) {
      case "location":
        return checkRender(permissionsLookup.dashboardLocation.realm, <LocationTab 
          customer={customer}
          dateFilters={{
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          }} />);
      case "device":
        return checkRender(permissionsLookup.dashboardStats.realm, <DeviceTab 
          customer={customer}
          barGraphOptions={barGraphOptions}
          dateFilters={{
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          }}
        />);
      default:
        return checkRender(permissionsLookup.dashboardService.realm, <ServiceTab 
          customer={customer}
          barGraphOptions={barGraphOptions}
          dateFilters={{
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          }}
        />);
    };
  }, [section, customer, startDate, endDate]);

  const exportCSV = () => {
    get(
      `/dashboard/export${buildQS({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        type: section,
      })}`
    ).then((data) => {
      var csv =
        Object.keys(data.results[0])
          .map((field) => {
            return `"${field}"`;
          })
          .join(",") + "\n";

      data.results.forEach((result) => {
        csv +=
          Object.keys(result)
            .map((field) => {
              return `"${result[field]}"`;
            })
            .join(",") + "\n";
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      downloadLink.target = "_blank";
      downloadLink.download = `${section}TabExport-${date.format(
        new Date(),
        "DDMMYYYY"
      )}.csv`;
      downloadLink.click();
    });
  };

  const handlePrint = () => {
    reportRef.current.handlePrint();
  };

  useEffect(() => {
    setTitle("Dashboard");
  }, [setTitle]);

  if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="grid grid-cols-4 gap-6 pb-6">
          <div className="col-span-4">
            <div>
              {checkAccess(permissionsLookup.dashboardService.realm) && (
                <button
                  onClick={() => navigate(`/${companyName}/statistics`)}
                  className={classNames(
                    section === undefined
                      ? "bg-ag-darkBlue text-white"
                      : "bg-white",
                    "p-3 mr-3 border border-b-0 rounded-t-md"
                  )}
                >
                  Service
                </button>
              )}
              {checkAccess(permissionsLookup.dashboardStats.realm) && (
                <button
                  onClick={() => navigate(`/${companyName}/statistics/device`)}
                  className={classNames(
                    section === "device"
                      ? "bg-ag-darkBlue text-white"
                      : "bg-white",
                    "p-3 mr-3 border border-b-0 rounded-t-md"
                  )}
                >
                  Device Statistics
                </button>
              )}
              {checkAccess(permissionsLookup.dashboardLocation.realm) && (
                <button
                  onClick={() =>
                    navigate(`/${companyName}/statistics/location`)
                  }
                  className={classNames(
                    section === "location"
                      ? "bg-ag-darkBlue text-white"
                      : "bg-white",
                    "p-3 mr-3 border border-b-0 rounded-t-md"
                  )}
                >
                  Device Location
                </button>
              )}
            </div>
            <div className="flex items-center justify-between py-4 px-6 bg-white shadow rounded-b-lg">
              <div>
                <div className="space-x-3">
                  <label>Show Data between</label>
                  <input
                    type="month"
                    value={moment(startDate).format("yyyy-MM")}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="border text-black bg-gray-50 border-gray-400 px-2 py-1 focus:border-ag-darkBlue"
                  />
                  <span>and</span>
                  <input
                    type="month"
                    value={moment(endDate).format("yyyy-MM")}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="border text-black bg-gray-50 border-gray-400 px-2 py-1 focus:border-ag-darkBlue"
                  />
                </div>
                {dateRangeError && (
                  <p role="alert" className="mt-2 text-red-600">
                    Please choose 12 months or less
                  </p>
                )}
              </div>
              <div className="flex items-center space-x-2">
                {actAs &&
                  !section &&
                  (generate ? (
                    reportLoading ? (
                      <div className="w-40">
                        <LoadingWheel className="w-8 h-8" />
                      </div>
                    ) : (
                      <button
                        onClick={handlePrint}
                        className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
                      >
                        Download PDF report
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        setReportLoading(true);
                        setGenerate(true);
                      }}
                      className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
                    >
                      Generate PDF report
                    </button>
                  ))}
                <button
                  className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
                  onClick={exportCSV}
                >
                  Detailed Export
                </button>
              </div>
            </div>
          </div>
          {!dateRangeError && getPanel}
          <div className="col-span-4" />
        </div>
        {generate && (
          <StatisticsReport
            dateFilters={{
              startDate: startDate.toISOString(),
              endDate: endDate.toISOString(),
            }}
            setLoading={setReportLoading}
            ref={reportRef}
          />
        )}
      </>
    );
  }
}

const Statistics = withCustomer(_Statistics);

export default Statistics;
