export default function TablePaginationControls({
  table,
  pageSizeOptions = [10, 20, 30, 40, 50],
}) {
  return (
    <>
      <div className="hidden md:flex mt-3 justify-end">
        <div className="flex items-center gap-2">
          <button
            type="button"
            className={`rounded px-2 py-1 text-white bg-ag-default hover:bg-ag-lightDefault ${
              !table.getCanPreviousPage() && "!bg-gray-200"
            }`}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"First"}
          </button>
          <button
            type="button"
            className={`rounded px-2 py-1 text-white bg-ag-default hover:bg-ag-lightDefault ${
              !table.getCanPreviousPage() && "!bg-gray-200"
            }`}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            type="button"
            className={`rounded px-2 py-1 text-white bg-ag-default hover:bg-ag-lightDefault ${
              !table.getCanNextPage() && "!bg-gray-200"
            }`}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            type="button"
            className={`rounded px-2 py-1 text-white bg-ag-default hover:bg-ag-lightDefault ${
              !table.getCanNextPage() && "!bg-gray-200"
            }`}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {"Last"}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount() === 0 ? 1 : table.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              min={1}
              max={table.getPageCount()}
              className="border p-1 rounded w-16"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {pageSizeOptions.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mt-2 grid grid-cols-4 md:hidden gap-2">
        <button
          type="button"
          className={`rounded px-2 py-1 text-white bg-ag-lightDefault hover:bg-ag-default ${
            !table.getCanPreviousPage() && "!bg-gray-200"
          }`}
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"First"}
        </button>
        <button
          type="button"
          className={`rounded px-2 py-1 text-white bg-ag-lightDefault hover:bg-ag-default ${
            !table.getCanPreviousPage() && "!bg-gray-200"
          }`}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          type="button"
          className={`rounded px-2 py-1 text-white bg-ag-lightDefault hover:bg-ag-default ${
            !table.getCanNextPage() && "!bg-gray-200"
          }`}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          type="button"
          className={`rounded px-2 py-1 text-white bg-ag-lightDefault hover:bg-ag-default ${
            !table.getCanNextPage() && "!bg-gray-200"
          }`}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {"Last"}
        </button>
        <div className="col-span-4 flex items-center justify-around">
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span>|</span>
          <span className="flex items-center gap-1">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border p-1 rounded w-16"
            />
          </span>
        </div>
        <div className="col-span-4 text-right">
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {pageSizeOptions.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
