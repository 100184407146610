import { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';

import { useServiceDashboard } from "../../data/useServiceDashboard";

import LoadingWheel from "../shared/LoadingWheel";

Chart.register(CategoryScale);

const CallTypeEnum = Object.freeze({
  0: "Breakdown",
  1: "Help Desk Call",
  2: "Courtesy Visit",
  3: "RTF",
});

function ServiceStatsTable({ data }) {
  const groupedData = data.reduce((acc, item) => {
    const { label, type, value, month, year } = item;

    const existingEntry = acc.find(
      (entry) =>
        entry.label === `${label} ${String(year).slice(-2)}` &&
        entry.year === year &&
        entry.month === month
    );

    if (existingEntry) {
      existingEntry[type] = value;
    } else {
      const newEntry = {
        label: `${label} ${String(year).slice(-2)}`,
        month,
        year,
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        [type]: value,
      };

      acc.push(newEntry);
    }

    return acc;
  }, []);

  const ServiceStatsTableRow = ({ stat, index }) => (
    <tr className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
      <td className="py-2 px-1 pl-5 font-bold">{stat.label}</td>
      <td className="py-2 px-1 text-center">{stat[0]}</td>
      <td className="py-2 px-1 text-center">{stat[1]}</td>
      <td className="py-2 px-1 text-center">{stat[2]}</td>
      <td className="py-2 px-1 text-center">{stat[3]}</td>
      <td className="py-2 px-1 text-center">
        {stat[0] + stat[1] + stat[2] + stat[3]}
      </td>
    </tr>
  );

  return (
    <table className="text-black w-full border">
      <thead>
        <tr className="border-b-2 border-gray-300">
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
          >
            Month
          </th>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell text-center"
          >
            {CallTypeEnum[0]}
          </th>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell text-center"
          >
            {CallTypeEnum[1]}
          </th>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell text-center"
          >
            {CallTypeEnum[2]}
          </th>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell text-center"
          >
            {CallTypeEnum[3]}
          </th>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell text-center"
          >
            Total Calls
          </th>
        </tr>
      </thead>
      <tbody>
        {groupedData.map((stat, i) => (
          <ServiceStatsTableRow key={i} stat={stat} index={i} />
        ))}
      </tbody>
    </table>
  );
}

export default function ServiceTab({ customer, dateFilters, barGraphOptions }) {
  const { calls } = useServiceDashboard(customer.id, dateFilters);

  useEffect(() => {}, []);

  const getLabels = (callType) => {
    return [
      ...calls.data.results.filter((s) => s.type === callType).map((s) => `${s.label}\n${s.year !== null && s.year !== undefined ? String(s.year).slice(2) : ''}`),
    ]
  }

  const getData = (callType) => {
    return [
      ...calls.data.results.filter((s) => s.type === callType).map((s) => s.value),
    ]
  }

  const getCourtesyCallData = () => ({
    labels: getLabels(2),
    datasets: [
      {
        label: `${CallTypeEnum[2]}s`,
        data: getData(2),
        borderColor: "rgb(9, 0, 65)",
        backgroundColor: "rgb(9, 0, 65)",
      },
    ],
  });

  const getHelpDeskCallData = () => ({
    labels: getLabels(1),
    datasets: [
      {
        label: `${CallTypeEnum[1]}s`,
        data: [
          ...calls.data.results.filter((s) => s.type === 1).map((s) => s.value),
        ],
        borderColor: "rgb(9, 0, 65)",
        backgroundColor: "rgb(9, 0, 65)",
      },
    ],
  });

  const getBreakdownCallsData = () => {
    let breakdownOnly = calls.data.results.filter((call) => call.type === 0);
    return {
      labels: getLabels(0),
      datasets: [
        {
          label: "Breakdowns",
          data: [...breakdownOnly.map((s) => s.value)],
          borderColor: "rgb(9, 0, 65)",
          backgroundColor: "rgb(9, 0, 65)",
        },
      ],
    };
  };

  const getRTFData = () => ({
    labels: getLabels(3),
    datasets: [
      {
        label: `${CallTypeEnum[3]}s`,
        data: [
          ...calls.data.results.filter((s) => s.type === 3).map((s) => s.value),
        ],
        borderColor: "rgb(9, 0, 65)",
        backgroundColor: "rgb(9, 0, 65)",
      },
    ],
  });

  return (
    <>
      <div className="col-span-4 md:col-span-2 bg-white shadow rounded-md p-5">
        <h1 className="mb-4 text-xl font-medium text-ag-default">
          Breakdowns Calls
        </h1>
        {!calls.isLoading && calls.data?.results ? (
          <Bar
            height={120}
            options={barGraphOptions}
            data={getBreakdownCallsData()}
          />
        ) : (
          <LoadingWheel />
        )}
      </div>

      <div className="col-span-4 md:col-span-2 bg-white shadow rounded-md p-5">
        <h1 className="mb-4 text-xl font-medium text-ag-default">
          Courtesy Visits
        </h1>
        {!calls.isLoading && calls.data?.results ? (
          <Bar
            height={120}
            options={barGraphOptions}
            data={getCourtesyCallData()}
          />
        ) : (
          <LoadingWheel />
        )}
      </div>

      <div className="col-span-4 md:col-span-2">
        <div className="grid grid-cols-1 gap-6">
          <div className="bg-white shadow rounded-md p-5">
            <h1 className="mb-4 text-xl font-medium text-ag-default">
              Help Desk Calls
            </h1>
            {!calls.isLoading && calls.data?.results ? (
              <Bar
                height={120}
                options={barGraphOptions}
                data={getHelpDeskCallData()}
              />
            ) : (
              <LoadingWheel />
            )}
          </div>

          <div className="bg-white shadow rounded-md p-5">
            <h1 className="mb-4 text-xl font-medium text-ag-default">
              RTF Calls
            </h1>
            {!calls.isLoading && calls.data?.results ? (
              <Bar height={120} options={barGraphOptions} data={getRTFData()} />
            ) : (
              <LoadingWheel />
            )}
          </div>
        </div>
      </div>

      <div className="col-span-4 md:col-span-2">
        <div className="bg-white shadow rounded-md p-5">
          <h1 className="mb-4 text-xl font-medium text-ag-default">
            Service Performance Statistics
          </h1>
          {!calls.isLoading && calls.data?.results ? (
            <ServiceStatsTable data={calls.data.results} />
          ) : (
            <LoadingWheel />
          )}
        </div>
      </div>
    </>
  );
}
