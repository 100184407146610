import { useNavigate, useParams } from "react-router";
import { withCustomer } from "../../data/withCustomer";

import FullscreenLoading from "../../components/shared/FullscreenLoading";

import Overview from "../../components/FleetManagement/Overview";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import LoadingWheel from "../../components/shared/LoadingWheel";

import classNames from "../../utils/classNames";
import Alerts from "../../components/FleetManagement/Alerts";
import MeterReadings from "../../components/FleetManagement/MeterReadings";
import Reports from "../../components/FleetManagement/Reports";
import Training from "../../components/FleetManagement/Training";
import usePageTitleContext from "../../contexts/PageTitleContext";
import { useEffect } from "react";
import { useKpax } from "../../data/useKpax";
import FleetManSignup from "../../components/FleetManagement/Signup";
import { permissionsLookup, useRoleCheck } from "../../hooks/useRoleCheck";
import { NoPermission } from "../../components/shared/NoPermission";

function _FleetManagement({ customerLoading, customer }) {
  const { checkAccess } = useRoleCheck();
  const { companyName, section } = useParams();
  const navigate = useNavigate();
  const { setTitle } = usePageTitleContext();
  const { devices } = useKpax(customer.id);

  const getPanel = () => {
    switch (section) {
      case "alerts":
        if (!checkAccess(permissionsLookup.kpaxAlerts.realm))
          return <NoPermission />;
        return <Alerts customer={customer} />;
      case "reports":
        if (!checkAccess(permissionsLookup.kpaxReports.realm))
          return <NoPermission />;
        return <Reports customer={customer} />;
      case "meter-readings":
        if (!checkAccess(permissionsLookup.kpaxReadings.realm))
          return <NoPermission />;
        return <MeterReadings customer={customer} />;
      default:
        if (!checkAccess(permissionsLookup.kpaxOverview.realm))
          return <NoPermission />;
        return <Overview customer={customer} />;
    }
  };

  const pages = [{ to: "", label: "Fleet Management", current: true }];

  useEffect(() => {
    setTitle("Fleet Management");
  }, [setTitle]);

  if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <nav className="">
          {checkAccess(permissionsLookup.kpaxOverview.realm) && (
            <button
              onClick={() => navigate(`/${companyName}/fleet-management`)}
              className={classNames(
                section === undefined
                  ? "bg-white"
                  : "bg-ag-darkBlue text-white",
                "p-3 mr-3 border border-b-0 rounded-t-md"
              )}
            >
              Overview
            </button>
          )}
          {checkAccess(permissionsLookup.kpaxAlerts.realm) && (
            <button
              onClick={() =>
                navigate(`/${companyName}/fleet-management/alerts`)
              }
              className={classNames(
                section === "alerts" ? "bg-white" : "bg-ag-darkBlue text-white",
                "p-3 mr-3 border border-b-0 rounded-t-md"
              )}
            >
              Alerts
            </button>
          )}
          {checkAccess(permissionsLookup.kpaxReports.realm) && (
            <button
              onClick={() =>
                navigate(`/${companyName}/fleet-management/reports`)
              }
              className={classNames(
                section === "reports"
                  ? "bg-white"
                  : "bg-ag-darkBlue text-white",
                "p-3 mr-3 border border-b-0 rounded-t-md"
              )}
            >
              Reports
            </button>
          )}
          {/* <button
            onClick={() =>
              navigate(`/${companyName}/fleet-management/training`)
            }
            className={classNames(
              section === "training" ? "bg-white" : "bg-ag-darkBlue text-white",
              "p-3 mr-3 border border-b-0 rounded-t-md"
            )}
          >
            Training
          </button> */}
          {checkAccess(permissionsLookup.kpaxReadings.realm) && (
            <button
              onClick={() =>
                navigate(`/${companyName}/fleet-management/meter-readings`)
              }
              className={classNames(
                section === "meter-readings"
                  ? "bg-white"
                  : "bg-ag-darkBlue text-white",
                "p-3 border border-b-0 rounded-t-md"
              )}
            >
              Meter Readings
            </button>
          )}
        </nav>
        <div className="p-8 bg-white -mt-px rounded-r-md shadow rounded-b-md">
          {devices.isLoading ? (
            <LoadingWheel />
          ) : devices.data.devices.length > 0 ? (
            getPanel()
          ) : (
            <FleetManSignup />
          )}
        </div>
      </div>
    );
  }
}

const FleetManagement = withCustomer(_FleetManagement);

export default FleetManagement;
