import Search from "./Search";
import Feed from "./Feed";
import useCurrentUserContext from "../../../contexts/UserContext";

export default function Sidebar() {
  const { currentUser: {user} } = useCurrentUserContext();

  return (
    <div className="text-ag-default">
      <div className="grid grid-cols-5 p-5 gap-6">
        <div className="col-span-4">
          <Search />
        </div>
      </div>
      <div className="px-4">
        <p className="text-xl font-medium">Hello,</p>
        <p className="text-3xl font-bold">{user && `${user.firstname}!`}</p>
        <p className="text-gray-400 text-lg">Welcome back to the Agilico Support Portal</p>

      </div>
      <div className="p-6">  <Feed /></div>

    </div>
  );
}
