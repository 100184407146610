import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/outline';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, forceRender: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    this.setState({ hasError: true });
  }

  handleBackClick = () => {
    const { navigate } = this.props;
    navigate("../");
    navigate(0);

    this.setState({ forceRender: true }, () => {
        this.setState({ forceRender: false });
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="p-8 bg-white rounded shadow flex flex-col items-center">
          <ExclamationTriangleIcon className="w-32 h-32 text-ag-darkBlue" />
          <h1 className="text-xl">We ran into a problem, please try again.</h1>
          <p>If the issue persists, please contact support</p>
          <button
            onClick={() => this.handleBackClick()}
            className="mt-5 p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
          >
            Take me back
          </button>
        </div>
      );
    }

    return children;
  }
}

export default function ErrorBoundaryWrapper({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ErrorBoundary navigate={navigate} location={location}>
      {children}
    </ErrorBoundary>
  );
}
