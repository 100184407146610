import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";

import TablePaginationControls from "../../components/shared/TablePaginationControls";
import YourContactDetails from "../../components/shared/YourContactDetails";
import SiteDropdown from "../../components/shared/SiteDropdown";
import LoadingWheel from "../../components/shared/LoadingWheel";

import { useEquipment } from "../../data/useEquipment";
import { useSupport } from "../../data/useSupport";

import useNotification from "../../hooks/useNotifications";

import useCurrentUserContext from "../../contexts/UserContext";

import { buildAddressString } from "../../utils/buildAddressString";

const softwareFaults = [
  "Slow print",
  "No Print - single device",
  "No print - all devices",
  "Can't release jobs - single user",
  "Can't release jobs - all users",
  "Scanning fault",
  "Email fault",
  "Printing fault",
  "Lost connection",
  "Device locked",
  "Authentication problem",
  "Printer offline",
  "Driver problem",
  "Prints disappear",
  "Can't print in colour",
  "Budget problem",
  "Card reader problem",
  "Booklet printing problem",
  "License problem",
  "Memory full",
  "Reports problem",
  "Password problem",
  "uniFLOW",
  "Papercut",
  "PaperRiver",
  "eCopy",
  "Copitrak",
  "Equitrac",
  "Safecom",
];

const hardwareFaults = [
  "Broken Part",
  "Creasing",
  "eCopy",
  "Error Code",
  "Jamming",
  "No Power/Dead",
  "Noise",
  "Poor Copy Quality",
  "Scanning",
  "Skewing",
  "Slow Printing",
  "Unable to Print",
  "Waste Toner Full",
];

const AddressCell = (row) => {
  const { currentUser } = useCurrentUserContext();
  let site = currentUser.user.sites.find((s) => s.id === row.customerId);
  if (site) {
    return buildAddressString(
      site.address1,
      site.address2,
      site.town
    );
  } else {
    return buildAddressString(row.address1, row.address2, row.town);
  }
};

function LocationUpdateCell({
  info,
  requestUpdateLocation,
  setEditLocationDeviceId,
}) {
  const { addNotification } = useNotification();
  const { currentUser } = useCurrentUserContext();
  const [value, setValue] = useState("");

  const data = info.row.original;

  const UpdateDeviceLocation = () => {
    let address;

    let site = currentUser.user.sites.find((s) => s.id === data.customerId);

    if (!site) {
      address = buildAddressString(data.address1, data.address2);
    }

    requestUpdateLocation.mutate(
      {
        yourName: `${currentUser.user.firstname} ${currentUser.user.lastname}`,
        companyName: site ? site.name : `${currentUser.user.firstname} ${currentUser.user.lastname}`,
        emailAddress: `${currentUser.user.emailAddress}`,
        contactNumber: currentUser.user.telephone,
        deviceId: data.id,
        location: value,
        addressLine1: site ? site.address1 : address,
        addressLine2: site ? site.address2 : '',
        addressLine3: site ? site.address3 : '',
        town: site ? site.town : '',
        postcode: site ? site.postCode : '',
        equipment: data,
        address: address,
      },
      {
        onSuccess: () => {
          addNotification({
            variant: "success",
            primaryText: "Change Location Request Submitted",
            secondaryText:
              "Thank you for updating the location. This request has been sent to Service and may take 24 hours to show in the portal.",
          });
          setEditLocationDeviceId(0);
        },
      }
    );
  };

  return (
    <div>
      <div className="mb-1 text-xs w-48">Location Changes</div>
      <div className="flex items-center">
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
        />
        <CheckIcon
          onClick={UpdateDeviceLocation}
          className="w-5 h-5 ml-2 text-gray-400 hover:cursor-pointer hover:text-gray-600"
        />
        <XMarkIcon
          onClick={() => setEditLocationDeviceId(0)}
          className="w-5 h-5 ml-2 text-gray-400 hover:cursor-pointer hover:text-gray-600"
        />
      </div>
    </div>
  );
}

function hasTicket(info) {
  if(info.row.original.jobsOpen > 0 || info.row.original.requestsOpen) return true;
  return false;
}

function EquipmentTable({
  data,
  selectedDevice,
  setSelectedDevice,
  setFilterSite,
}) {
  const { currentUser } = useCurrentUserContext();
  const { requestUpdateLocation } = useSupport(currentUser.customer.id);
  const [searchTerm, setSearchTerm] = useState("");
  const [editLocationDeviceId, setEditLocationDeviceId] = useState(0);

  const cols = [
    {
      id: "select",
      header: "",
      cell: (info) => (
        <div className="flex items-center justify-center">
          {info.row.original.serialNumber === selectedDevice ? (
            <button type="button" onClick={() => setSelectedDevice("")}>
              <CheckIcon className="w-8 h-8" />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setSelectedDevice(info.row.original.serialNumber)}
              className={`p-2 px-4 rounded-full text-white hover:bg-ag-lightDefault ${hasTicket(info)?'bg-ag-gray':'bg-ag-default'}`}
              disabled={hasTicket(info)}
            >
              Select
            </button>
          )}
        </div>
      ),
    },
    {
      accessorKey: "serialNumber",
      header: "Serial",
      cell: (info) => (
        <div className="">
          {info.getValue()}
          {hasTicket(info) && (
            <p className="text-red-600">
              {`1 Ticket Open`}
            </p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "description",
      header: "Device Name",
      cell: (info) => (
        <div className="space-y-2">
          <p>{info.getValue()}</p>
        </div>
      ),
    },
    {
      accessorKey: "name",
      header: "Company Name",
    },
    {
      id: "Address",
      accessorFn: (row) => AddressCell(row),
      cell: (info) => <span>{info.getValue()}</span>,
    },
    {
      accessorKey: "location",
      header: "Onsite Location",
      cell: (info) => {
        if (info.row.original.serialNumber === editLocationDeviceId) {
          return (
            <LocationUpdateCell
              setEditLocationDeviceId={setEditLocationDeviceId}
              requestUpdateLocation={requestUpdateLocation}
              info={info}
            />
          );
        } else {
          return (
            <div className="flex items-center">
              <p>{info.getValue()}</p>
              <PencilIcon
                onClick={() =>
                  setEditLocationDeviceId(info.row.original.serialNumber)
                }
                className="w-5 h-5 ml-2 text-gray-400 hover:cursor-pointer hover:text-gray-600"
                title="Edit Onsite Location"
              />
            </div>
          );
        }
      },
    },
    {
      accessorKey: "assetNumber",
      header: "Asset Number",
    },
  ];

  const table = useReactTable({
    data: data,
    columns: cols,
    filterFns: {
      fuzzy: basicIncludesFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: basicIncludesFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="relative mb-4 md:my-4">
        <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
          <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
            <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              placeholder="Search"
            />
          </div>
          <SiteDropdown
            onChange={(e) => setFilterSite(e.target.value)}
            className="w-full md:w-1/3"
          />
        </div>
      </div>
      <div className="block overflow-y-hidden overflow-x-auto">
        <table className="min-w-full divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                {headerGroup.headers.map((header, hi) => (
                  <th
                    key={header.id}
                    scope="col"
                    width={hi === 4 ? "20%" : "auto"}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, ri) => {
              let rowCells = row.getVisibleCells();
              return (
                <tr
                  key={row.id}
                  className={`${ri % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                >
                  {rowCells.map((cell, ci) => (
                    <td
                      key={cell.id}
                      className={`px-3 py-4 text-sm ${
                        ci === 1 ? "pl-5 font-bold" : "font-medium"
                      } ${
                        ci !== 0 && ci !== 1 ? "align-text-top" : ""
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePaginationControls table={table} />
    </>
  );
}

export default function NewSupportTicket({ customer }) {
  const { addNotification } = useNotification();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { equipment: allEquipment } = useEquipment(customer.id, {
    includeJobs: true,
  });
  const { requestSupport } = useSupport(customer.id);
  const [filterSite, setFilterSite] = useState("*");
  const [equipment, setEquipment] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedDeviceError, setSelectedDeviceError] = useState(undefined);

  const getFaultDescriptionOptions = (faultType) => {
    if (faultType === "1") {
      setValue("FaultTypeDescription", hardwareFaults[0]);
      return (
        <select
          {...register("FaultTypeDescription")}
          className="mt-2 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
        >
          {hardwareFaults.map((hf, i) => (
            <option value={hf} key={`hf-${i}`}>
              {hf}
            </option>
          ))}
        </select>
      );
    } else if (faultType === "2") {
      setValue("FaultTypeDescription", softwareFaults[0]);
      return (
        <select
          {...register("FaultTypeDescription")}
          className="mt-2 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
        >
          {softwareFaults.map((sf, i) => (
            <option value={sf} key={`sf-${i}`}>
              {sf}
            </option>
          ))}
        </select>
      );
    } else if (faultType === "0") {
      setValue("FaultTypeDescription", "");
      return (
        <>
          <input
            type="text"
            {...register("FaultTypeDescription", {
              required: "Please enter a fault type",
            })}
            aria-invalid={errors.FaultTypeDescription ? "true" : "false"}
            className="mt-2 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
          />
          {errors.FaultTypeDescription && (
            <p role="alert" className="text-red-600">
              {errors.FaultTypeDescription?.message}
            </p>
          )}
        </>
      );
    }
  };

  const onSubmit = (data) => {
    if (selectedDevice === "") {
      setSelectedDeviceError("Please select a device this issue relates to");
      return;
    }

    let equipment = allEquipment.data.equipment.find(
      (e) => e.serialNumber === selectedDevice
    );

    if (!equipment || equipment.jobsOpen > 0) {
      setSelectedDeviceError(
        "Please select a device that does not already have a job open"
      );
      return;
    }

    let formData = new FormData();
    formData.append("YourName", data.YourName);
    formData.append("EmailAddress", data.EmailAddress);
    formData.append("ContactNumber", data.ContactNumber);
    formData.append("CompanyName", data.CompanyName);
    formData.append("FaultType", parseInt(data.FaultType));
    formData.append("Department", data.Department);
    formData.append("ReferenceNo", data.ReferenceNo);
    formData.append("EndUser", data.EndUser);
    formData.append("FaultDescription", data.FaultDescription);
    formData.append("BestContactNumber", data.BestContactNumber);
    formData.append("BestContactTime", data.BestContactTime);
    formData.append("FaultTypeDescription", data.FaultTypeDescription);
    formData.append("DeviceModel", equipment.description);
    formData.append("AssetNo", equipment.assetNumber);
    formData.append("Location", equipment.location);
    formData.append("DeviceSerial", selectedDevice);

    for (const file of data.attachments) {
      formData.append("files", file);
    }

    formData.delete("site");
    formData.delete("attachments");

    requestSupport.mutate(formData, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Support Request Submitted",
          secondaryText: "Your support request has been submitted to the team",
        });
        setSelectedDevice("");
        reset();
      },
      onError: (error) => {
        addNotification({
          variant: "error",
          primaryText: "An error occurred",
          secondaryText: error.message,
        });
      },
    });
  };

  useEffect(() => {
    if (filterSite !== "*" && !allEquipment.isLoading) {
      setEquipment(
        allEquipment.data.equipment.filter(
          (e) => e.customerId === parseInt(filterSite)
        )
      );
    } else if (!allEquipment.isLoading) {
      setEquipment(allEquipment.data.equipment);
    }
  }, [filterSite, allEquipment.data, allEquipment.isLoading]);

  //Reset site filter if customer changes
  useEffect(() => {
    setFilterSite("*");
  }, [customer.id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <YourContactDetails register={register} reset={reset} errors={errors} />

      <div className="mt-10 flex justify-between">
        <h1 className="text-xl font-medium text-ag-default">Device Affected</h1>
      </div>

      <div className="mt-5">
        {allEquipment.isLoading ? (
          <LoadingWheel />
        ) : (
          <>
            <EquipmentTable
              data={equipment.sort((a, b) => {
                const accountNumberA = a.accountNumber;
                const accountNumberB = b.accountNumber;

                if (accountNumberA < accountNumberB) {
                  return -1;
                }
                if (accountNumberA > accountNumberB) {
                  return 1;
                }
                return 0;
              })}
              customer={customer}
              selectedDevice={selectedDevice}
              setSelectedDevice={setSelectedDevice}
              setFilterSite={setFilterSite}
            />
            {selectedDeviceError && (
              <p role="alert" className="text-red-600">
                {selectedDeviceError}
              </p>
            )}
          </>
        )}
      </div>

      <div className="mt-6">
        <h1 className="text-xl font-medium text-ag-default">Fault Details</h1>

        <div className="mt-5 grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-ag-default">
              Your Reference No.
            </label>
            <input
              type="text"
              {...register("ReferenceNo")}
              className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-ag-default">
              Fault Type
            </label>
            <select
              {...register("FaultType")}
              className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
            >
              <option value={1}>Hardware</option>
              <option value={2}>Software</option>
              <option value={0}>Other</option>
            </select>
            {getFaultDescriptionOptions(watch("FaultType"))}
          </div>
        </div>

        <div className="mt-4 w-full flex flex-col">
          <label className="mb-1 font-medium text-ag-default">
            Fault Description
          </label>
          <textarea
            {...register("FaultDescription", {
              required: "Please enter a fault description",
            })}
            aria-invalid={errors.FaultDescription ? "true" : "false"}
            className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
              errors.FaultDescription && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
            }`}
            rows={4}
          />
          {errors.FaultDescription && (
            <p role="alert" className="text-red-600">
              {errors.FaultDescription?.message}
            </p>
          )}
        </div>

        <div className="mt-5 grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-ag-default">
              End User who has the Fault
            </label>
            <input
              type="text"
              {...register("EndUser", {
                required: "Please enter a name with 3 or more characters",
                minLength: {
                  value: 3,
                  message: "Please enter a valid name with 3 or more characters",
                },
              })}
              aria-invalid={errors.EndUser ? "true" : "false"}
              className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                errors.EndUser && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
              }`}
            />
            {errors.EndUser && (
              <p role="alert" className="text-red-600">
                {errors.EndUser?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-ag-default">
              Best Contact No.
            </label>
            <input
              type="text"
              {...register("BestContactNumber", {
                required: "Please enter a number",
                minLength: {
                  value: 3,
                  message: "Please enter a valid number",
                },
              })}
              aria-invalid={errors.BestContactNumber ? "true" : "false"}
              className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                errors.BestContactNumber &&
                "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
              }`}
            />
            {errors.BestContactNumber && (
              <p role="alert" className="text-red-600">
                {errors.BestContactNumber?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col">
            <label className="mb-1 font-medium text-ag-default">
              Best Contact Time
            </label>
            <input
              type="text"
              {...register("BestContactTime", {
                required: "Please enter a time",
              })}
              aria-invalid={errors.BestContactTime ? "true" : "false"}
              className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                errors.BestContactTime && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
              }`}
            />
            {errors.BestContactTime && (
              <p role="alert" className="text-red-600">
                {errors.BestContactTime?.message}
              </p>
            )}
          </div>
        </div>
        <div className="mt-5 flex flex-col w-2/3">
          <label className="mb-1 font-medium text-ag-default">
            Attachments
          </label>
          <input
            type="file"
            {...register("attachments")}
            className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
            accept="image/png, image/jpeg, image/gif, image/tiff, application/pdf"
          />
        </div>
      </div>

      <div className="mt-10 mb-5">
        <button
          type="submit"
          className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
        >
          Submit Request
        </button>
      </div>
    </form>
  );
}
