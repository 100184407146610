import { useQuery } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useCurrentUserContext from "../contexts/UserContext";

export function useContent(customerId) {
  const { currentUser: { actAs } } = useCurrentUserContext();
  const { get } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs  },
  });

  const content = useQuery({
    queryKey: ["content", customerId],
    queryFn: () => get("/content"),
  });

  return {
    content,
  };
}
