import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { withCustomer } from "../../data/withCustomer";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import { useSupport } from "../../data/useSupport";
import useNotification from "../../hooks/useNotifications";
import usePageTitleContext from "../../contexts/PageTitleContext";
import YourContactDetails from "../../components/shared/YourContactDetails";
import { permissionsLookup, useRoleCheck } from "../../hooks/useRoleCheck";
import { NoPermission } from "../../components/shared/NoPermission";

const requestTypes = [
  { value: 0, label: "Account Query" },
  { value: 1, label: "Copy of an Invoice" },
  { value: 2, label: "Copy of a Statement" },
  { value: 3, label: "Account Manager Visit" },
  { value: 4, label: "Training Visit" },
  { value: 5, label: "Service Invoice Query" },
  { value: 6, label: "Non-Contract Device Call" },
  { value: 7, label: "Service Call Status" },
  { value: 10, label: "Toner Query" },
  { value: 21, label: "Portal Query" },
].sort((a, b) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
});

function _AskSomethingElse({ customerLoading, customer }) {
  const { checkAccess } = useRoleCheck();
  const { addNotification } = useNotification();
  const { customerRequest } = useSupport(customer.id);
  const { setTitle } = usePageTitleContext();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const pages = [
    { to: "..", label: "Support", current: false },
    { to: "", label: "Ask Something Else", current: true },
  ];

  const onSubmit = (data) => {
    customerRequest.mutate(
      {
        ...data,
        RequestTypeString: requestTypes.find(
          (type) => type.value === parseInt(data.RequestType)
        ).label,
      },
      {
        onSuccess: () => {
          addNotification({
            variant: "success",
            primaryText: "Request Submitted",
            secondaryText: "Your request has been submitted to the team",
          });
          reset();
        },
      }
    );
  };

  useEffect(() => {
    setTitle("Ask Something Else");
  }, [setTitle]);

  if (!checkAccess(permissionsLookup.askSomethingElse.realm)) {
    return <NoPermission />;
  } else if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="bg-white rounded-md shadow p-8">
          <form onSubmit={handleSubmit(onSubmit)} className="text-gray-500">
            <h1 className="text-xl font-medium text-ag-default mb-6">
              Ask Something Else
            </h1>

            <YourContactDetails
              title={false}
              register={register}
              reset={reset}
              errors={errors}
            />

            <div className="mt-10 pr-4 w-1/3 flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                Request Type
              </label>
              <select
                {...register("RequestType", { value: 0 })}
                className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              >
                {requestTypes.map((rt) => (
                  <option value={rt.value}>{rt.label}</option>
                ))}
              </select>
            </div>

            <div className="mt-4 w-full flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                Request
              </label>
              <textarea
                {...register("Request", {
                  required: "Please enter a request",
                  minLength: {
                    value: 3,
                    message: "Please enter a valid request with 3 or more characters",
                  },
                })}
                className="border text-black bg-gray-50 border-gray-400 p-2"
                rows={5}
              />
              {errors.Request && (
                <p role="alert" className="text-red-600">
                  {errors.Request?.message}
                </p>
              )}
            </div>

            <div className="mt-8">
              <button
                type="submit"
                className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
              >
                Submit Request
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

const AskSomethingElse = withCustomer(_AskSomethingElse);

export default AskSomethingElse;
