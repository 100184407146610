import { useEffect } from "react";
import { Link } from "react-router-dom";
import useCurrentUserContext from "../../contexts/UserContext";

export default function YourContactDetails({
  register,
  reset,
  errors,
  title = true,
}) {
  const { currentUser } = useCurrentUserContext();

  //Autofill user info
  useEffect(() => {
    if (!currentUser.loading && currentUser.user) {
      reset({
        YourName: `${currentUser.user.firstname} ${currentUser.user.lastname}`,
        EmailAddress: currentUser.user.emailAddress,
        CompanyName: currentUser.user.customer.name,
        Department: currentUser.user.department,
        ContactNumber: currentUser.user.telephone,
      });
    }
  }, [currentUser.loading, currentUser.user, reset]);

  return (
    <>
      {title && (
        <h1 className="text-xl font-medium text-ag-default">
          Your Contact Details
        </h1>
      )}
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">Your Name</label>
          <input
            type="text"
            {...register("YourName", {
              required: "Please enter your name",
              minLength: {
                value: 2,
                message: "Please enter a valid name with 2 or more characters",
              },
            })}
            aria-invalid={errors.YourName ? "true" : "false"}
            className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
              errors.YourName && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
            }`}
          />
          {errors.YourName && (
            <p role="alert" className="text-red-600">
              {errors.YourName?.message}
            </p>
          )}
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">Your Email</label>
          <input
            type="text"
            {...register("EmailAddress", {
              required: "Please enter your email address",
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            aria-invalid={errors.EmailAddress ? "true" : "false"}
            className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
              errors.EmailAddress && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
            }`}
          />
          {errors.EmailAddress && (
            <p role="alert" className="text-red-600">
              {errors.EmailAddress?.message}
            </p>
          )}
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">
            Contact Number
          </label>
          <input
            type="tel"
            {...register("ContactNumber", {
              required: "Please enter a contact number",
              pattern: {
                value: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
                message: "Please enter a valid contact number",
            },
            })}
            aria-invalid={errors.ContactNumber ? "true" : "false"}
            className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
              errors.ContactNumber && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
            }`}
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">
            Company Name
          </label>
          <input
            type="text"
            {...register("CompanyName", {
              required: "Please enter a company name",
              minLength: {
                value: 3,
                message: "Please enter a valid company name with 3 or more characters",
              },
            })}
            aria-invalid={errors.CompanyName ? "true" : "false"}
            className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
              errors.CompanyName && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
            }`}
          />
          {errors.CompanyName && (
            <p role="alert" className="text-red-600">
              {errors.CompanyName?.message}
            </p>
          )}
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">Department</label>
          <input
            type="text"
            {...register("Department")}
            className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
          />
        </div>
        <div className="flex items-end">
          <Link
            to={`/${currentUser.customer.reference.toLowerCase()}/settings`}
            className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
          >
            Update Details
          </Link>
        </div>
      </div>
    </>
  );
}
