import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import useCurrentUserContext, {
  UserReducerActions,
} from "../contexts/UserContext";


export function withCustomer(Wrapped) {
  return function (props) {
    const navigate = useNavigate();
    const {
      currentUser: { loading, customer, user },
      dispatch,
    } = useCurrentUserContext();
    const { companyName: customerRef } = useParams();
    const [customerLoading, setCustomerLoading] = useState(true);

    const findCustomer = () => {
      if (
        !loading &&
        user &&
        user.customers !== null &&
        Array.isArray(user.customers)
      ) {
        return user.customers.find(
          (c) => c.reference.toLowerCase() === customerRef
        );
      } else {
        return undefined;
      }
    };

    useEffect(() => {
      if (!loading && user) {
        if (!customerRef) {
          navigate(`/${user.customer.reference.toLowerCase()}`);
        } else if (!customer || customer.reference !== !customerRef) {
          const foundCustomer = findCustomer();
          dispatch({
            type: UserReducerActions.switchCustomer,
            payload: foundCustomer,
          });
          setCustomerLoading(false);
        }
      }
    }, [loading, customer, customerRef, user]);

    return (
      <Wrapped {...props} customerLoading={customerLoading} customer={customer} />
    );
  };
}
