import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip }  from "react-tooltip";

export default function InfoTooltip({ text, toolTip }) {

    const infoTooltipStyle = {
        backgroundColor: 'rgb(0, 1, 50)',
        overflowWrap: 'break-word',
        maxWidth: '100%'
    };

    return (
        <span className="flex flex-row items-center gap-1">
            {text}
            {text !== 'N/A' && toolTip &&
            <>
                <span data-tooltip-id="info-tooltip" data-tooltip-content={toolTip} data-tooltip-place="top">
                    <InformationCircleIcon className="w-5 h-5" />
                </span>
                <Tooltip id="info-tooltip" style={infoTooltipStyle} multiline={true} />
            </>
            }
        </span>
    )
}
