import { useQuery, useMutation } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useQSBuilder from "../hooks/useQSBuilder";
import useCurrentUserContext from "../contexts/UserContext";

export function useSupport(
  customerId,
  queryParams = {
    countOnly: false,
    daysSinceToday: 365,
    onlyClosed: false,
    onlyOpen: false,
    incomplete: false,
  }
) {
  const {
    currentUser: { actAs },
  } = useCurrentUserContext();
  const { get, post } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
  });
  const { post: postFormData } = useApiHelper({
    headers: { "Content-Type": null },
  });
  const { buildQS } = useQSBuilder();

  const supportTickets = useQuery({
    queryKey: ["support", customerId, queryParams],
    queryFn: () => get(`/support/work${buildQS(queryParams)}`),
  });

  const requestSupport = useMutation((supportRequest) =>
    postFormData("/support/request", supportRequest)
  );

  const cancelRequest = useMutation((cancelRequest) =>
    post("/support/cancel", JSON.stringify(cancelRequest))
  );

  const requestBoxes = useMutation((boxRequest) =>
    post("/support/recycling", JSON.stringify(boxRequest))
  );

  const requestMove = useMutation((moveRequest) =>
    post("/support/move", JSON.stringify(moveRequest))
  );

  const requestUpdateLocation = useMutation((locationRequest) =>
    post("/support/location", JSON.stringify(locationRequest))
  );

  const requestSuggestion = useMutation((suggestionRequest) =>
    post("/support/suggestion", JSON.stringify(suggestionRequest))
  );

  const customerRequest = useMutation((request) =>
    post("/support/otherrequest", JSON.stringify(request))
  );

  return {
    supportTickets,
    requestSupport,
    cancelRequest,
    requestBoxes,
    requestMove,
    requestUpdateLocation,
    requestSuggestion,
    customerRequest,
  };
}
