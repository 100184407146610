import { useState, useMemo } from "react";
import date from "date-and-time";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import {
  MagnifyingGlassIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import TablePaginationControls from "../shared/TablePaginationControls";

import ViewSelect from "../shared/ViewSelect";
import SiteDropdown from "../shared/SiteDropdown";

import useCurrentUserContext from "../../contexts/UserContext";

import { buildAddressString } from "../../utils/buildAddressString";
import InfoTooltip from "../shared/InfoTooltip";

const AddressCell = (info, equipment) => {
  const { currentUser } = useCurrentUserContext();
  let device = equipment.find(
    (eq) => eq.serialNumber === info.row.original.serialNumber
  );

  if(!device) {
    return <span className="text-gray-600 italic">Machine decommissioned</span>;;
  }

  let site = currentUser.user.sites.find((s) => s.id === device.customerId);

  if (site) {
    return buildAddressString(
      site.address1,
      site.address2,
      site.address3,
      site.town,
      site.postcode
    );
  } else if (device) {
    return buildAddressString(
      device.address1,
      device.address2,
      device.address3,
      device.town,
      device.postcode
    );
  } else {
    return "";
  }
};

const LocationCell = (info, equipment) => {
  let device = equipment.find(
    (eq) => eq.serialNumber === info.row.original.serialNumber
  );

  if(!device) {
    return <span className="text-gray-600 italic">Machine decommissioned</span>;
  } else {
    return info.row.original.location
  }
}

const getTicketTypeDescription = (ticketType) => {
  switch(ticketType) {
    case "Breakdowns":
      return "This is a standard definition for when a ticket is logged due to a fault with a device. The Fault could be a jam, error code, line on the page or software issue and these are responded to within your agreed SLA’s.";
    case "Courtesy Visits":
      return "A visit that's not usually requested by you e.g. if an engineer is already in your area, they might stop by to carry out preventative maintenance to reduce the likelihood of breakdowns.";
    case "Help Desk Calls":
      return "Help desk calls relate to issues with your P.C. or office technology such as software or internet. Generally we can fix these issues remotely, without needing to visit site.";
    case "RTF":
      return "(Return to Fit) The job has been extended as additional parts are required, the engineer will return to fit the additional parts.";
    case "Site Visit":
      return "This is when we've scheduled a visit to site e.g. to check on something or as a courtesy visit. It doesn't have a specific time we need to respond by.";
    default:
      return "";
  }
}

export default function TicketTable({
  data,
  cardView,
  setCardView,
  onCancelClick,
  equipment,
  open = false,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSite, setFilterSite] = useState("*");

  const filteredData = useMemo(() => {
    if (filterSite === "*") {
      return data;
    } else {
      return data.filter(row => row.customerId.toString() === filterSite);
    }
  }, [data, filterSite]);

  const sharedCols = [
    {
      accessorKey: "reference",
      header: "Ticket No.",
    },
    {
      accessorKey: "category",
      header: "Ticket Type",
      cell: (info) => <InfoTooltip text={info.row.original.category} toolTip={getTicketTypeDescription(info.row.original.category)} />
    },
    {
      accessorKey: "serialNumber",
      header: "Serial",
    },
    {
      accessorKey: "name",
      header: "Company Name",
    },
    {
      id: "address",
      header: "Address",
      cell: (info) => AddressCell(info, equipment),
    },
    {
      id: "location",
      header: "Onsite Location",
      cell: (info) => LocationCell(info, equipment),
    },
  ];

  const openTicketCols = [
    ...sharedCols,
    {
      accessorKey: "engineerStatusText",
      header: "Ticket Status",
    },
    {
      accessorKey: "loggedDate",
      header: "Opened On",
      cell: (info) => (
        <span>
          {date
            .format(new Date(info.getValue()), "DD/MM/YY HH:mm")
            .replace(" ", "\xA0\xA0\xA0")}
        </span>
      ),
    },
    {
      id: "actions",
      cell: (info) => (
        <div className="flex items-center">
          {info.row.original.completedDate === null && (
            <button
              type="button"
              onClick={() => onCancelClick(info.row.original)}
              className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
            >
              Cancel
            </button>
          )}
        </div>
      ),
    }
  ]

  const closedTicketCols = [
    ...sharedCols,
    {
      accessorKey: "issue",
      header: "Issue",
    },
    {
      accessorKey: "actionTaken",
      header: "Action Taken",
    },
    {
      accessorKey: "completedDate",
      header: "Closed On",
      cell: (info) => (
        <span>
          {date
            .format(new Date(info.getValue()), "DD/MM/YY HH:mm")
            .replace(" ", "\xA0\xA0\xA0")}
        </span>
      ),
    },
  ]
  

  const table = useReactTable({
    data: filteredData,
    columns: open ? openTicketCols : closedTicketCols,
    filterFns: {
      fuzzy: basicIncludesFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: basicIncludesFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="relative mb-4">
        <div className="w-full grid grid-cols-1 md:pb-4 md:flex md:items-center md:justify-between">
          <div className="w-full -mt-5 mb-4 md:mb-0 md:w-96">
            <div className="pointer-events-none relative inset-y-8 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              placeholder="Search"
            />
          </div>
          <div className="md:space-x-2 md:flex md:items-center w-7/8 justify-end">
            <SiteDropdown onChange={(e) => setFilterSite(e.target.value)} className="w-full md:w-1/2 mb-4 md:mb-0" />
            <ViewSelect value={cardView} onChange={setCardView} className="w-full md:w-1/2 mb-4 md:mb-0" />
          </div>
        </div>
        {open && (
          <div className="rounded-md bg-red-50 my-4 p-4 w-full">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-red-700">
                  Support tickets will show here once they have been processed
                  by our Customer Service Team.
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="block overflow-y-hidden overflow-x-auto">
          <table className="text-black min-w-full divide-y border divide-gray-300">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, ri) => {
                let rowCells = row.getVisibleCells();
                return (
                  <tr
                    key={row.id}
                    className={`${ri % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                  >
                    {rowCells.map((cell, ci) => (
                      <td
                        key={cell.id}
                        width={ci === 0 ? "10%" : "auto"}
                        className={`px-3 py-4 text-sm align-text-top ${
                          ci === 2 ? "pl-5 font-bold" : "font-medium"
                        }`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <TablePaginationControls table={table} />
    </>
  );
}
