import { createContext, useContext, useState } from "react";;

const initState = "Welcome Back!";

const PageTitleContext = createContext();

export function PageTitleContextProvider({ children }) {
  const [title, setTitle] = useState(initState);

  return (
    <PageTitleContext.Provider value={{ title, setTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
}

export default function usePageTitleContext() {
  const ctx = useContext(PageTitleContext);

  if (!ctx) {
    throw new Error(
      "useCurrentUserContext must be called inside a UserContextProvider"
    );
  }

  return ctx;
}
