import { useState, useMemo } from "react";
import date from "date-and-time";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import TablePaginationControls from "../shared/TablePaginationControls";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import LoadingWheel from "../shared/LoadingWheel";
import SiteDropdown from "../shared/SiteDropdown";

import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import { useKpax } from "../../data/useKpax";
import { isNotToday } from "../../utils/isNotToday";

function ReadingsTable({ data, count }) {

  const [searchTerm, setSearchTerm] = useState("");
  const [filterSite, setFilterSite] = useState("*");

  const filteredData = useMemo(() => {

    if (filterSite === "*") {
      return data;
    } else {
      return data.filter(row => row.customerId.toString() === filterSite);
    }
  }, [data, filterSite]);

  const ReadingCell = (info) => {
    let value = info.getValue();

    if (!value || value === "0") {
      return <div className="text-gray-400">N/A</div>;
    } else {
      return value.toLocaleString();
    }
  };

  const cols = [
    {
      accessorKey: "lastCheck",
      header: "Last Reading",
      cell: (info) => {
        return (
          <span className={isNotToday(info.getValue()) ? "text-red-600" : ""}>
            {date.format(new Date(info.getValue()), "DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      accessorKey: "serialNumber",
      header: "Serial",
    },
    {
      accessorKey: "model",
      header: "Device",
    },
    {
      accessorKey: "hostName",
      header: "Host Name",
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon101;
        } else {
          return "0";
        }
      },
      header: "101",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon103;
        } else {
          return "0";
        }
      },
      header: "103",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon105;
        } else {
          return "0";
        }
      },
      header: "105",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon106;
        } else {
          return "0";
        }
      },
      header: "106",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon108;
        } else {
          return "0";
        }
      },
      header: "108",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon109;
        } else {
          return "0";
        }
      },
      header: "109",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon112;
        } else {
          return "0";
        }
      },
      header: "112",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon113;
        } else {
          return "0";
        }
      },
      header: "113",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon112;
        } else {
          return "0";
        }
      },
      header: "122",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon123;
        } else {
          return "0";
        }
      },
      header: "123",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.canon124;
        } else {
          return "0";
        }
      },
      header: "124",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.engineCount;
        } else {
          return "0";
        }
      },
      header: "Total",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.totalMono;
        } else {
          return "0";
        }
      },
      header: "Mono",
      filterable: false,
      cell: ReadingCell,
    },
    {
      accessorFn: (row) => {
        if (row.meters) {
          return row.meters.totalColor;
        } else {
          return "0";
        }
      },
      header: "Colour",
      filterable: false,
      cell: ReadingCell,
    },
  ];

  const table = useReactTable({
    data: filteredData,
    columns: cols,
    filterFns: {
      fuzzy: basicIncludesFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: basicIncludesFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="text-ag-default text-right font-medium">Total Devices: {count}</div>
      <div className="relative my-4">
        <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
          <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
            <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              placeholder="Search"
            />
          </div>
          <SiteDropdown
            onChange={(e) => setFilterSite(e.target.value)}
            className="w-full md:w-1/4"
          />
        </div>
      </div>
      <div className="block overflow-y-hidden overflow-x-auto">
        <table className="text-black min-w-full divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, ri) => {
              let rowCells = row.getVisibleCells();
              return (
                <tr
                  key={row.id}
                  className={`${ri % 2 == 0 ? "bg-gray-100" : "bg-white"}`}
                >
                  {rowCells.map((cell) => (
                    <td key={cell.id} className="px-3 py-4 text-sm font-medium">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePaginationControls table={table} />
    </>
  );
}

export default function MeterReadings({ customer }) {
  const { devices } = useKpax(customer.id);

  const exportCSV = () => {
    let csv =
      "Device,Serial,101,103,105,106,108,109,112,113,122,123,124,Total,Mono,Colour,Last Reading\n";

    devices.data.devices.forEach((device) => {
      var reading = undefined;
      if (device.meters) reading = device.meters;
      csv += device.model + "," + device.serialNumber + ",";
      csv += (reading && reading.canon101 ? reading.canon101 : "") + ",";
      csv += (reading && reading.canon103 ? reading.canon103 : "") + ",";
      csv += (reading && reading.canon105 ? reading.canon105 : "") + ",";
      csv += (reading && reading.canon106 ? reading.canon106 : "") + ",";
      csv += (reading && reading.canon108 ? reading.canon108 : "") + ",";
      csv += (reading && reading.canon109 ? reading.canon109 : "") + ",";
      csv += (reading && reading.canon112 ? reading.canon112 : "") + ",";
      csv += (reading && reading.canon113 ? reading.canon113 : "") + ",";
      csv += (reading && reading.canon122 ? reading.canon122 : "") + ",";
      csv += (reading && reading.canon123 ? reading.canon123 : "") + ",";
      csv += (reading && reading.canon124 ? reading.canon124 : "") + ",";
      csv += (reading && reading.engineCount ? reading.engineCount : "") + ",";
      csv += (reading && reading.totalMono ? reading.totalMono : "") + ",";
      csv += (reading && reading.totalColor ? reading.totalColor : "") + ",";
      csv +=
        (device.lastCheck
          ? date.format(new Date(device.lastCheck), "DD/MM/YYYY")
          : "") + ",";
      csv += "\n";
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    downloadLink.target = "_blank";
    downloadLink.download = `MeterReadings-${date.format(
      new Date(),
      "DDMMYYYY"
    )}.csv`;
    downloadLink.click();
  };

  return (
    <div className="min-h-[55vh]">
      {devices.isLoading ? (
        <LoadingWheel />
      ) : (
        <>
          <div className="mb-5 flex justify-end">
            <button
              type="button"
              onClick={exportCSV}
              className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
            >
              Export to CSV
            </button>
          </div>
          <ReadingsTable 
            data={devices.data.devices} 
            count={devices.data.count}
          />
        </>
      )}
    </div>
  );
}
