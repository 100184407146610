import { useEffect } from "react";
import Slider from "react-slick";

import { withCustomer } from "../../data/withCustomer";

import { DocumentIcon } from "@heroicons/react/24/outline";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import Breadcrumbs from "../../components/shared/Breadcrumbs";

import CanonLogo from "../../assets/BrandLogos/CanonLogo.png";
import RicohLogo from "../../assets/BrandLogos/RicohLogo.png";
import SharpLogo from "../../assets/BrandLogos/SharpLogo.png";
import ToshibaLogo from "../../assets/BrandLogos/ToshibaLogo.png";
import XeroxLogo from "../../assets/BrandLogos/XeroxLogo.png";
import KyoceraLogo from "../../assets/BrandLogos/KyoceraLogo.png";
import HpLogo from "../../assets/BrandLogos/HpLogo.png";
import KonicaMinoltaLogo from "../../assets/BrandLogos/KonicaMinoltaLogo.png";
import usePageTitleContext from "../../contexts/PageTitleContext";
import { useContent } from "../../data/useContent";
import { useNavigate } from "react-router";
import { permissionsLookup, useRoleCheck } from "../../hooks/useRoleCheck";
import { NoPermission } from "../../components/shared/NoPermission";

// function ManualRow({ manual }) {
//   const navigate = useNavigate();

//   return (
//     <div className="px-2 py-4">
//       <div className="grid grid-cols-8 gap-2">
//         <div className="col-span-2 md:col-span-2 flex justify-center items-center">
//           <DocumentIcon className="w-10 h-10" />
//         </div>
//         <div className="col-span-6 md:col-span-6 h-full flex flex-col">
//           <div className="prose w-full">
//             <h4>{manual.shortDesc}</h4>
//           </div>
//           <div className="text-sm my-3 flex flex-grow">{manual.longDesc}</div>
//           <button
//             onClick={() => navigate(`${manual.id}`)}
//             className="px-4 py-1 w-16 rounded-full text-white text-sm bg-ag-default hover:bg-ag-lightDefault"
//           >
//             View
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

function _DownloadPrinterManuals({ customerLoading, customer }) {
  const { checkAccess } = useRoleCheck();
  const { content } = useContent(customer.id);
  const { setTitle } = usePageTitleContext();

  const pages = [
    { to: "../..", label: "Support", current: false },
    { to: "", label: "Manuals", current: true },
  ];

  useEffect(() => {
    setTitle("Manuals");
  }, [setTitle]);

  if (!checkAccess(permissionsLookup.guides.realm)) {
    return <NoPermission />;
  } else if (customerLoading || content.isLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="bg-white rounded-md shadow p-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            {/* <div className="p-4">
              <h1 className="text-xl font-medium text-ag-default mb-6">
                Featured Guides
              </h1>
              <Slider
                dots={true}
                infinite={true}
                arrows={false}
                autoplay={true}
                autoplaySpeed={8000}
              >
                {content.data.content
                  .filter((c) => c.featured)
                  .map((manual, i) => (
                    <ManualRow key={`featured-${i}`} manual={manual} />
                  ))}
              </Slider>
            </div> */}

            <div className="p-4 pb-16 md:col-span-4">
              <h1 className="text-xl font-medium text-ag-default mb-6">
                Need help finding the manual for your printer?
              </h1>
              <p className="mt-5 text-gray-500">
                Please click on the brand icons below for the printer you
                require manuals for, the link will take you to the
                manufacturer's website where you can download the latest
                drivers.
              </p>
            </div>

            <div className="md:col-span-4 p-4">
              <div className="px-8 pb-16 grid grid-cols-1 md:grid-cols-4 gap-32">
                <div>
                  <a
                    href="https://www.canon.co.uk/support/purchase-a-user-manual"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={CanonLogo} alt="Canon Logo" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.ricoh.co.uk/support"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={RicohLogo} alt="Ricoh Logo" />
                  </a>
                </div>
                <div>
                  <a
                    href="http://global.sharp/restricted/products/copier/downloads/manuals/select.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={SharpLogo} alt="Sharp Logo" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://business.toshiba.com/support"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={ToshibaLogo} alt="Toshiba Logo" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.support.xerox.com/engb.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="" src={XeroxLogo} alt="Xerox Logo" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.kyoceradocumentsolutions.co.uk/en/support/downloads.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="" src={KyoceraLogo} alt="Kyocera Logo" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://support.hp.com/gb-en"
                    target="_blank"
                    className="flex justify-center"
                    rel="noreferrer"
                  >
                    <img className="w-20 h-auto" src={HpLogo} alt="HP Logo" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.konicaminolta.co.uk/en-gb/customer-support/download-centre"
                    target="_blank"
                    className="flex justify-center"
                    rel="noreferrer"
                  >
                    <img
                      className="w-32 h-auto"
                      src={KonicaMinoltaLogo}
                      alt="Konica Minolta Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DownloadPrinterManuals = withCustomer(_DownloadPrinterManuals);

export default DownloadPrinterManuals;
