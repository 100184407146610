import { useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/20/solid";

import classNames from "../../utils/classNames";

import useNotification from "../../hooks/useNotifications";
import { useKpax } from "../../data/useKpax";

const alertTypes = [
  {
    title: "Agent Offline",
    desc: "Receive an alert if your Kpax Reporting Agent(s) go offline",
    guid: "903869a6-6303-4b51-a95f-19ad3b2d3db8",
  },
  {
    title: "Device Offline",
    desc: "Receive an alert if any of your devices stop reporting.",
    guid: "0359acf4-dec8-49ed-9239-4177ff4fda1a",
  },
  {
    title: "Problem Machine Alerts",
    desc: "Receive an alert whenever a machine errors. These alerts include Paper Jams, Door Opens, Error Alerts, etc",
    guid: "649438bb-6c8a-4e2a-b61e-8d34bb763289",
  },
  {
    title: "Low Toner Alert",
    desc: "Receive alerts every time one of your devices runs low on toner.",
    guid: "3d920fe9-a3b1-4dff-bf6e-ffa04417db49",
  },
  {
    title: "Paper Out Alert",
    desc: "Receive alerts every time one of your devices runs low on paper.",
    guid: "91ed2686-c8a6-4316-857d-d523c22381cb",
  },
];

export default function AddAlert({ onCancel, customer }) {
  const { addNotification } = useNotification();
  const { createAlert } = useKpax(customer.id);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      guid: alertTypes[0],
    },
  });

  useEffect(() => {
    register("guid");
  }, [register]);

  const onSubmit = (data) => {
    const body = {
      emailAddress: data.emailAddress,
      guid: data.guid.guid,
    };
    createAlert.mutate(body, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Alert Setup",
          secondaryText: "Your alert was successfully setup",
        });
        reset();
        onCancel();
      },
    });
  };

  const guidValue = watch("guid");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">
            Recipients Email
          </label>
          <input
            type="text"
            {...register("emailAddress", {
              required: "Please enter an email address",
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            aria-invalid={errors.emailAddress ? "true" : "false"}
            className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
              errors.emailAddress && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
            }`}
            placeholder="your@emailaddress.com"
          />
          {errors.emailAddress && (
            <p role="alert" className="text-red-600">
              {errors.emailAddress?.message}
            </p>
          )}
        </div>
        <div className="flex flex-col">
          <label className="mb-1 font-medium text-ag-default">Alert Type</label>
          <Listbox value={guidValue} onChange={(v) => setValue("guid", v)}>
            {({ open }) => (
              <>
                <div className="relative w-full">
                  <div className="w-full inline-flex">
                    <Listbox.Button className="w-full inline-flex items-center text-sm p-0.5 font-medium border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue">
                      <div className="w-full text-left align-left p-2">
                        {guidValue.title}
                      </div>
                      <span className="sr-only">Alert Type</span>
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </Listbox.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute left-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {alertTypes.map((option) => (
                        <Listbox.Option
                          key={option.title}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-ag-default"
                                : "text-gray-900",
                              "cursor-default select-none p-4 text-sm"
                            )
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <div className="flex flex-col">
                              <div className="flex justify-between">
                                <p
                                  className={
                                    selected ? "font-semibold" : "font-normal"
                                  }
                                >
                                  {option.title}
                                </p>
                                {selected ? (
                                  <span
                                    className={
                                      active ? "text-white" : "text-indigo-500"
                                    }
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </div>
                              <p
                                className={classNames(
                                  active ? "text-indigo-200" : "text-gray-500",
                                  "mt-2"
                                )}
                              >
                                {option.desc}
                              </p>
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <div className="flex justify-end space-x-3">
        <button
          type="button"
          className="p-2 px-4 rounded-full text-ag-default border border-ag-default"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
        >
          Create Alert
        </button>
      </div>
    </form>
  );
}
