import { useEffect, useState } from "react";
import useCurrentUserContext from "../../contexts/UserContext";
import { useKpax } from "../../data/useKpax";
import LoadingWheel from "../shared/LoadingWheel";

export default function Reports({ customer }) {
  const { reportAuth } = useKpax(customer.id);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(undefined);
  const [token, setToken] = useState(undefined);

  useEffect(() => {
    if (!reportAuth.isLoading && reportAuth.data) {
      setEmail(reportAuth.data.emailAddress);
      setToken(reportAuth.data.accessToken);
      setLoading(false);
    }
  }, [reportAuth.isLoading, reportAuth.data]);

  if (loading) {
    return (
      <div className="flex items-center">
        <LoadingWheel />
      </div>
    );
  } else {
    return (
      <>
        <div className="mb-3 flex justify-between">
        <p className="text-ag-default font-medium">If third party cookies are blocked then you will see a login screen below, please turn off third party cookie blocking or click the pop out button</p>
          <a
            target="_blank"
            href={`https://stats.kpax.dmcplc.co.uk/auth/link_authenticate?email=${email}&api_token=${token}`}
            className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
          >
            Pop out
          </a>
        </div>
        <iframe
          src={`https://stats.kpax.dmcplc.co.uk/auth/link_authenticate?email=${email}&api_token=${token}`}
          style={{ width: "100%", height: "700px", border: "0px" }}
          border="0"
        ></iframe>
      </>
    );
  }
}
