import { Squares2X2Icon, TableCellsIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";

export default function ViewSelect({ value, onChange }) {
  const [_, setSearchParams] = useSearchParams();

  return (
    <div className="flex items-center mb-4 md:m-0">
      <p className="text-md font-medium mr-2">View As</p>
      <span className="isolate inline-flex rounded-md">
        <button
          onClick={() => {
            setSearchParams("");
            onChange(false);
          }}
          type="button"
          className={`relative -ml-px inline-flex items-center rounded-l-md border border-gray-300 bg-white px-3 py-1.5 text-sm font-medium ${
            !value
              ? "bg-ag-darkBlue text-white"
              : "text-gray-700 hover:bg-ag-lightDefault hover:text-white"
          }`}
        >
          <TableCellsIcon className="w-5 h-5" />
        </button>
        <button
          onClick={() => {
            setSearchParams("view=card");
            onChange(true);
          }}
          type="button"
          className={`relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-1.5 text-sm font-medium ${
            value
              ? "bg-ag-darkBlue text-white"
              : "text-gray-700 hover:bg-ag-lightDefault hover:text-white"
          }`}
        >
          <Squares2X2Icon className="w-5 h-5" />
        </button>
      </span>
    </div>
  );
}
