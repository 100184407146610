import { useEffect } from "react";
import Slider from "react-slick";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import ServiceStatus from "../../components/Dashboard/ServiceStatus";
import WantTile from "../../components/Dashboard/Home/WantTile";

import usePageTitleContext from "../../contexts/PageTitleContext";
import useCurrentUserContext from "../../contexts/UserContext";
import { withCustomer } from "../../data/withCustomer";
import { useStatsDate } from "../../hooks/useStatsDate";
import { useDeviceDashboard } from "../../data/useDeviceDashboard";
import LoadingWheel from "../../components/shared/LoadingWheel";
import FavVideo from "../../components/Dashboard/Home/FavVideo";

function Voip() {
  return (
    <div className="min-h-[620px] grid grid-cols-1 xl:grid-cols-2 gap-x-8 px-2">
      <div className="xl:col-span-2 space-y-1">
        <h1 className="text-2xl font-medium text-ag-default">
          Hello. From here, there and everywhere.
        </h1>
        <h2 className="text-lg font-normal text-ag-default">
          Make sure your teams and customers stay connected and collaborative,
          with Agilico Connect, our Hosted VoIP solution.
        </h2>
      </div>
      <div className="flex flex-col justify-between">
        <div className="xl:hidden my-4">
          <img
            alt="Agilico Connect"
            src="https://www.agilico.co.uk/wp-content/uploads/2022/04/agilico-connect-square.png"
            className=""
          />
        </div>
        <p className="text-base">
          Traditional on-premise telephony is costly, inflexible and limiting
          for remote employees. We can connect you to our cloud telephony
          platform - delivering an enriched communications experience for your
          people, no matter where they are.
          <br />
          <br />
          <br />
          Using just an internet connection, you'll get all the capability of
          traditional telephony, plus enhanced collaboration capabilities like:
          MS Teams integration, messaging, conferencing and more…
        </p>
        <div className="flex flex-col space-y-1 mt-4">
          <a
            className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
            href="https://www.agilico.co.uk/business-hosted-voip/"
          >
            Find out more
          </a>
          <a
            className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
            href="https://www.agilico.co.uk/book-connect-demo/"
          >
            Book a Demo
          </a>
        </div>
      </div>
      <div className="hidden xl:block overflow-hidden">
        <img
          alt="Agilico Connect"
          src="https://www.agilico.co.uk/wp-content/uploads/2022/04/agilico-connect-square.png"
          className="2xl:max-w-[445px]"
        />
      </div>
    </div>
  );
}

function InvoiceProcessing() {
  return (
    <div className="min-h-[620px] grid grid-cols-1 xl:grid-cols-2 gap-x-8 px-2">
      <div className="xl:col-span-2 space-y-1">
        <h1 className="text-2xl font-medium text-ag-default">
          Invoice processing. Automated.
        </h1>
        <h2 className="text-lg font-normal text-ag-default">
          A highly effective step in your journey from paper-based processes to
          digital workflow.
        </h2>
      </div>
      <div className="flex flex-col justify-between">
        <div className="xl:hidden my-4">
          <img
            alt="Agilico Connect"
            src="https://www.agilico.co.uk/wp-content/uploads/2021/03/stay-productive-AIP.jpg"
            className=""
          />
        </div>
        <p className="text-base">
          Keeping on top of invoice processing is a demanding task for most
          finance teams and business owners. Without a clearly defined,
          efficient process for managing supplier payments and outgoings, it's
          easy for accounts payable departments to become overloaded and
          payments to be missed.
          <br />
          <br />
          <br />
          We'll help you scan, categorise and file invoices automatically and
          make them accessible to your approvers anytime, anywhere.
        </p>
        <div className="flex flex-col space-y-1 my-4">
          <a
            className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
            href="https://www.agilico.co.uk/automated-invoice-processing/"
          >
            Find out more
          </a>
          <a
            className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
            href="https://www.agilico.co.uk/book-invu-verify-demo/"
          >
            Book a Demo
          </a>
        </div>
      </div>
      <div className="hidden xl:block overflow-hidden">
        <img
          alt="Agilico Connect"
          src="https://www.agilico.co.uk/wp-content/uploads/2021/03/stay-productive-AIP.jpg"
          className="mr-4 2xl:max-w-[445px]"
        />
      </div>
    </div>
  );
}

function AccountManagerSlide({ details }) {
  return (
    <>
      <div className="w-full prose text-ag-default mb-2">
        <h1 className="text-2xl font-medium text-ag-default">
          {details.type} Account Manager
        </h1>
      </div>
      <div className="grid gap-y-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
        <div className="flex flex-col">
          <label className="font-medium text-ag-default">Name</label>
          <p>{details.fullName}</p>
        </div>
        <div />
        <div className="flex flex-col">
          <label className="font-medium text-ag-default">E-Mail</label>
          <p>{details.emailAddress}</p>
        </div>
        <div className="flex flex-col items-end">
          <label className="font-medium text-ag-default">Contact Number</label>
          <p>{details.telephone}</p>
        </div>
      </div>
    </>
  );
}

function _Home({ customerLoading, customer }) {
  //Preloading dashboard data
  const { currentUser } = useCurrentUserContext();
  const { startDate, endDate } = useStatsDate();
  /* eslint-disable */
  const dateRange = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
  const { accountManagers } = useDeviceDashboard(customer.id, dateRange);
  /* eslint-enable */

  const { setTitle } = usePageTitleContext();
  const {
    currentUser: { favouriteTiles },
  } = useCurrentUserContext();

  useEffect(() => {
    setTitle(`Welcome Back ${currentUser.user.firstname}!`);
  }, [setTitle, currentUser.user.firstname]);

  if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-8 gap-4 shrink">
        <div className="w-full col-span-1 lg:col-span-8">
          <p className="text-ag-darkBlue font-semibold">Your Service Status</p>
        </div>
        <div className="md:col-span-4 2xl:col-span-3 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="md:col-span-2">
            <ServiceStatus customer={customer} />
          </div>
          {favouriteTiles.length === 0 || favouriteTiles[0] === "" ? (
            <div className="col-span-2 row-span-2">
              <FavVideo />
            </div>
          ) : (
            favouriteTiles.map(
              (tile, i) => tile !== "" && <WantTile key={i} type={tile} />
            )
          )}
          {}
          {/* Spacing divs for grid to appear correctly */}
          {/* {Array(Math.ceil((4 - favouriteTiles.length) / 2))
            .fill("")
            .map((_, i) => (
              <div key={i} className="h-40 col-span-2" />
            ))} */}
          <div className="w-full bg-white rounded-md shadow py-4 px-6 pb-8 min-h-48 md:col-span-2">
            {accountManagers.isLoading ? (
              <div className="min-h-full flex justify-center items-center">
                <LoadingWheel />
              </div>
            ) : accountManagers.data && accountManagers.data.length ? (
              <Slider
                dots={true}
                infinite={true}
                arrows={false}
                autoplay={true}
                autoplaySpeed={20000}
              >
                {accountManagers.data.map((details, i) => (
                  <AccountManagerSlide
                    details={details}
                    key={`accountManager-${i}`}
                  />
                ))}
              </Slider>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="md:col-span-4 2xl:col-span-5 grid grid-cols-1">
          <div className="p-8 pb-12 bg-white rounded-md shadow row-span-3">
            <Slider
              dots={true}
              infinite={true}
              arrows={false}
              autoplay={true}
              autoplaySpeed={20000}
              className="min-h-full"
            >
              <Voip />
              <InvoiceProcessing />
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

const Home = withCustomer(_Home);

export default Home;
