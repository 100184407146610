import { useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import Breadcrumbs from "../../components/shared/Breadcrumbs";

import classNames from "../../utils/classNames";
import { withCustomer } from "../../data/withCustomer";
import usePageTitleContext from "../../contexts/PageTitleContext";

import NewSupportTicket from "../../components/Support/NewSupportTicket";
import OpenTickets from "../../components/Support/OpenTickets";
import ClosedTickets from "../../components/Support/ClosedTickets";
import { useRoleCheck, permissionsLookup } from "../../hooks/useRoleCheck";
import { NoPermission } from "../../components/shared/NoPermission";

function _SupportTickets({ customerLoading, customer }) {
  const navigate = useNavigate();
  const { checkAccess } = useRoleCheck();
  const { companyName, section } = useParams();
  const [searchParams] = useSearchParams();
  const { setTitle } = usePageTitleContext();

  const pages = [
    { to: "../..", label: "Support", current: false },
    { to: "", label: "Support Tickets", current: true },
  ];

  const getPanel = useMemo(() => {
    const checkRender = (realm, component) => {
      if(!checkAccess(realm)) return <NoPermission />;
      return component;
    };

    switch (section) {
      case "open":
        return checkRender(permissionsLookup.openTicket.realm, <OpenTickets customer={customer} />);
      case "closed":
        return checkRender(permissionsLookup.closedTicket.realm, <ClosedTickets customer={customer} />);
      default:
        return checkRender(permissionsLookup.newTicket.realm, <NewSupportTicket customer={customer} />);
    }
  }, [section, customer]);

  //Set page title
  useEffect(() => {
    setTitle("Support Tickets");
  }, [setTitle]);

  const cardView = searchParams.has("view")
    ? searchParams.get("view") === "card"
    : false;

  if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div>
          {checkAccess(permissionsLookup.newTicket.realm) && (
            <button
              onClick={() => navigate(`/${companyName}/support/tickets`)}
              className={classNames(
                section === undefined
                  ? "bg-ag-darkBlue text-white"
                  : "bg-white",
                "p-3 mr-3 border border-b-0 rounded-t-md"
              )}
            >
              New Ticket
            </button>
          )}
          {checkAccess(permissionsLookup.openTicket.realm) && (
            <button
              onClick={() => navigate(`/${companyName}/support/tickets/open`)}
              className={classNames(
                section === "open" ? "bg-ag-darkBlue text-white" : "bg-white",
                "p-3 mr-3 border border-b-0 rounded-t-md"
              )}
            >
              Open Tickets
            </button>
          )}
          {checkAccess(permissionsLookup.closedTicket.realm) && (
            <button
              onClick={() => navigate(`/${companyName}/support/tickets/closed`)}
              className={classNames(
                section === "closed" ? "bg-ag-darkBlue text-white" : "bg-white",
                "p-3 mr-3 border border-b-0 rounded-t-md"
              )}
            >
              Closed Tickets
            </button>
          )}
        </div>

        <div
          className={`-mt-px ${
            cardView ? "" : "bg-white p-8 shadow rounded-r-md rounded-b-md"
          }`}
        >
          {getPanel}
        </div>
      </>
    );
  }
}

const SupportTickets = withCustomer(_SupportTickets);

export default SupportTickets;
