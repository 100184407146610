import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { msalConfig } from "./authConfig";
import { NotifierContextProvider } from "react-headless-notifier";
import { UserContextProvider } from "./contexts/UserContext";
import { PageTitleContextProvider } from "./contexts/PageTitleContext";

import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const notifierConfig = {
  max: null, // Max number of notiication simultaneously, `null` will result in no maximum
  duration: 15000, // Duration by notification in milliseconds
  position: "topRight", // You can specify a position where the notification should appears, valid positions are 'top', 'topRight', 'topLeft', 'bottomRight', 'bottomLeft', 'bottom'.
};

const root = ReactDOM.createRoot(document.getElementById("root"));

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <NotifierContextProvider config={notifierConfig}>
        <BrowserRouter>
          <MsalProvider instance={msalInstance}>
            <PageTitleContextProvider>
              <UserContextProvider>
                <App />
              </UserContextProvider>
            </PageTitleContextProvider>
          </MsalProvider>
        </BrowserRouter>
      </NotifierContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
