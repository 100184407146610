import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withCustomer } from "../../data/withCustomer";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import PlaceTonerOrderPartial from "../../components/Consumables/PlaceTonerOrderPartial";
import OrderHistoryPartial from "../../components/Consumables/OrderHistoryPartial";
import classNames from "../../utils/classNames";
import PlaceConsumablesOrderPartial from "../../components/Consumables/PlaceConsumablesOrderPartial";
import usePageTitleContext from "../../contexts/PageTitleContext";
import SubmitMeterReadings from "../../components/Consumables/SubmitMeterReadings";
import { permissionsLookup, useRoleCheck } from "../../hooks/useRoleCheck";
import { NoPermission } from "../../components/shared/NoPermission";

function _Consumables({ customerLoading, customer }) {
  const navigate = useNavigate();
  const { checkAccess } = useRoleCheck();
  const { companyName, section } = useParams();
  const { setTitle } = usePageTitleContext();

  const pages = [
    { to: "../..", label: "Support", current: false },
    { to: "", label: "Consumables", current: true },
  ];

  const getPanel = () => {
    switch (section) {
      case "order-consumables":
        if(!checkAccess(permissionsLookup.placeConsumableOrder.realm)) return <NoPermission />
        return <PlaceConsumablesOrderPartial customer={customer} />;
      case "order-history":
        if(!checkAccess(permissionsLookup.orderHistory.realm)) return <NoPermission />
        return <OrderHistoryPartial customer={customer} />;
      case "submit-readings":
        if(!checkAccess(permissionsLookup.submitMeterReadings.realm)) return <NoPermission />
        return <SubmitMeterReadings customer={customer} />;
      default:
        if(!checkAccess(permissionsLookup.placeTonerOrder.realm)) return <NoPermission />
        return <PlaceTonerOrderPartial customer={customer} />;
    }
  };

  useEffect(() => {
    setTitle("Consumables");
  }, [setTitle]);

  if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <>
        <div className="mb-4">
          <Breadcrumbs pages={pages} />
        </div>
        <div>
         {checkAccess(permissionsLookup.placeTonerOrder.realm) &&
          <button
            onClick={() => navigate(`/${companyName}/support/consumables`)}
            className={classNames(
              section === undefined ? "bg-ag-darkBlue text-white" : "bg-white",
              "p-3 mr-3 border border-b-0 rounded-t-md"
            )}
          >
            Place Toner Order
          </button>
         }
         {checkAccess(permissionsLookup.placeConsumableOrder.realm) &&
          <button
            onClick={() =>
              navigate(`/${companyName}/support/consumables/order-consumables`)
            }
            className={classNames(
              section === "order-consumables"
                ? "bg-ag-darkBlue text-white"
                : "bg-white",
              "p-3 mr-3 border border-b-0 rounded-t-md"
            )}
          >
            Place Consumables Order
          </button>
         }
         {checkAccess(permissionsLookup.orderHistory.realm) &&
          <button
            onClick={() =>
              navigate(`/${companyName}/support/consumables/order-history`)
            }
            className={classNames(
              section === "order-history"
                ? "bg-ag-darkBlue text-white"
                : "bg-white",
              "p-3 mr-3 border border-b-0 rounded-t-md"
            )}
          >
            Order History
          </button>
         }
         {checkAccess(permissionsLookup.submitMeterReadings.realm) &&
          <button
            onClick={() =>
              navigate(`/${companyName}/support/consumables/submit-readings`)
            }
            className={classNames(
              section === "submit-readings"
                ? "bg-ag-darkBlue text-white"
                : "bg-white",
              "p-3 border border-b-0 rounded-t-md"
            )}
          >
            Submit Meter Readings
          </button>
         }
        </div>

        <div className="p-8 bg-white -mt-px shadow rounded-r-md rounded-b-md">
          {getPanel()}
        </div>
      </>
    );
  }
}

const Consumables = withCustomer(_Consumables);

export default Consumables;
